import {
  Button,
  Divider,
  Flex,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { Loading } from '@components/Loading'
import {
  CreateTeacherRequestGenderEnum,
  CreateTournamentCategoryRequestAgeCalculationModeEnum,
  UpdateTournamentCategoryRequest,
  UpdateTournamentCategoryRequestGenderEnum,
} from '@/openapi-api/api'
import {
  optionsFromEntity,
  optionsFromEntityNoId,
} from '@/utils/functions/optionsFromEntity'
import {
  useGetProfiles,
  useGetSpecializations,
  useGetTournaments,
} from '@/utils/hooks/entities'
import { getValue } from '@/utils/functions/dataUtils'
import { useLanguageContext } from '@/contexts/LanguageProvider'

const EditTournamentCategory = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tournamentCategoriesAdminControllerApi } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const profiles = useGetProfiles()
  const tournaments = useGetTournaments()
  const profileId = Form.useWatch('profileId', form)
  const teamMinAge = Form.useWatch('teamMinAge', form)
  const teamMaxAge = Form.useWatch('teamMaxAge', form)
  const specializations = useGetSpecializations(getValue(profileId) as string)

  const validateRequired = (_: any, value: any) => {
    if (teamMinAge || teamMaxAge) {
      if (!value) {
        return Promise.reject(new Error(t`required`))
      }
    }
    return Promise.resolve()
  }

  useEffect(() => {
    tournamentCategoriesAdminControllerApi
      .getById(+(id || 0))
      .then(({ data }) => {
        setFormData({
          name: data.name,
          profileId: String(data.profile?.id),
          tournamentId: String(data.tournament?.id),
          specialization: data.specialization,
          teamAgeName: data.teamAgeName,
          teamMinAge: data.teamMinAge,
          teamMaxAge: data.teamMaxAge,
          teamMinAgeShift: data.teamMinAgeShift,
          teamMaxAgeShift: data.teamMaxAgeShift,
          teamMaxAgeRatio: data.teamMaxAgeRatio,
          teamMinAgeRatio: data.teamMinAgeRatio,
          teamAgeDifference: data.teamAgeDifference,
          ageCalculationMode: data.ageCalculationMode,
          teamSizeName: data.teamSizeName,
          teamMinSize: data.teamMinSize,
          teamMaxSize: data.teamMaxSize,
          gender: data.gender,
          level: data.level,
        })
        setReady(true)
      })
  }, [id, tournamentCategoriesAdminControllerApi])

  const title = useMemo(() => formData.name, [formData.name])

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])
  const { appLang } = useLanguageContext()

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: UpdateTournamentCategoryRequest = {
        [`name${appLang}`]: values.name,
        profileId: getValue(values.profileId, true) as number,
        specialization: values.specialization,
        [`teamAgeName${appLang}`]: values.teamAgeName,
        teamMinAge: values.teamMinAge,
        teamMaxAge: values.teamMaxAge,
        teamMinAgeShift: values.teamMinAgeShift,
        teamMaxAgeShift: values.teamMaxAgeShift,
        teamMaxAgeRatio: values.teamMaxAgeRatio,
        teamMinAgeRatio: values.teamMinAgeRatio,
        teamAgeDifference: values.teamAgeDifference,
        ageCalculationMode: values.ageCalculationMode,
        [`teamSizeName${appLang}`]: values.teamSizeName,
        teamMinSize: values.teamMinSize,
        teamMaxSize: values.teamMaxSize,
        gender: getValue(
          values.gender,
        ) as any as UpdateTournamentCategoryRequestGenderEnum,
        level: values.level,
      } as any
      let entityId = +(id || 0)

      await tournamentCategoriesAdminControllerApi.update(entityId, dataToSend)
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.organiser.categories.all)}
        >
          {t`cancel`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Space>

      <Form
        name="basic"
        style={{
          maxWidth: 800,
          width: '100%',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        layout="vertical"
      >
        <Form.Item name="tournamentId" label={t`tournaments.tournament`}>
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(tournaments)}
            disabled
          />
        </Form.Item>
        <FormInput
          label={t`nazwa`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />

        <Form.Item
          name="profileId"
          label={t`profile.profile`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
            },
          ]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(profiles)}
          />
        </Form.Item>
        <Form.Item
          name="specialization"
          label={t`group.specialization`}
          rules={[{ required: true, message: t`required` }]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntityNoId(specializations)}
          />
        </Form.Item>
        <Form.Item label={t`group.level`} name="level">
          <Input />
        </Form.Item>
        <Form.Item name="gender" label={t`teacher.gender`}>
          <Select
            options={[
              {
                value: CreateTeacherRequestGenderEnum.FEMALE,
                label: t`teacher.genderWoman`,
              },
              {
                value: CreateTeacherRequestGenderEnum.MALE,
                label: t`teacher.genderMan`,
              },
              {
                value: CreateTeacherRequestGenderEnum.OTHER,
                label: t`teacher.genderOther`,
              },
            ]}
          />
        </Form.Item>
        <Divider />
        <Typography.Text strong>{t`tournaments.teamAge`}</Typography.Text>
        <Form.Item
          name="teamAgeName"
          label={t`tournaments.bulkAgeCategoryName`}
          style={{ marginTop: '16px' }}
        >
          <Input />
        </Form.Item>
        <Flex
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '16px',
          }}
        >
          <Form.Item
            name="teamMinAge"
            label={
              <Typography.Text
                strong
              >{t`tournaments.bulkAgeMinAge`}</Typography.Text>
            }
          >
            <Input style={{ maxWidth: '100px' }} />
          </Form.Item>

          <Form.Item
            name="teamMaxAge"
            label={
              <Typography.Text
                strong
              >{t`tournaments.bulkAgeMaxAge`}</Typography.Text>
            }
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            name="teamMinAgeRatio"
            label={t`tournaments.bulkTeamMinAgeRatio`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} addonAfter="%" />
          </Form.Item>
          <Form.Item
            name="teamMaxAgeRatio"
            label={t`tournaments.bulkTeamMaxAgeRatio`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} addonAfter="%" />
          </Form.Item>
          <Form.Item
            name="teamMinAgeShift"
            label={t`tournaments.bulkTeamAgeShift`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            name="teamMaxAgeShift"
            label={t`tournaments.bulkTeamAgeShift`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            name="ageCalculationMode"
            label={t`tournaments.bulkAgeCalculationMode`}
            rules={[{ validator: validateRequired }]}
            required={teamMinAge || teamMaxAge}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={[
                {
                  value:
                    CreateTournamentCategoryRequestAgeCalculationModeEnum.YEAR_OF_BIRTH,
                  label: t`tournaments.bulkAgeCalculationModeYear`,
                },
                {
                  value:
                    CreateTournamentCategoryRequestAgeCalculationModeEnum.EXACT_BIRTH_DAY,
                  label: t`tournaments.bulkAgeCalculationModeDay`,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="teamAgeDifference"
            label={t`tournaments.bulkAgeDifference`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
        </Flex>
        <Divider />
        <Typography.Text strong>{t`tournaments.bulkTeamSize`}</Typography.Text>
        <Form.Item
          label={t`tournaments.bulkCustomTeamSizeName`}
          name="teamSizeName"
          style={{ marginTop: '16px' }}
        >
          <Input />
        </Form.Item>
        <Flex
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '16px',
          }}
        >
          <Form.Item label={t`tournaments.teamMinSize`} name="teamMinSize">
            <Input type="number" />
          </Form.Item>
          <Form.Item label={t`tournaments.teamMaxSize`} name="teamMaxSize">
            <Input type="number" />
          </Form.Item>
        </Flex>
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default EditTournamentCategory
