import { Flex, Typography } from 'antd'
import { FC, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

interface Props {
  day: Dayjs
  short?: boolean
}

export const DayTopRow: FC<Props> = ({ day, short = false }) => {
  const { t } = useTranslation()
  const isToday = useMemo(() => day.isToday(), [day])
  const color = useMemo(() => {
    if (isToday) return '#0958D9'
    if (day.isAfter(dayjs())) return 'rgba(0, 0, 0, 0.88)'
    return 'rgba(0, 0, 0, 0.44)'
  }, [day, isToday])

  return (
    <Flex vertical>
      <Flex
        justify="space-between"
        style={{
          paddingBottom: '8px',
          marginBottom: '2px',
          borderBottom: `2px solid ${
            isToday ? '#0958D9' : 'rgba(0, 0, 0, 0.06)'
          }`,
        }}
      >
        <Typography style={{ color: '#0958D9' }}>
          {isToday ? t`schedule.today` : ''}
        </Typography>
        <Typography style={{ color }} className="firstLetter">
          {day.format(short ? 'ddd' : 'dddd')}
        </Typography>
      </Flex>
      <Flex justify="flex-end">
        <Typography style={{ color }}>{day.date()}</Typography>
      </Flex>
    </Flex>
  )
}
