import { Progress, Typography } from 'antd'
import { SessionAttendanceViewDto } from '@/openapi-api/api'
import { FC } from 'react'

interface Props {
  session?: SessionAttendanceViewDto
}

export const SessionProgress: FC<Props> = ({ session }) => (
  <Progress
    percent={
      ((session?.sessionAttended as number) /
        (session?.sessionRequested as number)) *
      100
    }
    size="small"
    strokeColor={{
      '0%': 'rgba(16, 142, 233, 1)',
      '100%': 'rgba(135, 208, 104, 1)',
    }}
    format={() => (
      <Typography style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
        {session?.sessionAttended}
        {'/'}
        {session?.sessionRequested}
      </Typography>
    )}
  />
)
