import {
  Button,
  Flex,
  Form,
  FormProps,
  Input,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import {
  CreateTeacherRequest,
  CreateTeacherRequestGenderEnum,
  UpdateTeacherRequest,
} from '@/openapi-api/api'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { FormAvatar } from '@components/Form/FormAvatar'
import { EMAIL_REGEXP } from '@/utils/constants'

const EditTeacher = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const { refreshTour } = useGlobalContext()
  const navigate = useNavigate()
  const {
    schoolsAdminControllerApi,
    profilesAdminControllerApi,
    specializationsAdminControllerApi,
    teachersAdminControllerApi,
  } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { appLang } = useLanguageContext()
  const { id } = useParams()

  const profileIds = Form.useWatch('profileIds', form)
  const { getLocaleField } = useLocaleHelpers()

  const { resource: profiles } = useAsyncResource({
    fetchResource: useCallback(
      () => profilesAdminControllerApi.find(),
      [profilesAdminControllerApi],
    ),
  })

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(
      () => schoolsAdminControllerApi.find(0, 1000),
      [schoolsAdminControllerApi],
    ),
  })

  const { resource: specializations } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!profileIds.length) {
        return { data: { content: [] } }
      }
      return specializationsAdminControllerApi.find(
        0,
        1000,
        undefined,
        `profile.id~in~${profileIds.toString()}`,
      )
    }, [profileIds, specializationsAdminControllerApi]),
  })

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      teachersAdminControllerApi
        .getByIdExtended(+(id || 0))
        .then(({ data }) => {
          setFormData({
            name: getLocaleField('namePL', data),
            pseudonim: getLocaleField('pseudonimPL', data),
            bio: getLocaleField('bioPL', data),
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            socialMediaLink: data.socialMediaLink,
            profileIds: data.profiles?.map((p) => p.id),
            schoolIds: data.schools?.map((p) => p.id),
            specializations: data.specializations?.split(', '),
            avatar: data.mediaFiles?.[0]
              ? [
                  {
                    uid: data.mediaFiles[0].id,
                    status: 'done',
                    url: data.mediaFiles[0].link,
                  },
                ]
              : null,
          })
          setReady(true)
        })
    }
  }, [
    getLocaleField,
    id,
    schoolsAdminControllerApi,
    teachersAdminControllerApi,
  ])

  const title = useMemo(
    () => formData.name || t`teacher.new`,
    [formData.name, t],
  )

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateTeacherRequest = {
        [`name${appLang}`]: values.name,
        [`pseudonim${appLang}`]: values.pseudonim,
        [`bio${appLang}`]: values.bio,
        email: values.email,
        phone: values.phone,
        gender: values.gender,
        profileIds: values.profileIds,
        schoolIds: values.schoolIds,
        socialMediaLink: values.socialMediaLink,
        createLoginCredentials: values.createLoginCredentials,
        specializations: values.specializations?.join(', '),
      }

      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await teachersAdminControllerApi.create(dataToSend)
        entityId = data.id as number
      } else {
        await teachersAdminControllerApi.update(
          id as any,
          dataToSend as never as UpdateTeacherRequest,
        )
      }
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (id === 'new' || !formData.avatar?.length) {
          await teachersAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await teachersAdminControllerApi.updateFile(
            entityId,
            formData.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && formData.avatar?.length) {
        await teachersAdminControllerApi.deleteMediaFile(
          entityId,
          formData.avatar[0].uid as number,
        )
      }
      if (id === 'new') {
        refreshTour()
        navigate(routePaths.teachers.all)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.teachers.all)}
        >
          {t`cancel`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Space>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <FormAvatar label={t`image`} form={form} />
        <FormInput
          label={t`teacher.name`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 100, message: t`max100` },
          ]}
        />
        <Form.Item name="gender" label={t`teacher.gender`}>
          <Radio.Group
            style={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <Radio
              value={CreateTeacherRequestGenderEnum.FEMALE}
            >{t`teacher.genderWoman`}</Radio>
            <Radio
              value={CreateTeacherRequestGenderEnum.MALE}
            >{t`teacher.genderMan`}</Radio>
            <Radio
              value={CreateTeacherRequestGenderEnum.OTHER}
            >{t`teacher.genderOther`}</Radio>
          </Radio.Group>
        </Form.Item>
        <FormInput
          label={t`teacher.nickname`}
          name="pseudonim"
          rules={[{ max: 50, message: t`max50` }]}
        />
        <Form.Item
          name="profileIds"
          label={t`profile.profile`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
              type: 'array',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={t`school.pleaseSelect`}
            options={
              profiles?.data.content?.map(({ id, name }) => ({
                label: name,
                value: id,
              })) || []
            }
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item name="specializations" label={t`teacher.specialization`}>
          <Select
            mode="tags"
            placeholder={t`school.pleaseSelect`}
            options={
              specializations?.data.content?.map(({ name }) => ({
                label: name,
                value: name,
              })) || []
            }
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="schoolIds"
          label={t`school.school`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
              type: 'array',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={t`school.pleaseSelect`}
            options={
              schools?.data.content?.map(({ id, name }) => ({
                label: name,
                value: id,
              })) || []
            }
          />
        </Form.Item>
        <Form.Item name="bio" label={t`teacher.bio`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <FormPhoneInput label={t`profile.phone`} required={false} />
        <FormInput
          name="email"
          label={t`profile.email`}
          rules={[
            {
              pattern: new RegExp(EMAIL_REGEXP),
              message: t`school.wrongEmail`,
            },
          ]}
        />
        <FormInput
          name="socialMediaLink"
          label={t`teacher.social`}
          rules={[
            {
              type: 'url',
              message: t`school.wrongLink`,
            },
          ]}
        />
        <Form.Item
          name="createLoginCredentials"
          label={t`settings`}
          valuePropName="checked"
        >
          <Switch />
          <Typography.Text
            style={{ marginLeft: '8px' }}
          >{t`teacher.logDescription`}</Typography.Text>
        </Form.Item>
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default EditTeacher
