import { Flex, Spin } from 'antd'

export const Loading = () => (
  <Flex
    align="center"
    justify="center"
    style={{ width: '100%', height: '100%' }}
  >
    <Spin size="large" />
  </Flex>
)
