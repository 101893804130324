import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useApi from '@/contexts/api'
import { useAuthContext } from '@/contexts/AuthContext'

type Breadcrumb = {
  name: string
  href: string
}

export interface IGlobalContext {
  setBreadcrumbs: (data: Breadcrumb[]) => void
  breadcrumbs: Breadcrumb[]
  showTour?: boolean
  tour?: Tour
  refreshTour: () => Promise<void>
}

export interface Tour {
  teacher: boolean
  school: boolean
  place: boolean
  group: boolean
  room: boolean
}

const GlobalContext = React.createContext<IGlobalContext | undefined>(undefined)

const NotificationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const {
    teachersAdminControllerApi,
    schoolsAdminControllerApi,
    placesAdminControllerApi,
    studentGroupsAdminControllerApi,
    roomsAdminControllerApi,
  } = useApi()
  const { isAdmin, isLoggedIn } = useAuthContext()
  const [tour, setTour] = useState<Tour>()

  const showTour = useMemo(
    () => tour && Object.values(tour).some((val) => !val),
    [tour],
  )
  const getStepperData = useCallback(async () => {
    if (!isAdmin || !isLoggedIn) return
    const { data: teacherCount } = await teachersAdminControllerApi.getCount()
    const { data: schoolCount } = await schoolsAdminControllerApi.getCount()
    const { data: placesCount } = await placesAdminControllerApi.getCount()
    const { data: groupCount } =
      await studentGroupsAdminControllerApi.getCount()
    const { data: roomsCount } = await roomsAdminControllerApi.getCount()

    setTour({
      teacher: !!teacherCount,
      school: !!schoolCount,
      place: !!placesCount,
      group: !!groupCount,
      room: !!roomsCount,
    })
  }, [
    isAdmin,
    isLoggedIn,
    placesAdminControllerApi,
    roomsAdminControllerApi,
    schoolsAdminControllerApi,
    studentGroupsAdminControllerApi,
    teachersAdminControllerApi,
  ])

  useEffect(() => {
    getStepperData()
  }, [getStepperData, isLoggedIn])

  let value = useMemo(
    () => ({
      breadcrumbs,
      showTour,
      tour,
      setBreadcrumbs,
      refreshTour: getStepperData,
    }),
    [breadcrumbs, getStepperData, showTour, tour],
  )

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export function useGlobalContext() {
  const context = useContext(GlobalContext)

  if (typeof context === 'undefined') {
    throw new Error('useGlobalContext must be used within an GlobalProvider')
  }

  return context
}

export default NotificationProvider
