import { Form, FormItemProps, Input, InputProps } from 'antd'
import { FC } from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

interface Props extends FormItemProps {
  placeholder?: string
  disabled?: boolean
  type?: InputProps['type']
}

export const FormInput: FC<Props> = ({
  placeholder,
  type,
  disabled,
  ...rest
}) => {
  return (
    <Form.Item {...rest} style={{ width: '100%', ...rest.style }}>
      {type !== 'password' && (
        <Input placeholder={placeholder} type={type} disabled={disabled} />
      )}
      {type === 'password' && (
        <Input.Password
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      )}
    </Form.Item>
  )
}
