import { FC, useMemo } from 'react'
import {
  SendUserNotificationRequestTypeEnum,
  SessionDto,
} from '@/openapi-api/api'
import { Button, Dropdown, Flex, MenuProps, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { formatTimeRange, generateCardStyles } from '@pages/schedule/helpers'
import { useTranslation } from 'react-i18next'
import { EditOutlined, MailOutlined, MoreOutlined } from '@ant-design/icons'
import {
  getColor,
  getLevel,
  getName,
  renderAge,
} from '@pages/groups/GroupsList'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { routePaths } from '@/routes'
import { Link, useNavigate } from 'react-router-dom'
import { useSendNotification } from '@/utils/hooks/useSendNotification'
import { useEventCancel } from '@/utils/hooks/useEventCancel'

interface Props {
  session: SessionDto
  wrap?: boolean
  refresh: () => Promise<void>
}

export const SessionCard: FC<Props> = ({ session, wrap = false, refresh }) => {
  const startTime = dayjs(session.startTime)
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()
  const navigate = useNavigate()
  const { modal, setData } = useSendNotification()

  const cancel = useEventCancel(refresh)

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'cancelEvent',
        label: (
          <Link
            to=""
            onClick={() => cancel(session.id)}
          >{t`schedule.cancelEvent`}</Link>
        ),
      },
    ],
    [cancel, session.id, t],
  )

  return (
    <Flex
      style={{
        padding: '16px',
        backgroundColor: getColor(session),
        borderRadius: '4px',
        gap: '8px',
        ...generateCardStyles(startTime, session.duration),
      }}
      vertical
    >
      <Flex justify="space-between" align="center">
        <Typography style={{ fontWeight: 600 }}>
          {formatTimeRange(startTime, session.duration as number)}
        </Typography>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            type="link"
            onClick={() => navigate(routePaths.attendance.specific(session.id))}
          >{t`schedule.presence`}</Button>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => navigate(routePaths.scheduleEdit(session.id))}
          />
          <Button
            type="text"
            icon={<MailOutlined />}
            onClick={() =>
              setData({
                schoolId: session.room?.place?.schoolId!,
                entityId: session.id!,
                type: SendUserNotificationRequestTypeEnum.SESSION,
              })
            }
          />
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </Flex>
      <Typography style={{ fontWeight: 600 }}>{getName(session)}</Typography>
      <Space size="large">
        {renderAge(session, true) && (
          <Typography>
            {renderAge(session)} {t`years`}
          </Typography>
        )}
        {getLevel(session) && <Typography>{getLevel(session)}</Typography>}
      </Space>
      <Space style={{ flexWrap: isMobile || wrap ? 'wrap' : 'nowrap' }}>
        <Button
          type="link"
          style={{ padding: 0, height: 'auto' }}
          onClick={() =>
            navigate(routePaths.teachers.specific(session.teacher?.id).view)
          }
        >
          {session.teacher?.name}
        </Button>
        <Typography>{session.room?.place?.address}</Typography>
        <Typography>{session.room?.name}</Typography>
      </Space>
      {modal}
    </Flex>
  )
}
