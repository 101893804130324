import { useCallback } from 'react'
import { Flex, FormInstance, Modal, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const useFormReset = (form: FormInstance) => {
  const { t } = useTranslation()

  const resetData = useCallback(() => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        content: (
          <Flex vertical>
            <Typography.Text
              style={{ marginBottom: '8px', fontWeight: 600 }}
            >{t`warning`}</Typography.Text>
            <Typography.Text>{t`profile.changesNotSaved`}</Typography.Text>
          </Flex>
        ),
        onOk: () => form.resetFields(),
        icon: <ExclamationCircleOutlined />,
        cancelText: t`cancel`,
      })
    }
  }, [form, t])

  return resetData
}
