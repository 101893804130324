/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SendAdminNotificationRequest } from '../models';
// @ts-ignore
import { SendUserNotificationRequest } from '../models';
/**
 * NotificationsControllerApi - axios parameter creator
 * @export
 */
export const NotificationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendUserNotificationRequest} sendUserNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send: async (sendUserNotificationRequest: SendUserNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendUserNotificationRequest' is not null or undefined
            assertParamExists('send', 'sendUserNotificationRequest', sendUserNotificationRequest)
            const localVarPath = `/v1/notifications/users/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendUserNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendAdminNotificationRequest} sendAdminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToSchoolAdmin: async (sendAdminNotificationRequest: SendAdminNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendAdminNotificationRequest' is not null or undefined
            assertParamExists('sendToSchoolAdmin', 'sendAdminNotificationRequest', sendAdminNotificationRequest)
            const localVarPath = `/v1/notifications/admins/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendAdminNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setToRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setToRead', 'id', id)
            const localVarPath = `/v1/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsControllerApi - functional programming interface
 * @export
 */
export const NotificationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SendUserNotificationRequest} sendUserNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async send(sendUserNotificationRequest: SendUserNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.send(sendUserNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendAdminNotificationRequest} sendAdminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToSchoolAdmin(sendAdminNotificationRequest: SendAdminNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendToSchoolAdmin(sendAdminNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setToRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setToRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsControllerApi - factory interface
 * @export
 */
export const NotificationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SendUserNotificationRequest} sendUserNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send(sendUserNotificationRequest: SendUserNotificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.send(sendUserNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendAdminNotificationRequest} sendAdminNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToSchoolAdmin(sendAdminNotificationRequest: SendAdminNotificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendToSchoolAdmin(sendAdminNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setToRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.setToRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsControllerApi - object-oriented interface
 * @export
 * @class NotificationsControllerApi
 * @extends {BaseAPI}
 */
export class NotificationsControllerApi extends BaseAPI {
    /**
     * 
     * @param {SendUserNotificationRequest} sendUserNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public send(sendUserNotificationRequest: SendUserNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).send(sendUserNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendAdminNotificationRequest} sendAdminNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public sendToSchoolAdmin(sendAdminNotificationRequest: SendAdminNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).sendToSchoolAdmin(sendAdminNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public setToRead(id: number, options?: AxiosRequestConfig) {
        return NotificationsControllerApiFp(this.configuration).setToRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}
