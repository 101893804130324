import { Flex, Modal } from 'antd'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { FC, useCallback, useRef } from 'react'
import { Dates, SCHEDULE_TYPES, UnionSessionDto } from '@pages/schedule/types'
import ScheduleDayView from '@pages/schedule/ScheduleDayView'
import { DATE_FORMAT } from '@pages/schedule/helpers'
import axios from 'axios'
import UserScheduleWeekView from '@pages/schedule/UserScheduleWeekView'
import { PageStudentSessionEnrollmentViewDto } from '@/openapi-api/api'
import { useTranslation } from 'react-i18next'

interface Props {
  view: SCHEDULE_TYPES
  dates: Dates
  students: string[]
  setDates: (dates: Dates) => void
}

const doQuery = ({ dates, students }: { dates: Dates; students: string[] }) => {
  const query = []

  query.push(`startTime~DATE_AFTER~${dates[0].format(DATE_FORMAT)}`)
  query.push(`startTime~DATE_BEFORE~${dates[1].format(DATE_FORMAT)}`)
  query.push(`status~in~scheduled,absent,attended`)

  if (students?.length) {
    query.push(`studentId~in~${students.join(',')}`)
  }

  return query.join(';') || undefined
}

const prepareData = (
  data?: PageStudentSessionEnrollmentViewDto,
): UnionSessionDto[] =>
  data?.content?.map(
    (item) => ({ ...item.session, enrollment: item }) as UnionSessionDto,
  ) || []

const MyScheduleContent: FC<Props> = ({ view, dates, setDates, students }) => {
  const {
    studentSessionEnrollmentViewControllerApi,
    sessionStudentsAdminControllerApi,
  } = useApi()
  const { t } = useTranslation()
  const token = useRef(axios.CancelToken.source())
  const { resource: sessions, fetch: refreshSessions } = useAsyncResource({
    fetchResource: useCallback(() => {
      token.current.cancel()
      token.current = axios.CancelToken.source()
      return studentSessionEnrollmentViewControllerApi.find(
        0,
        1000,
        undefined,
        doQuery({
          dates,
          students,
        }),
        { cancelToken: token.current.token },
      )
    }, [dates, students, studentSessionEnrollmentViewControllerApi]),
  })

  const deleteSession = useCallback(
    async (session: UnionSessionDto) => {
      Modal.confirm({
        title: t`schedule.removeSessionQuestion`,
        onOk: async () => {
          await sessionStudentsAdminControllerApi.deleteById(
            session.enrollment?.entityId as number,
          )
          await refreshSessions()
          Modal.info({
            title: t`schedule.removeSessionSuccess`,
          })
        },
      })
    },
    [refreshSessions, sessionStudentsAdminControllerApi, t],
  )

  return (
    <Flex vertical>
      {view === SCHEDULE_TYPES.DAY && (
        <ScheduleDayView
          sessions={prepareData(sessions?.data)}
          dates={dates}
          setDates={setDates}
          isUserView
          scheduleUser={deleteSession}
          refresh={refreshSessions}
        />
      )}
      {view === SCHEDULE_TYPES.WEEK && (
        <UserScheduleWeekView
          sessions={prepareData(sessions?.data)}
          dates={dates}
          setDates={setDates}
          scheduleUser={deleteSession}
        />
      )}
    </Flex>
  )
}

export default MyScheduleContent
