/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteResetPasswordRequest } from '../models';
// @ts-ignore
import { InitResetPasswordRequest } from '../models';
// @ts-ignore
import { RefreshTokenRequest } from '../models';
// @ts-ignore
import { UserLoginRequest } from '../models';
// @ts-ignore
import { UserLoginResponse } from '../models';
/**
 * LoginControllerApi - axios parameter creator
 * @export
 */
export const LoginControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompleteResetPasswordRequest} completeResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePasswordReset: async (completeResetPasswordRequest: CompleteResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeResetPasswordRequest' is not null or undefined
            assertParamExists('completePasswordReset', 'completeResetPasswordRequest', completeResetPasswordRequest)
            const localVarPath = `/v1/complete-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InitResetPasswordRequest} initResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPasswordReset: async (initResetPasswordRequest: InitResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initResetPasswordRequest' is not null or undefined
            assertParamExists('initPasswordReset', 'initResetPasswordRequest', initResetPasswordRequest)
            const localVarPath = `/v1/init-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (userLoginRequest: UserLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLoginRequest' is not null or undefined
            assertParamExists('login', 'userLoginRequest', userLoginRequest)
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken: async (refreshTokenRequest: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshAccessToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/v1/refresh-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginControllerApi - functional programming interface
 * @export
 */
export const LoginControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CompleteResetPasswordRequest} completeResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completePasswordReset(completeResetPasswordRequest: CompleteResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completePasswordReset(completeResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InitResetPasswordRequest} initResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initPasswordReset(initResetPasswordRequest: InitResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initPasswordReset(initResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(userLoginRequest: UserLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(userLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAccessToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAccessToken(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginControllerApi - factory interface
 * @export
 */
export const LoginControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CompleteResetPasswordRequest} completeResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completePasswordReset(completeResetPasswordRequest: CompleteResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.completePasswordReset(completeResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InitResetPasswordRequest} initResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPasswordReset(initResetPasswordRequest: InitResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initPasswordReset(initResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserLoginRequest} userLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(userLoginRequest: UserLoginRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.login(userLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.refreshAccessToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginControllerApi - object-oriented interface
 * @export
 * @class LoginControllerApi
 * @extends {BaseAPI}
 */
export class LoginControllerApi extends BaseAPI {
    /**
     * 
     * @param {CompleteResetPasswordRequest} completeResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public completePasswordReset(completeResetPasswordRequest: CompleteResetPasswordRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).completePasswordReset(completeResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InitResetPasswordRequest} initResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public initPasswordReset(initResetPasswordRequest: InitResetPasswordRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).initPasswordReset(initResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserLoginRequest} userLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public login(userLoginRequest: UserLoginRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).login(userLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public refreshAccessToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).refreshAccessToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
