import { Avatar, Popover } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLocalStorage } from '@/utils/hooks/useLocalStorage'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { Link } from 'react-router-dom'
import { routePaths } from '@/routes'

export const TourPopover = () => {
  const [open, setOpen] = useLocalStorage('tour', true)
  const { t } = useTranslation()
  const { tour } = useGlobalContext()
  const hide = () => {
    setOpen(false)
  }

  const text = useMemo(() => {
    if (!tour?.school)
      return (
        <Trans
          i18nKey="tour.school"
          components={{
            a: <Link to={routePaths.schools.specific('new').edit} />,
          }}
        />
      )
    if (!tour?.room)
      return (
        <Trans
          i18nKey="tour.room"
          components={{
            a: <Link to={routePaths.rooms.specific('new').edit} />,
          }}
        />
      )
    if (!tour?.place)
      return (
        <Trans
          i18nKey="tour.place"
          components={{
            a: <Link to={routePaths.locations.specific('new').edit} />,
          }}
        />
      )
    if (!tour?.teacher)
      return (
        <Trans
          i18nKey="tour.teacher"
          components={{
            a: <Link to={routePaths.teachers.specific('new').edit} />,
          }}
        />
      )
    if (!tour?.group)
      return (
        <Trans
          i18nKey="tour.group"
          components={{
            a: <Link to={routePaths.groups.specific('new').edit} />,
          }}
        />
      )
  }, [tour])

  return (
    <Popover
      content={
        <div>
          {text}
          <a
            onClick={hide}
            style={{ display: 'block', marginTop: '16px' }}
          >{t`invite.close`}</a>
        </div>
      }
      trigger="click"
      open={open}
      overlayInnerStyle={{ maxWidth: '300px' }}
    >
      <Avatar
        style={{ cursor: 'pointer', backgroundColor: '#f56a00' }}
        icon={<ExclamationCircleOutlined />}
        size={32}
        onClick={() => setOpen(!open)}
      />
    </Popover>
  )
}
