import { Card, Flex, Tabs, TabsProps } from 'antd'
import { FC, useMemo } from 'react'
import { SchoolDto } from '@/openapi-api/api'
import { useTranslation } from 'react-i18next'
import { extractAvatar } from '@/utils/functions/dataUtils'

export interface Props {
  school?: SchoolDto
}

export const SchoolTabs: FC<Props> = ({ school }) => {
  const { t } = useTranslation()
  const items: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: school?.name,
        children: (
          <Card
            hoverable
            cover={
              extractAvatar(school) && (
                <img alt="example" src={extractAvatar(school)} />
              )
            }
          >
            <Card.Meta title={school?.name} description={school?.description} />
          </Card>
        ),
      },
      {
        key: '2',
        label: t`locations`,
        children: (
          <Flex vertical gap="small">
            {school?.places?.map((p) => (
              <Card hoverable cover={undefined} key={p.id}>
                <Card.Meta title={p.address} />
              </Card>
            ))}
          </Flex>
        ),
      },
      {
        key: '3',
        label: t`teachers`,
        children: 'Content of Tab Pane 3',
      },
    ],
    [school, t],
  )

  return <Tabs defaultActiveKey="1" items={items} type="card" />
}
