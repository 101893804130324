import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useApi from '@/contexts/api'
import { useLocalStorage } from '@/utils/hooks/useLocalStorage'
import { AdminDto, MediaFileDto, UserLoginResponse } from '@/openapi-api/api'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useAuthInterceptorsEffect } from '@/utils/hooks/useAuthInterceptorsEffect'

export interface IAuthContext {
  login(username: string, password: string): Promise<boolean>
  logout: () => Promise<void>
  isLoginInProgress: boolean
  isLoggedIn: boolean
  isAdmin: boolean
  isUser: boolean
  isOrganiser: boolean
  loaded: boolean
  user: any | null
  refreshUser: () => Promise<void>
  avatar?: MediaFileDto
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined)

const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { loginControllerApi, userControllerApi } = useApi()
  const navigate = useNavigate()
  const [user, setUser] = useState<AdminDto | null>(null)
  const [loaded, setLoaded] = useState(false)
  const [isLoginInProgress, setIsLoginInProgress] = useState(false)
  const [jwtData, setJwtData] = useLocalStorage<UserLoginResponse | null>(
    'festivale.auth',
    null,
  )

  const getMe = useCallback(async () => {
    const { data } = await userControllerApi.getUserDetails()
    setUser(data)
    setLoaded(true)
  }, [userControllerApi])

  useEffect(() => {
    if (jwtData) {
      getMe()
    }
  }, [getMe, jwtData])

  const isAdmin = useMemo(
    () => user?.loginCredentials?.role?.name === 'admin',
    [user],
  )

  const isUser = useMemo(
    () => user?.loginCredentials?.role?.name === 'user',
    [user],
  )

  const isOrganiser = useMemo(
    () => user?.loginCredentials?.role?.name === 'organiser',
    [user],
  )

  const avatar = useMemo(() => user?.mediaFiles?.[0], [user])

  const logout = useCallback(async () => {
    setJwtData(null)
    setUser(null)
    setIsLoginInProgress(false)
    navigate(routePaths.login)
  }, [navigate, setJwtData])

  const login = useCallback(async (username: string, password: string) => {
    setIsLoginInProgress(true)

    try {
      const { data } = await loginControllerApi.login({
        username,
        password,
      })

      setJwtData(data)
      navigate(routePaths.dashboard)
    } catch (error) {
      await setJwtData(null)
      throw error
    }

    setIsLoginInProgress(false)
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useAuthInterceptorsEffect(jwtData, setJwtData, logout)

  const value: IAuthContext = useMemo(
    () => ({
      login,
      logout,
      isLoginInProgress,
      isLoggedIn: !!jwtData,
      refreshUser: getMe,
      user,
      isAdmin,
      avatar,
      isUser,
      loaded,
      isOrganiser,
    }),
    [
      getMe,
      isAdmin,
      isLoginInProgress,
      jwtData,
      login,
      logout,
      user,
      avatar,
      isUser,
      loaded,
      isOrganiser,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuthContext() {
  const context = useContext(AuthContext)

  if (typeof context === 'undefined') {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }

  return context
}

export default AuthProvider
