import { Flex } from 'antd'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { FC, useCallback, useRef } from 'react'
import { Dates, SCHEDULE_TYPES } from '@pages/schedule/types'
import ScheduleDayView from '@pages/schedule/ScheduleDayView'
import ScheduleWeekView from '@pages/schedule/ScheduleWeekView'
import { DATE_FORMAT } from '@pages/schedule/helpers'
import ScheduleMonthView from './ScheduleMonthView'
import axios from 'axios'
import UserScheduleWeekView from './UserScheduleWeekView'
import { SessionDto } from '@/openapi-api/api'

interface Props {
  view: SCHEDULE_TYPES
  dates: Dates
  locations: string[]
  schools: string[]
  rooms: string[]
  teachers: string[]
  profiles: string[]
  groups: string[]
  setDates: (dates: Dates) => void
  scheduleUser?: (session: SessionDto) => Promise<void>
  isUserView?: boolean
}

const doQuery = ({
  dates,
  locations,
  schools,
  rooms,
  teachers,
  profiles,
  groups,
}: {
  dates: Dates
  locations: string[]
  schools: string[]
  rooms: string[]
  teachers: string[]
  groups: string[]
  profiles: string[]
}) => {
  const query = []

  query.push(`startTime~DATE_AFTER~${dates[0].format(DATE_FORMAT)}`)
  query.push(`startTime~DATE_BEFORE~${dates[1].format(DATE_FORMAT)}`)
  if (locations?.length) {
    query.push(`room.place.id~in~${locations.join(',')}`)
  }
  if (schools?.length) {
    query.push(`studentGroup.school.id~in~${schools.join(',')}`)
  }
  if (rooms?.length) {
    query.push(`room.id~in~${rooms.join(',')}`)
  }
  if (teachers?.length) {
    query.push(`teacher.id~in~${teachers.join(',')}`)
  }
  if (profiles?.length) {
    query.push(`studentGroup.profile.id~in~${profiles.join(',')}`)
  }
  if (groups?.length) {
    query.push(`studentGroup.id~in~${groups.join(',')}`)
  }

  return query.join(';') || undefined
}

const Schedule: FC<Props> = ({
  view,
  dates,
  setDates,
  schools,
  locations,
  rooms,
  teachers,
  profiles,
  groups,
  isUserView,
  scheduleUser,
}) => {
  const { sessionsAdminControllerApi } = useApi()
  const token = useRef(axios.CancelToken.source())
  const { resource: sessions, fetch: refreshSessions } = useAsyncResource({
    fetchResource: useCallback(() => {
      token.current.cancel()
      token.current = axios.CancelToken.source()
      return sessionsAdminControllerApi.find(
        0,
        1000,
        ['startTime,asc'],
        doQuery({
          dates,
          schools,
          locations,
          rooms,
          teachers,
          profiles,
          groups,
        }),
        { cancelToken: token.current.token },
      )
    }, [
      sessionsAdminControllerApi,
      dates,
      schools,
      locations,
      rooms,
      teachers,
      profiles,
      groups,
    ]),
  })

  const handleScheduleUser = useCallback(
    async (session: SessionDto) => {
      if (!scheduleUser) return
      await scheduleUser(session)
      // await refreshSessions()
    },
    [scheduleUser],
  )

  return (
    <Flex vertical>
      {view === SCHEDULE_TYPES.DAY && (
        <ScheduleDayView
          sessions={sessions?.data.content || []}
          dates={dates}
          setDates={setDates}
          isUserView={isUserView}
          scheduleUser={handleScheduleUser}
          refresh={refreshSessions}
        />
      )}
      {view === SCHEDULE_TYPES.WEEK &&
        (isUserView ? (
          <UserScheduleWeekView
            sessions={sessions?.data.content || []}
            dates={dates}
            setDates={setDates}
            scheduleUser={handleScheduleUser}
          />
        ) : (
          <ScheduleWeekView
            sessions={sessions?.data.content || []}
            dates={dates}
            setDates={setDates}
            refresh={refreshSessions}
          />
        ))}
      {view === SCHEDULE_TYPES.MONTH && (
        <ScheduleMonthView
          sessions={sessions?.data.content || []}
          dates={dates}
          setDates={setDates}
          refresh={refreshSessions}
        />
      )}
    </Flex>
  )
}

export default Schedule
