import { Button, Flex, Space, Table, Typography } from 'antd'
import { EnrollmentTabs } from '@pages/enrollments/EnrollmentTabs'
import { routePaths } from '@/routes'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { DocumentTypeDto, SchoolDto } from '@/openapi-api/api'
import { useCallback, useMemo } from 'react'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { TableParams } from '@/utils/types'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import useApi from '@/contexts/api'
import { PaperClipOutlined } from '@ant-design/icons'
import { useGetSchools } from '@/utils/hooks/entities'
import { filterOptionsFromEntity } from '@/utils/functions/optionsFromEntity'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.description?.length) {
    query.push(`description~~${filters.description[0]}`)
  }
  if (filters?.address?.length) {
    query.push(`address~~${filters.address[0]}`)
  }
  if (filters?.school?.length) {
    query.push(`school.id~in~${filters.school[0]}`)
  }
  return query.join(';') || undefined
}

export const DocsEnrollment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { documentTypesAdminControllerApi } = useApi()
  const searchColumn = useSearchColumn<SchoolDto>()
  const { tableParams, handleTableChange } = useTableFilters()
  const schools = useGetSchools()
  const { resource: locations } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        documentTypesAdminControllerApi.find(
          0,
          1000,
          doSort(tableParams),
          doQuery(tableParams),
        ),
      [documentTypesAdminControllerApi, tableParams],
    ),
  })

  const columns: ColumnsType<DocumentTypeDto> = useMemo(
    () => [
      {
        title: t`document.name`,
        dataIndex: 'name',
        sorter: true,
        filteredValue: tableParams.filters?.name || null,
        ...searchColumn('name'),
        render: (_, entity) => (
          <Link to={routePaths.enrollment.docs.specific(entity.id).edit}>
            {entity.name}
          </Link>
        ),
      },
      {
        title: t`group.description`,
        dataIndex: 'description',
        sorter: true,
        filteredValue: tableParams.filters?.description || null,
        ...searchColumn('description'),
      },
      {
        title: t`document.required`,
        dataIndex: 'required',
        sorter: true,
        render: (required: boolean) => (required ? t`yes` : t`no`),
      },
      {
        title: t`school.school`,
        dataIndex: 'school',
        sorter: true,
        filteredValue: tableParams.filters?.school || null,
        filters: filterOptionsFromEntity(schools),
        render: (school: SchoolDto) =>
          schools?.find((s) => s.id === school.id)?.name,
      },
      {
        title: t`document.viewFile`,
        dataIndex: 'action',
        render: (i, entity) => {
          const file = entity.mediaFiles?.[0]
          if (!file) return null
          return (
            <Space>
              <a
                href={file.link}
                download
                target="_blank"
                rel="noreferrer"
                style={{ whiteSpace: 'nowrap' }}
              >{t`document.viewDoc`}</a>
              <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Space>
          )
        },
      },
    ],
    [
      schools,
      searchColumn,
      t,
      tableParams.filters?.description,
      tableParams.filters?.name,
      tableParams.filters?.school,
    ],
  )

  return (
    <EnrollmentTabs active={routePaths.enrollment.docs.all}>
      <Flex
        align="center"
        justify="space-between"
        style={{ marginBottom: '8px' }}
      >
        <Typography.Title
          level={5}
          style={{ margin: 0 }}
        >{t`enrollment.docsForRegistration`}</Typography.Title>
        <Button
          onClick={() =>
            navigate(routePaths.enrollment.docs.specific('new').edit)
          }
        >{t`enrollment.addDoc`}</Button>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={locations?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: locations?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: '100%' }}
      />
    </EnrollmentTabs>
  )
}
