import React, { useRef, useState, useEffect } from 'react'
import { Popover } from 'antd'

interface EllipsisProps {
  children: React.ReactNode
  maxHeight?: number
}

const Ellipsis: React.FC<EllipsisProps> = ({ children, maxHeight = 50 }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      setIsOverflowing(
        container.scrollHeight > container.clientHeight ||
          container.scrollWidth > container.clientWidth,
      )
    }
  }, [children])

  const contentStyle: React.CSSProperties = {
    maxHeight: `${maxHeight}px`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    position: 'relative',
  }

  const ellipsisContent = (
    <div ref={containerRef} style={contentStyle}>
      {children}
      {isOverflowing && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,1) 60%, rgba(255,255,255,0))',
            paddingLeft: '12px',
            height: '100%',
            textAlign: 'right',
          }}
        >
          {'…'}
        </div>
      )}
    </div>
  )

  return isOverflowing ? (
    <Popover content={children} trigger="hover">
      {ellipsisContent}
    </Popover>
  ) : (
    ellipsisContent
  )
}

export default Ellipsis
