/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateTeacherRequest
 */
export interface CreateTeacherRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'pseudonimPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'pseudonimEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'pseudonimUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'pseudonimRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'bioPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'bioEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'bioUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'bioRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'gender'?: CreateTeacherRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTeacherRequest
     */
    'profileIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTeacherRequest
     */
    'schoolIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'specializations'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherRequest
     */
    'socialMediaLink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTeacherRequest
     */
    'createLoginCredentials'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTeacherRequestGenderEnum {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
    UNKNOWN = 'unknown'
}


