import { Avatar, Button, Card, Flex, Space, Tag, Typography } from 'antd'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { routePaths } from '@/routes'
import dayjs from 'dayjs'
import { formatTimeRange } from '@pages/schedule/helpers'
import { SessionProgress } from '@pages/attendance/SessionProgress'

interface Props {
  type: string
}

export const AttendanceStudents: FC<Props> = ({ type }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    sessionStudentsAdminControllerApi,
    sessionAttendanceViewControllerApi,
  } = useApi()
  const { id } = useParams<{ id: string }>()

  const { resource: entity, fetch } = useAsyncResource({
    fetchResource: useCallback(() => {
      const query = []

      if (type === 'absent') {
        query.push(`attended~false~`)
      } else if (type === 'attended') {
        query.push(`attended~true~`)
      }
      query.push(`session.id==${id}`)
      return sessionStudentsAdminControllerApi.find(
        0,
        1000,
        undefined,
        query.join(';'),
      )
    }, [id, sessionStudentsAdminControllerApi, type]),
  })

  const { resource: session, fetch: fetchSession } = useAsyncResource({
    fetchResource: useCallback(async () => {
      const { data } = await sessionAttendanceViewControllerApi.find(
        0,
        1,
        undefined,
        `id==${id}`,
      )
      return data.content?.[0]
    }, [id, sessionAttendanceViewControllerApi]),
  })

  const handleChangeAttendee = useCallback(
    async (id: number, studentId: number, status: boolean) => {
      await sessionStudentsAdminControllerApi.update(id, {
        attended: status,
        id: studentId,
      })
      await fetch()
      await fetchSession()
    },
    [fetch, fetchSession, sessionStudentsAdminControllerApi],
  )

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.attendance.all)}
        >
          {t`back`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {dayjs(session?.startTime).format('DD MMMM, dddd')}
        </Typography.Title>
      </Space>
      <Tag
        color={session?.group?.color}
        style={{ padding: '24px', marginBottom: '32px', marginRight: 0 }}
      >
        <Flex
          justify="space-between"
          align="center"
          wrap="wrap"
          style={{ marginBottom: '24px' }}
        >
          <Flex
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: 'auto',
              columnGap: '16px',
            }}
          >
            <Typography.Title level={4} style={{ margin: 0 }}>
              {formatTimeRange(
                dayjs(session?.startTime),
                session?.duration as number,
              )}
            </Typography.Title>
            <Typography.Title
              level={4}
              style={{ margin: 0, whiteSpace: 'wrap' }}
            >
              {session?.group?.name} {session?.group?.level} {t`with`}{' '}
              {session?.teacher?.name}
            </Typography.Title>
          </Flex>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              navigate(routePaths.groups.specific(session?.group?.id).edit)
            }
          >{t`attendance.viewEditGroup`}</Button>
        </Flex>
        <SessionProgress session={session} />
      </Tag>
      <Flex
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(347px, 1fr))',
        }}
        gap="middle"
      >
        {entity?.data.content?.map(({ student, attended, id, mediaFiles }) => (
          <Card
            style={{
              border: '1px solid',
              padding: 0,
              borderColor: attended ? 'rgba(0, 58, 201, 1)' : '#D9D9D9',
            }}
            styles={{ body: { padding: 16 } }}
            key={student?.id}
          >
            <Flex justify="space-between" align="center">
              <Flex gap="16px">
                <Avatar
                  shape="square"
                  src={
                    mediaFiles?.[0]?.thumbLink || (
                      <UserOutlined style={{ fontSize: '50px' }} />
                    )
                  }
                  style={{
                    width: '100px',
                    minWidth: '100px',
                    height: '100px',
                    backgroundColor: 'rgba(217, 217, 217, 1)',
                  }}
                />

                <Flex vertical justify="center" gap={16}>
                  <Link to="">{student?.name}</Link>
                  <Typography.Text>
                    {student?.birthDate
                      ? dayjs(student.birthDate).format('DD MMM, YYYY')
                      : ''}
                  </Typography.Text>
                </Flex>
              </Flex>
              <div
                style={{ cursor: 'pointer', marginLeft: '16px' }}
                onClick={() =>
                  handleChangeAttendee(id as number, id as number, !attended)
                }
              >
                {attended ? (
                  <CheckCircleFilled
                    style={{ fontSize: '24px', color: 'rgba(0, 58, 201, 1)' }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ fontSize: '24px', color: 'rgba(0,0,0,0.45)' }}
                  />
                )}
              </div>
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  )
}
