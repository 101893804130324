import { Breadcrumb } from 'antd'
import { routePaths } from '@/routes'
import { HomeOutlined } from '@ant-design/icons'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGlobalContext } from '@/contexts/GlobalContext'
import styles from './Styles.module.less'

export const Breadcrumbs = () => {
  const { pathname } = useLocation()
  const { breadcrumbs } = useGlobalContext()
  const { t } = useTranslation()

  const items = useMemo(() => {
    if (matchPath(routePaths.profile, pathname))
      return [{ name: t`myProfile`, href: '' }]
    if (matchPath(routePaths.rooms.all, pathname))
      return [{ name: t`rooms`, href: '' }]
    if (matchPath(routePaths.schools.all, pathname))
      return [{ name: t`mySchools`, href: '' }]
    if (
      matchPath(routePaths.organiser.tournaments.all, pathname) ||
      matchPath(routePaths.admin.tournaments.all, pathname)
    )
      return [{ name: t`tournaments.tournaments`, href: '' }]

    if (
      matchPath(routePaths.organiser.categories.all, pathname) ||
      matchPath(routePaths.admin.categories.all, pathname)
    )
      return [{ name: t`tournaments.categories`, href: '' }]
    if (matchPath(routePaths.organiser.categories.createBulk, pathname))
      return [
        {
          name: t`tournaments.categories`,
          href: routePaths.organiser.categories.all,
        },
        {
          name: t`tCategories.addBulk`,
          href: '',
        },
      ]
    if (matchPath(routePaths.schedule, pathname))
      return [{ name: t`schedule.title`, href: '' }]
    if (matchPath(routePaths.admin.students.all, pathname))
      return [{ name: t`students`, href: '' }]
    if (matchPath(routePaths.attendance.all, pathname))
      return [{ name: t`attendance.attendance`, href: '' }]
    if (matchPath(routePaths.attendance.specific(':id'), pathname))
      return [
        { name: t`attendance.attendance`, href: routePaths.attendance.all },
        { name: t`attendance.registerAttendance`, href: '' },
      ]
    if (matchPath(routePaths.groups.all, pathname))
      return [{ name: t`groups`, href: '' }]
    if (matchPath(routePaths.user.schools.all, pathname))
      return [{ name: t`school.school`, href: '' }]
    if (
      matchPath(routePaths.user.enrollment, pathname) ||
      matchPath(
        routePaths.enrollment.student(':schoolId', ':studentId'),
        pathname,
      )
    )
      return [{ name: t`enrollment.enrollment`, href: '' }]
    if (matchPath(routePaths.schools.specific(':id').edit, pathname))
      return [
        { name: t`mySchools`, href: routePaths.schools.all },
        ...breadcrumbs,
      ]
    if (
      matchPath(routePaths.organiser.tournaments.specific(':id').edit, pathname)
    )
      return [
        {
          name: t`tournaments.tournaments`,
          href: routePaths.organiser.tournaments.all,
        },
        ...breadcrumbs,
      ]
    if (
      matchPath(routePaths.organiser.categories.specific(':id').edit, pathname)
    )
      return [
        {
          name: t`tournaments.categories`,
          href: routePaths.organiser.categories.all,
        },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.rooms.specific(':id').edit, pathname))
      return [{ name: t`rooms`, href: routePaths.rooms.all }, ...breadcrumbs]
    if (matchPath(routePaths.locations.specific(':id').edit, pathname))
      return [
        { name: t`locations`, href: routePaths.locations.all },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.locations.specific(':id').rooms.all, pathname))
      return [
        { name: t`locations`, href: routePaths.locations.all },
        ...breadcrumbs,
      ]
    if (
      matchPath(
        routePaths.locations.specific(':locationId').rooms.specific(':id').edit,
        pathname,
      )
    )
      return [
        { name: t`locations`, href: routePaths.locations.all },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.groups.specific(':id').edit, pathname))
      return [{ name: t`groups`, href: routePaths.groups.all }, ...breadcrumbs]
    if (matchPath(routePaths.groups.specific(':id').schedule, pathname))
      return [{ name: t`groups`, href: routePaths.groups.all }, ...breadcrumbs]
    if (matchPath(routePaths.locations.all, pathname))
      return [{ name: t`locations`, href: '' }]
    if (matchPath(routePaths.user.schedule, pathname))
      return [{ name: t`schedule.title`, href: '' }]
    if (
      matchPath(routePaths.notifications.sent, pathname) ||
      matchPath(routePaths.notifications.item(':id'), pathname) ||
      matchPath(routePaths.notifications.received, pathname)
    )
      return [{ name: t`notifications.notifications`, href: '' }]
    if (
      matchPath(routePaths.schools.specific(':id').edit, pathname) ||
      matchPath(routePaths.schools.specific(':id').view, pathname)
    )
      return [
        { name: t`locations`, href: routePaths.locations.all },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.teachers.all, pathname))
      return [{ name: t`teachers`, href: '' }]
    if (
      matchPath(routePaths.teachers.specific(':id').edit, pathname) ||
      matchPath(routePaths.teachers.specific(':id').view, pathname)
    )
      return [
        { name: t`teachers`, href: routePaths.teachers.all },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.user.students.all, pathname))
      return [{ name: t`students`, href: '' }]
    if (matchPath(routePaths.user.students.specific(':id').edit, pathname))
      return [
        { name: t`students`, href: routePaths.user.students.all },
        ...breadcrumbs,
      ]
    if (matchPath(routePaths.enrollment.docs.specific(':id').edit, pathname))
      return [
        {
          name: t`enrollment.enrollment`,
          href: routePaths.enrollment.docs.all,
        },
        ...breadcrumbs,
      ]
    if (
      matchPath(routePaths.enrollment.docs.all, pathname) ||
      matchPath(routePaths.enrollment.schools, pathname) ||
      matchPath(routePaths.enrollment.groups, pathname)
    )
      return [{ name: t`enrollment.enrollment`, href: '' }]
    if (matchPath(routePaths.payments.all, pathname))
      return [{ name: t`payments.menPayments.tabTitle`, href: '' }]
    if (matchPath(routePaths.payments.specific(':id').view, pathname))
      return [
        {
          name: t`payments.menPayments.tabTitle`,
          href: routePaths.payments.all,
        },
        ...breadcrumbs,
      ]
  }, [breadcrumbs, pathname, t])

  if (!items) return null

  return (
    <Breadcrumb style={{ margin: '16px 0' }} className={styles.breadcrumbs}>
      <Breadcrumb.Item href={routePaths.dashboard}>
        <HomeOutlined />
      </Breadcrumb.Item>
      {items.map(({ name, href }) => (
        <Breadcrumb.Item key={name} href={href}>
          {name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}
