export const filterOptionsFromEntity = <
  T extends { id?: number; name?: string }[],
>(
  entity?: T,
) =>
  entity?.map((p) => ({
    text: p.name as string,
    value: p.id?.toString() as string,
  })) || []

export const optionsFromEntity = <T extends { id?: number; name?: string }[]>(
  entity?: T,
) =>
  entity?.map((p) => ({
    label: p.name as string,
    value: p.id?.toString() as string,
  })) || []

export const optionsFromEntityNoId = <
  T extends { id?: number; name?: string }[],
>(
  entity?: T,
) =>
  entity?.map((p) => ({
    label: p.name as string,
    value: p.name as string,
  })) || []
