import { SessionTime } from '@/openapi-api/api'
import dayjs, { Dayjs } from 'dayjs'

export const dateToSessionTime = (dates: [Dayjs, Dayjs]): SessionTime => ({
  oddWeek: true,
  evenWeek: true,
  duration: dates[1].diff(dates[0], 'minute'),
  startTime: dates[0].format('HH:mm:ss') as any,
})

export const disableDatesInFuture = (current: Dayjs) =>
  current && current > dayjs().endOf('day')

export const disableDatesInPast = (current: Dayjs) =>
  current && current.isBefore(dayjs().startOf('day'))

export const formatTDate = (date: Dayjs) =>
  date && `${date.format('YYYY-MM-DD')}T${date.format('HH:mm:ss')}`
