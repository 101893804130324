import {
  Button,
  Flex,
  GetProp,
  Modal,
  QRCode,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo, useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { ProfileDto, SchoolDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { addFilterToUrl } from '@/utils/functions/filterUtils'
import { SCHEDULE_TYPES } from '@pages/schedule/types'
import {
  CopyOutlined,
  PrinterOutlined,
  FileImageOutlined,
} from '@ant-design/icons'
import printJS from 'print-js'
import useIsDevice from '@/utils/hooks/useIsMobile'

const doQuery = (filters: Parameters<GetProp<TableProps, 'onChange'>>[1]) => {
  const query = []
  if (filters.profiles?.length) {
    query.push(`profiles.id$~$${filters.profiles.toString()}`)
  }
  if (filters.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  return query.join(';') || undefined
}

const Schools = () => {
  const { t } = useTranslation()
  const { schoolsAdminControllerApi, profilesAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { isBothMobile } = useIsDevice()
  const [filters, setFilters] = useState<
    Parameters<GetProp<TableProps, 'onChange'>>[1]
  >({})

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        schoolsAdminControllerApi.find(0, 1000, undefined, doQuery(filters)),
      [schoolsAdminControllerApi, filters],
    ),
  })

  const searchColumn = useSearchColumn<SchoolDto>()

  const { resource: profiles } = useAsyncResource({
    fetchResource: useCallback(
      () => profilesAdminControllerApi.find(),
      [profilesAdminControllerApi],
    ),
  })

  const handleTableChange: TableProps['onChange'] = (_, filters) => {
    setFilters(filters)
  }

  const handlePrint = () => {
    const canvas = document.querySelector(
      '#myqrcode canvas',
    ) as HTMLCanvasElement
    printJS({
      printable: canvas.toDataURL(),
      type: 'image',
      imageStyle: 'width:100%',
    })
  }

  const columns: ColumnsType<SchoolDto> = useMemo(
    () => [
      {
        title: t`school.name`,
        dataIndex: 'name',
        ...searchColumn('name'),
      },
      {
        title: t`profile.profile`,
        dataIndex: 'profiles',
        filters:
          profiles?.data.content?.map((p) => ({
            text: p.name as string,
            value: p.id?.toString() as string,
          })) || [],
        render: (profiles: ProfileDto[]) => (
          <Space size="middle">
            {profiles?.map((p) => <Tag key={p.name}>{p.name}</Tag>)}
          </Space>
        ),
      },
      {
        title: t`locations`,
        dataIndex: 'places',
        render: (_, e) => (
          <Link
            to={
              routePaths.locations.all +
              addFilterToUrl({ filters: { schoolId: [e.id] } })
            }
          >
            {e.places?.length}
          </Link>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={routePaths.schools.specific(entity.id).view}
            >{t`profile.profile`}</Link>
            <Link
              to={
                routePaths.schedule +
                addFilterToUrl({
                  schools: [entity.id],
                  view: isBothMobile ? SCHEDULE_TYPES.DAY : SCHEDULE_TYPES.WEEK,
                })
              }
            >{t`schedule.title`}</Link>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                const url =
                  window.location.origin + routePaths.schoolInvite(entity.id)
                Modal.confirm({
                  icon: null,
                  width: 'fit-content',
                  content: (
                    <Flex vertical gap={8} id="myqrcode">
                      <QRCode value={url} type="canvas" />
                      <Flex vertical gap={4}>
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() => navigator.clipboard.writeText(url)}
                        >
                          {t`copyUrl`}
                        </Button>
                        <Button
                          icon={<FileImageOutlined />}
                          onClick={() => {
                            const canvas = document.querySelector(
                              '#myqrcode canvas',
                            ) as HTMLCanvasElement
                            if (canvas) {
                              canvas.toBlob((blob) => {
                                if (blob) {
                                  const item = new ClipboardItem({
                                    'image/png': blob,
                                  })
                                  console.log(item)
                                  navigator.clipboard.write([item])
                                }
                              })
                            }
                          }}
                        >
                          {t`copyAsImage`}
                        </Button>
                        <Button
                          icon={<PrinterOutlined />}
                          onClick={handlePrint}
                        >
                          {t`print`}
                        </Button>
                      </Flex>
                    </Flex>
                  ),
                  cancelButtonProps: { style: { display: 'none' } },
                })
              }}
            >{t`qrCode`}</Button>
          </Space>
        ),
      },
    ],
    [isBothMobile, profiles?.data.content, searchColumn, t],
  )

  return (
    <Flex vertical>
      <div style={{ marginBottom: '24px' }}>
        <Button
          onClick={() => navigate(routePaths.schools.specific('new').edit)}
        >{t`school.add`}</Button>
      </div>
      <Typography.Title level={5}>{t`school.school`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={schools?.data.content || []}
        onChange={handleTableChange}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default Schools
