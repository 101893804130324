import { FC, useCallback, useMemo, useState } from 'react'
import { Button, Form, Input, Modal, Space } from 'antd'
import { SendUserNotificationRequestTypeEnum } from '@/openapi-api/api'
import { FormInput } from '@components/Form/FormInput'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import { useLanguageContext } from '@/contexts/LanguageProvider'

interface Props {
  schoolId: number
  entityId: number
  type: SendUserNotificationRequestTypeEnum
  onSuccess?: () => void
}

const SendNotificationForm: FC<Props> = ({
  schoolId,
  entityId,
  type,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { notificationsControllerApi } = useApi()
  const { appLang } = useLanguageContext()

  const handleOk = useCallback(
    async ({ subject, text }: { subject: string; text: string }) => {
      Modal.confirm({
        title: t`notifications.confirmSend`,
        okText: t`yes`,
        cancelText: t`no`,
        onOk: async () => {
          try {
            await notificationsControllerApi.send({
              subject,
              text,
              lang: appLang as any,
              schoolId,
              entityId,
              type,
            })
            onSuccess?.()
            Modal.success({
              title: t`notifications.successSend`,
            })
          } catch (e) {}
        },
      })
    },
    [
      appLang,
      entityId,
      notificationsControllerApi,
      onSuccess,
      schoolId,
      t,
      type,
    ],
  )

  return (
    <Form
      layout="vertical"
      onFinish={handleOk}
      size="large"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px',
      }}
    >
      <FormInput
        label={t`notifications.sendMessageSubject`}
        name="subject"
        rules={[{ required: true, message: t`required` }]}
      />
      <Form.Item
        name="text"
        label={t`group.description`}
        rules={[{ required: true, message: t`required` }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Space size="large" style={{ justifyContent: 'center' }}>
        <Button type="text" onClick={() => onSuccess?.()}>
          {t`invite.close`}
        </Button>
        <Button type="primary" htmlType="submit">
          {t`sentMessage`}
        </Button>
      </Space>
    </Form>
  )
}

export const useSendNotification = () => {
  const [data, setData] = useState<
    | {
        entityId: number
        type: SendUserNotificationRequestTypeEnum
        schoolId: number
      }
    | undefined
  >()
  const { t } = useTranslation()

  const modal = useMemo(() => {
    if (!data) return null

    return (
      <Modal
        title={t`notifications.sendMessageTitle`}
        open={!!data}
        footer={[]}
        destroyOnClose
        closeIcon={null}
      >
        <SendNotificationForm {...data} onSuccess={() => setData(undefined)} />
      </Modal>
    )
  }, [data, t])

  return { setData, modal }
}
