import { Flex, Tabs } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttendanceStudents } from '@pages/attendance/AttendanceStudents'

export const AttendanceStudentsWrapper = () => {
  const { t } = useTranslation()
  const [key, setKey] = useState('all')
  const onChange = useCallback((key: string) => setKey(key), [])

  return (
    <Flex vertical>
      <Tabs
        onChange={onChange}
        type="card"
        activeKey={key}
        items={[
          {
            label: t`attendance.allStudents`,
            key: 'all',
          },
          { label: t`attendance.presentStudents`, key: 'attended' },
          { label: t`attendance.absentStudents`, key: 'absent' },
        ]}
        tabBarStyle={{ marginBottom: 0 }}
      />
      <Flex
        vertical
        style={{
          backgroundColor: 'white',
          height: '100%',
          padding: '16px',
          marginLeft: '1px',
        }}
      >
        <AttendanceStudents type={key} />
      </Flex>
    </Flex>
  )
}
