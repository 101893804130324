import { Avatar, Button, Flex, Space, Table, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { ProfileDto, SchoolDto, TeacherDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { useTableDynamicColumns } from '@/utils/hooks/useTableDynamicColumns'
import { UserOutlined } from '@ant-design/icons'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { FilterValue } from 'antd/es/table/interface'
import { useGetProfiles } from '@/utils/hooks/entities'
import { filterOptionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { addFilterToUrl } from '@/utils/functions/filterUtils'
import { SCHEDULE_TYPES } from '@pages/schedule/types'
import useIsDevice from '@/utils/hooks/useIsMobile'
import Ellipsis from '@components/Ellipsis'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.profiles?.length) {
    query.push(`profiles.id$~$${filters.profiles.toString()}`)
  }
  if (filters?.phone?.length) {
    query.push(`phone~~${filters.phone.toString()}`)
  }
  if (filters?.email?.length) {
    query.push(`email~~${filters.email.toString()}`)
  }
  if (filters?.specializations?.length) {
    query.push(`specializations~~${filters.specializations.toString()}`)
  }
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  return query.join(';') || undefined
}

const PAGE_SIZE = 10

const TeachersList = () => {
  const { t } = useTranslation()
  const { teachersAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { isBothMobile } = useIsDevice()
  const { tableParams, handleTableChange, setTableParams } =
    useTableFilters(PAGE_SIZE)

  const { resource: list } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        teachersAdminControllerApi.find(
          0,
          PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams),
        ),
      [teachersAdminControllerApi, tableParams],
    ),
  })

  const profiles = useGetProfiles()

  const searchColumn = useSearchColumn<SchoolDto>()

  const columns: ColumnsType<TeacherDto> = useMemo(
    () => [
      {
        title: t`teacher.fio`,
        dataIndex: 'name',
        sorter: true,
        ...searchColumn('name'),
        filteredValue: tableParams.filters?.name || null,
        render: (_, entity) => (
          <Space size="middle">
            <Avatar
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.link || <UserOutlined />}
            />
            <Link to={routePaths.teachers.specific(entity.id).view}>
              {entity.name}
            </Link>
          </Space>
        ),
      },
      {
        title: t`profile.profile`,
        dataIndex: 'profiles',
        filteredValue: tableParams.filters?.profiles || null,
        filters: filterOptionsFromEntity(profiles),
        ellipsis: true,
        render: (profiles: ProfileDto[]) => (
          <Ellipsis>
            <Space size="middle">
              {profiles?.map((p) => (
                <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setTableParams((old) => ({
                      ...old,
                      filters: {
                        ...old.filters,
                        profiles: [p.id] as FilterValue,
                      },
                    }))
                  }
                  key={p.id}
                >
                  {p.name}
                </Tag>
              ))}
            </Space>
          </Ellipsis>
        ),
      },
      {
        title: t`teacher.specialization`,
        dataIndex: 'specializations',
        ellipsis: true,
        filteredValue: tableParams.filters?.specializations || null,
        ...searchColumn('specializations'),
        render: (specialization: string) => (
          <Ellipsis>
            <Space size="middle">
              {specialization?.split(', ')?.map((p) => (
                <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setTableParams((old) => ({
                      ...old,
                      filters: {
                        ...old.filters,
                        specializations: [p] as FilterValue,
                      },
                    }))
                  }
                  key={p}
                >
                  {p}
                </Tag>
              ))}
            </Space>
          </Ellipsis>
        ),
      },
      {
        title: t`profile.phone`,
        dataIndex: 'phone',
        ...searchColumn('phone'),
        filteredValue: tableParams.filters?.phone || null,
      },
      {
        title: t`profile.email`,
        dataIndex: 'email',
        ...searchColumn('email'),
        filteredValue: tableParams.filters?.email || null,
      },
      {
        title: t`school.school`,
        dataIndex: 'schools',
        render: (_, entity) =>
          entity.schools?.map(({ name }) => name).join(', '),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={routePaths.teachers.specific(entity.id).edit}
            >{t`Edit`}</Link>
            <Link
              to={
                routePaths.schedule +
                addFilterToUrl({
                  teachers: [entity.id],
                  view: isBothMobile ? SCHEDULE_TYPES.DAY : SCHEDULE_TYPES.WEEK,
                })
              }
            >{t`schedule.title`}</Link>
          </Space>
        ),
      },
    ],
    [
      isBothMobile,
      profiles,
      searchColumn,
      setTableParams,
      t,
      tableParams.filters?.email,
      tableParams.filters?.name,
      tableParams.filters?.phone,
      tableParams.filters?.profiles,
      tableParams.filters?.specializations,
    ],
  )
  const { control, filteredColumns } = useTableDynamicColumns(columns)

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '24px' }} justify="space-between">
        <Button
          onClick={() => navigate(routePaths.teachers.specific('new').edit)}
        >{t`teacher.add`}</Button>
      </Flex>
      <Flex justify="space-between" align="center">
        <Typography.Title level={5}>{t`teachers`}</Typography.Title>
        {control}
      </Flex>
      <Table
        columns={filteredColumns}
        tableLayout="fixed"
        scroll={{ x: 1500 }}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={list?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: list?.data.totalElements,
          position: ['bottomLeft'],
        }}
      />
    </Flex>
  )
}

export default TeachersList
