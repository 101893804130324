import {
  Button,
  DatePicker,
  Descriptions,
  Flex,
  Form,
  FormProps,
  Modal,
  Select,
  Space,
  TimePicker,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { Loading } from '@components/Loading'
import {
  CreateScheduledSessionsRequest,
  CreateScheduledSessionsResponse,
  StudentGroupExtendedDto,
} from '@/openapi-api/api'
import {
  useGetAllGroups,
  useGetLocations,
  useGetRooms,
  useGetTeachers,
} from '@/utils/hooks/entities'
import { getValue } from '@/utils/functions/dataUtils'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { days } from '@/utils/constants'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import {
  dateToSessionTime,
  disableDatesInPast,
} from '@/utils/functions/dateUtils'
import { Separator } from '@components/Separator'
import { startDateInFuture } from '@/utils/functions/validators'
import { Text } from '@components/Text'
import { AxiosResponse } from 'axios'
import { ButtonAccordion } from '@components/ButtonAccordion'

interface Props {
  minimal?: boolean
}

function extractErrors(arr: CreateScheduledSessionsResponse[]) {
  const result: Array<[string, string]> = []
  arr.forEach((a) => {
    if (a.errors) {
      result.push(...Object.entries(a.errors))
    }
  })
  return result
}

const GroupSchedule: FC<Props> = ({ minimal }) => {
  const [submitting, setSubmitting] = useState(false)
  const [collapsed, setCollapsed] = useState<number[]>([])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { studentGroupsAdminControllerApi, sessionsAdminControllerApi } =
    useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const locationId = Form.useWatch('locationId', form)
  const groupId = Form.useWatch('groupId', form)
  const { getLocaleField } = useLocaleHelpers()

  const entityId: string = useMemo(() => id || groupId, [groupId, id])

  const { resource: group, loaded } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!entityId)
        return { data: {} } as AxiosResponse<StudentGroupExtendedDto>
      return studentGroupsAdminControllerApi.getByIdExtended(+entityId)
    }, [entityId, studentGroupsAdminControllerApi]),
  })

  const teachers = useGetTeachers(getValue(group?.data.school?.id) as string)
  const locations = useGetLocations(getValue(group?.data.school?.id) as string)
  const rooms = useGetRooms(getValue(locationId) as string)
  const groups = useGetAllGroups()

  const title = useMemo(
    () =>
      group?.data
        ? `${t('schedule.title')} ${getLocaleField('namePL', group.data)}`
        : '',
    [getLocaleField, group?.data, t],
  )

  useEffect(() => {
    if (id) {
      form.setFieldValue('groupId', id)
    }
  }, [form, id])

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const result: any = []
      values.items.forEach((value: any) => {
        const dataToSend: CreateScheduledSessionsRequest = {
          session: {
            roomId: getValue(values.roomId, true) as number,
            teacherId: getValue(values.teacherId, true) as number,
            studentGroupId: group?.data.id as number,
          },
          schedule: {
            startDate: value.generalRange[0].format('YYYY-MM-DD'),
            endDate: value.generalRange[1].format('YYYY-MM-DD'),
            dailySessions: {},
          },
        }

        days.forEach((d) => {
          if (value[d].length) {
            value[d].forEach((it: any) => {
              if (!it?.timeRange) return
              if (it.timeRange?.every((val: any) => !!val)) {
                if (!dataToSend.schedule.dailySessions[d]) {
                  dataToSend.schedule.dailySessions[d] = [
                    dateToSessionTime(it.timeRange),
                  ]
                } else {
                  dataToSend.schedule.dailySessions[d].push(
                    dateToSessionTime(it.timeRange),
                  )
                }
              }
            })
          }
        })
        result.push(dataToSend)
      })
      const response =
        await sessionsAdminControllerApi.scheduleGroupSessions(result)

      const errors = extractErrors(response.data || [])

      const { destroy } = Modal.info({
        title: t`schedule.sessionAdded`,
        footer: (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                destroy()
                form.resetFields()
                navigate(routePaths.scheduleAdd)
              }}
            >{t`schedule.addAnotherSession`}</Button>
            <Button
              type="primary"
              onClick={() => {
                destroy()
                navigate(routePaths.schedule)
              }}
            >{t`schedule.finishAddingSessions`}</Button>
          </Space>
        ),
        content: (
          <Flex vertical>
            <Typography.Text strong>
              {t('schedule.sessionAddedSuccessfully', {
                number: response.data.reduce(
                  (acc, val) => acc + (val.sessions?.scheduled.length || 0),
                  0,
                ),
              })}
            </Typography.Text>
            {!!errors?.length && (
              <Typography.Text strong type="danger">
                {t('schedule.sessionAddedError', {
                  number: errors.length,
                })}
              </Typography.Text>
            )}
            {!!errors?.length && (
              <ButtonAccordion title={t`schedule.moreErrorInfo`} center={false}>
                {errors.map(([title, text]) => (
                  <Descriptions
                    title=""
                    bordered
                    key={title}
                    size="small"
                    layout="vertical"
                    style={{ marginBottom: '4px' }}
                  >
                    <Descriptions.Item label={title} span={3}>
                      {text}
                    </Descriptions.Item>
                  </Descriptions>
                ))}
              </ButtonAccordion>
            )}
          </Flex>
        ),
      })
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)
  if (!loaded) return <Loading />

  return (
    <Flex vertical>
      {!minimal && (
        <Space
          size="large"
          align="center"
          style={{ marginBottom: '24px', justifyContent: 'space-between' }}
        >
          <Space size="large">
            <Button
              icon={<ArrowLeftOutlined />}
              iconPosition="start"
              onClick={() =>
                navigate(routePaths.groups.specific(group?.data.id).edit)
              }
            >
              {t`back`}
            </Button>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {title}
            </Typography.Title>
          </Space>
        </Space>
      )}

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        disabled={submitting}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        initialValues={{
          teacherId: group?.data.teacher?.id
            ? String(group.data.teacher.id)
            : null,
          items: [
            {
              ...days.reduce((acc: Record<any, any>, d) => {
                acc[d] = [null]
                return acc
              }, {}),
            },
          ],
        }}
      >
        <Form.Item
          name="groupId"
          label={t`group.group`}
          rules={[{ required: true, message: t`required` }]}
          style={{ maxWidth: '480px' }}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(groups)}
            optionFilterProp="label"
            showSearch
            disabled={!minimal}
          />
        </Form.Item>
        <Flex vertical style={{ maxWidth: '480px' }}>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div
                style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}
              >
                {fields.map((field, index) => (
                  <Flex vertical key={field.key}>
                    <Flex
                      style={{
                        marginLeft: '25%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '75%',
                        marginBottom: '24px',
                      }}
                    >
                      <Text type="LG/Strong">{t`group.addTimerange`}</Text>
                      <Space style={{ width: 'auto' }}>
                        {fields.length > 1 && (
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => remove(index)}
                          />
                        )}
                        {fields.length === 1 && (
                          <Button
                            icon={<ClearOutlined />}
                            onClick={() => form.resetFields(['items', '0'])}
                          />
                        )}
                        <Button
                          type="primary"
                          icon={
                            collapsed.includes(index) ? (
                              <PlusOutlined />
                            ) : (
                              <MinusOutlined />
                            )
                          }
                          onClick={() =>
                            collapsed.includes(index)
                              ? setCollapsed((old) =>
                                  old.filter((i) => i !== index),
                                )
                              : setCollapsed((old) => [...old, index])
                          }
                        />
                      </Space>
                    </Flex>
                    <Flex
                      vertical
                      style={{
                        maxHeight: collapsed.includes(index) ? 0 : '500px',
                        transition: 'max-height 0.26s ease',
                        overflow: 'hidden',
                      }}
                    >
                      <Form.Item
                        label={t`schedule.range`}
                        name={[field.name, 'generalRange']}
                        rules={[
                          { required: true, message: t`required` },
                          {
                            validator: startDateInFuture,
                            message: t`validation.startDateInFuture`,
                          },
                        ]}
                      >
                        <DatePicker.RangePicker
                          needConfirm={false}
                          disabledDate={disableDatesInPast}
                        />
                      </Form.Item>
                      {days.map((d) => (
                        <Form.List name={[field.name, d]} key={d}>
                          {(subFields, { add }) => (
                            <Flex
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '25% 37.5% 37.5%',
                              }}
                            >
                              <Typography>
                                {t(`days.${d}`)}
                                {':'}
                              </Typography>
                              {subFields.map((subField) => (
                                <Form.Item
                                  name={[subField.name, 'timeRange']}
                                  key={subField.key}
                                  labelCol={{ span: 0 }}
                                  wrapperCol={{ span: 23 }}
                                >
                                  <TimePicker.RangePicker
                                    minuteStep={5}
                                    showHour
                                    showMinute
                                    needConfirm={false}
                                  />
                                </Form.Item>
                              ))}
                              {subFields.length === 1 && (
                                <Button icon={<PlusOutlined />} onClick={add} />
                              )}
                            </Flex>
                          )}
                        </Form.List>
                      ))}
                    </Flex>
                  </Flex>
                ))}
                <Button
                  onClick={() =>
                    add(
                      days.reduce((acc: Record<any, any>, d) => {
                        acc[d] = [null]
                        return acc
                      }, {}),
                    )
                  }
                >
                  {'+'}
                </Button>
              </div>
            )}
          </Form.List>
        </Flex>
        <Separator />
        <Flex vertical style={{ maxWidth: '480px' }}>
          <Form.Item
            name="teacherId"
            label={t`group.teacher`}
            rules={[{ required: true, message: t`required` }]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(teachers)}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="locationId"
            label={t`location.location`}
            rules={[{ required: true, message: t`required` }]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(locations)}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="roomId"
            label={t`room.room`}
            rules={[{ required: true, message: t`required` }]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(rooms)}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
        </Flex>
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default GroupSchedule
