import { FormInput } from '@components/Form/FormInput'
import { Button, Checkbox, Flex, Form, Radio, Typography } from 'antd'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LoginViewProps } from '@pages/login/types'
import { CheckCircleFilled } from '@ant-design/icons'
import styles from './index.module.less'
import commonStyles from '@/common.module.less'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import {
  UserRegistrationRequest,
  UserRegistrationRequestRoleEnum,
} from '@/openapi-api/api'
import { useNotificationContext } from '@/contexts/NotificationProvider'
import { isAxiosError } from 'axios'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { EMAIL_REGEXP, NO_DIGITS } from '@/utils/constants'

export const RegistrationView: FC<LoginViewProps> = ({ changeLayout }) => {
  const [formStep, setFormStep] = useState(true)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { registrationControllerApi, countriesControllerApi } = useApi()
  const { error } = useNotificationContext()
  const role = Form.useWatch('role', form)
  const organizer = useMemo(
    () => role === UserRegistrationRequestRoleEnum.ORGANISER,
    [role],
  )

  const handleOk = useCallback(
    async (formData: any) => {
      try {
        const { data } = await countriesControllerApi.find()
        const countryId =
          data.content?.find((item) => item.code === 'PL')?.id || 1
        const dataToSend: UserRegistrationRequest = {
          role: formData.role,
          firstNamePL: formData.firstName,
          lastNamePL: formData.lastName || 'Organiser',
          email: formData.email,
          countryId,
          phone: formData.phone,
          password: formData.password,
        }
        await registrationControllerApi.register(dataToSend)
        setFormStep(false)
      } catch (e) {
        if (isAxiosError(e)) {
          const code = e.response?.data.code
          if (code === 'EMAIL_ALREADY_EXISTS') {
            form.setFields([
              { name: 'email', errors: [t`login.emailDuplicate`] },
            ])
          } else if (code === 'PHONE_ALREADY_EXISTS') {
            form.setFields([
              { name: 'phone', errors: [t`login.phoneDuplicate`] },
            ])
          } else {
            error({
              message: e.response?.data.message,
              showProgress: true,
              pauseOnHover: true,
            })
          }
        }
      }
    },
    [countriesControllerApi, error, form, registrationControllerApi, t],
  )

  return formStep ? (
    <Form
      layout="vertical"
      onFinish={handleOk}
      form={form}
      className={styles.registrationWrapper}
      initialValues={{
        role: UserRegistrationRequestRoleEnum.USER,
      }}
      size="large"
    >
      <Flex
        className={cn(commonStyles.fromTablet, styles.haveAccount)}
        justify="flex-end"
        align="center"
      >
        <Typography.Text>{t`login.haveAccount`}</Typography.Text>
        <Button
          type="link"
          size="small"
          onClick={() => changeLayout('login')}
        >{t`login.logIn`}</Button>
      </Flex>
      <Typography.Title>{t`welcome`}</Typography.Title>
      <div className={styles.radioWrapper}>
        <Typography.Text
          style={{ padding: '8px 0' }}
          className={styles.smallText}
        >{t`login.signInTitle`}</Typography.Text>
        <Form.Item name="role" className={styles.formItem}>
          <Radio.Group
            buttonStyle="solid"
            className={styles.radioGroup}
            style={{ display: 'flex' }}
          >
            <Radio.Button
              value={UserRegistrationRequestRoleEnum.USER}
              className={styles.radioItem}
            >{t`login.parent`}</Radio.Button>
            <Radio.Button
              value={UserRegistrationRequestRoleEnum.ADMIN}
              className={styles.radioItem}
            >{t`admin`}</Radio.Button>
            <Radio.Button
              value={UserRegistrationRequestRoleEnum.ORGANISER}
              className={styles.radioItem}
            >{t`organizer`}</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </div>
      <Flex
        justify="right"
        className={commonStyles.onlyMobile}
        style={{ marginBottom: '16px' }}
      >
        <Button
          type="link"
          size="small"
          onClick={() => changeLayout('login')}
        >{t`login.haveAccount`}</Button>
      </Flex>
      <FormInput
        label={t`email/login`}
        name="email"
        rules={[
          { required: true, message: t`required` },
          {
            pattern: new RegExp(EMAIL_REGEXP),
            message: t`wrongEmail`,
          },
        ]}
      />
      <FormInput
        label={organizer ? t`nazwa` : t`login.name`}
        placeholder={
          organizer ? t`login.organiserName` : t`login.namePlaceholder`
        }
        name="firstName"
        rules={[
          { required: true, message: t`required` },
          { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
          { min: 1, max: 50 },
        ]}
      />
      {!organizer && (
        <FormInput
          label={t`login.lastName`}
          placeholder={t`login.lastNamePlaceholder`}
          name="lastName"
          rules={[
            { required: true, message: t`required` },
            { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
            { min: 1, max: 50 },
          ]}
        />
      )}

      <FormPhoneInput label={t`login.phone`} />
      <FormInput
        label={t`password`}
        name="password"
        rules={[
          {
            required: true,
            pattern: new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)'),
          },
          { min: 8, max: 50 },
        ]}
        type="password"
        help={t`login.passwordExtra`}
      />
      <FormInput
        style={{ marginTop: '16px' }}
        label={t`login.repeatPassword`}
        name="rePassword"
        rules={[
          {
            required: true,
            message: t`required`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t`login.passwordDontMatch`))
            },
          }),
        ]}
        type="password"
      />
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t`login.privacyPolicyShouldAccept`)),
          },
        ]}
      >
        <Checkbox>
          <Trans
            i18nKey="login.privacyPolicy"
            components={{ a: <Link to={routePaths.app} /> }}
          />
        </Checkbox>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        style={{ marginBottom: '16px', marginTop: '8px' }}
        className={styles.sighInButton}
      >
        {t`login.signInAction`}
      </Button>
    </Form>
  ) : (
    <Flex justify="center" align="center" vertical style={{ height: '100%' }}>
      <CheckCircleFilled
        style={{
          color: 'rgba(82, 196, 26, 1)',
          fontSize: '52px',
          marginBottom: '20px',
        }}
      />
      <Typography.Text
        style={{ fontSize: '18px', color: '#333', marginBottom: '8px' }}
      >{t`login.linkSent`}</Typography.Text>
      <Typography.Text
        style={{ fontSize: '13px', color: '#999', marginBottom: '8px' }}
      >{t`login.linkSentDescription`}</Typography.Text>
    </Flex>
  )
}
