import { Button, Flex, Form, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useMemo } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { FormViewMode } from '@components/FormViewMode'
import dayjs from 'dayjs'
import { ColumnsType } from 'antd/es/table'
import { ActiveStudentViewDto } from '@/openapi-api/api'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { TableParams } from '@/utils/types'

const PAGE_SIZE = 7

const doQuery = ({ filters }: TableParams, defaultQuery: string) => {
  const query = [defaultQuery]
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  return query.join(';') || undefined
}

const PaymentView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)
  const { activeStudentsViewControllerApi, paymentsAdminControllerApi } =
    useApi()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const searchColumn = useSearchColumn<ActiveStudentViewDto>()

  const { resource: payment } = useAsyncResource({
    fetchResource: useCallback(async () => {
      const { data } = await paymentsAdminControllerApi.getById(
        id as any as number,
      )
      return data
    }, [id, paymentsAdminControllerApi]),
  })

  const { resource: students } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!payment?.school?.id) {
        return { content: [] }
      }
      const { data } = await activeStudentsViewControllerApi.find(
        (tableParams.pagination?.current || 1) - 1,
        tableParams.pagination?.pageSize || PAGE_SIZE,
        doSort(tableParams),
        doQuery(tableParams, `schoolId==${payment?.school?.id}`),
      )
      return data
    }, [payment?.school?.id, activeStudentsViewControllerApi, tableParams]),
  })

  useEffect(() => {
    setBreadcrumbs([
      {
        name: t`payments.menPayments.summary`,
        href: '',
      },
    ])
  }, [payment, setBreadcrumbs, t])

  const columns: ColumnsType<ActiveStudentViewDto> = useMemo(
    () => [
      {
        title: 'Number',
        render: (_, student, index) => index + 1,
      },
      {
        title: t`payments.menPayments.studentName`,
        dataIndex: 'name',
        sorter: true,
        ...searchColumn('name'),
      },
      {
        title: t`payments.menPayments.firstSession`,
        dataIndex: 'firstSessionDate',
        sorter: true,
        render: (_, entity) =>
          dayjs(entity?.firstSessionDate).format('DD MMM, YYYY'),
      },
      {
        title: t`payments.menPayments.lastSession`,
        dataIndex: 'lastSessionDate',
        sorter: true,
        render: (_, entity) =>
          dayjs(entity?.lastSessionDate).format('DD MMM, YYYY'),
      },
      {
        title: t`payments.menPayments.sessionsCount`,
        dataIndex: 'sessionsCount',
      },
    ],
    [searchColumn, t],
  )

  return (
    <Flex vertical>
      <Flex justify="space-between">
        <Space size="large" align="center" style={{ marginBottom: '24px' }}>
          <Button
            icon={<ArrowLeftOutlined />}
            iconPosition="start"
            onClick={() => navigate(routePaths.payments.all)}
          >
            {t`back`}
          </Button>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t`payments.menPayments.viewPageTitle`}
          </Typography.Title>
        </Space>
        <Button type="primary" onClick={() => {}}>
          {t`payments.menPayments.generateInvoice`}
        </Button>
      </Flex>

      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 836,
          width: '100%',
        }}
        labelAlign="left"
      >
        <FormViewMode label={t`school.name`}>
          {payment?.school?.name}
        </FormViewMode>
        <FormViewMode label={t`payments.menPayments.invoiceDate`}>
          {dayjs(payment?.createdDate).format('DD MMM, YYYY')}
        </FormViewMode>
        <FormViewMode label={t`payments.menPayments.paymentDue`}>
          {dayjs(payment?.dueDate).format('DD MMM, YYYY')}
        </FormViewMode>
        <FormViewMode label={t`payments.menPayments.amount`}>
          {payment?.amount + ' ' + t`currency`}
        </FormViewMode>
        <FormViewMode label={t`payments.menPayments.vat`}>
          <span></span>
        </FormViewMode>
        <FormViewMode label={t`payments.menPayments.details`}>
          <span></span>
        </FormViewMode>
      </Form>

      <Typography.Title
        level={5}
      >{t`payments.menPayments.studentsList`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={students?.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: students?.totalElements,
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default PaymentView
