export const Separator = () => (
  <div
    style={{
      width: 'calc(100% + 48px)',
      backgroundColor: '#f5f5f5',
      height: '8px',
      margin: '24px -24px',
    }}
  />
)
