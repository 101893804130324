import { TeacherDtoGenderEnum } from '@/openapi-api/api'

export const tagsColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
]

export const genderMapping: { [key in TeacherDtoGenderEnum]: string } = {
  [TeacherDtoGenderEnum.MALE]: 'teacher.genderMan',
  [TeacherDtoGenderEnum.FEMALE]: 'teacher.genderWoman',
  [TeacherDtoGenderEnum.OTHER]: 'teacher.genderOther',
  [TeacherDtoGenderEnum.UNKNOWN]: 'teacher.genderOther',
}

export const days = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const NO_DIGITS = /^\D+$/
