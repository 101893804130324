import { FC, PropsWithChildren, useMemo } from 'react'
import { Form, Image } from 'antd'
import { ExternalLinkWrapper } from '@components/ExternalLinkWrapper'

export const FormViewMode: FC<
  PropsWithChildren<{
    label: string
    type?: 'link' | 'email' | 'phone' | 'image'
  }>
> = ({ label, children, type }) => {
  const content = useMemo(() => {
    switch (type) {
      case 'email':
        return (
          <ExternalLinkWrapper title={children} url={'mailto:' + children} />
        )
      case 'phone':
        return <ExternalLinkWrapper title={children} url={'tel:' + children} />
      case 'link':
        return <ExternalLinkWrapper title={children} url={children as string} />
      case 'image':
        return <Image src={children as string} width="100%" />
      default:
        return children
    }
  }, [children, type])

  return children ? (
    <Form.Item style={{ marginBottom: '16px' }} label={label}>
      {content}
    </Form.Item>
  ) : null
}
