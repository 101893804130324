import {
  Button,
  Flex,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import { CreateDocumentTypeRequest } from '@/openapi-api/api'
import { EnrollmentTabs } from './EnrollmentTabs'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { useGetSchools } from '@/utils/hooks/entities'
import { FormDocument } from '@components/Form/FormDocument'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { getValue } from '@/utils/functions/dataUtils'

const DocEdit = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { documentTypesAdminControllerApi } = useApi()
  const { appLang } = useLanguageContext()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const schools = useGetSchools()
  const { getLocaleField } = useLocaleHelpers()

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      documentTypesAdminControllerApi
        .getByIdExtended(+(id || 0))
        .then(({ data }) => {
          setFormData({
            name: getLocaleField('namePL', data),
            description: getLocaleField('descriptionPL', data),
            required: data.required,
            schoolId: data.school?.id?.toString(),
            document: data.mediaFiles?.[0]
              ? [
                  {
                    uid: data.mediaFiles[0].id,
                    status: 'done',
                    url: data.mediaFiles[0].link,
                    name: data.mediaFiles[0].fileName,
                  },
                ]
              : null,
          })
          setReady(true)
        })
    }
  }, [documentTypesAdminControllerApi, getLocaleField, id])

  const title = useMemo(
    () => formData.name || t`document.new`,
    [formData.name, t],
  )

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateDocumentTypeRequest = {
        [`name${appLang}`]: values.name,
        [`description${appLang}`]: values.description,
        schoolId: getValue(values.schoolId, true) as number,
        required: values.required,
      }
      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await documentTypesAdminControllerApi.create({
          ...dataToSend,
        })
        entityId = data.id as number
      } else {
        await documentTypesAdminControllerApi.update(id as any, dataToSend)
      }
      if (values.document?.length && !values.document[0].url) {
        const file = await getBase64ToUpload(values.document[0], true)

        if (id === 'new' || !formData.document?.length) {
          await documentTypesAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.document[0].name,
          })
        } else {
          await documentTypesAdminControllerApi.updateFile(
            entityId,
            formData.document?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.document[0].name,
            },
          )
        }
      }
      if (!values.document?.length && formData.document?.length) {
        await documentTypesAdminControllerApi.deleteMediaFile(
          entityId,
          formData.document[0].uid as number,
        )
      }
      if (id === 'new') {
        navigate(routePaths.enrollment.docs.all)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <EnrollmentTabs active={routePaths.enrollment.docs.all}>
      <Flex vertical>
        <Space size="large" align="center" style={{ marginBottom: '24px' }}>
          <Button
            icon={<ArrowLeftOutlined />}
            iconPosition="start"
            onClick={() => navigate(routePaths.enrollment.docs.all)}
          >
            {t`back`}
          </Button>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Space>

        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{
            maxWidth: 600,
            width: '100%',
            margin: '0 auto',
          }}
          disabled={submitting}
          form={form}
          initialValues={{ required: false, ...formData }}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
        >
          <FormInput
            label={t`Nazwa`}
            name="name"
            rules={[{ required: true, message: t`required` }]}
          />
          <Form.Item name="description" label={t`group.description`}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="schoolId"
            label={t`school.school`}
            rules={[
              {
                required: true,
                message: t`required`,
              },
            ]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(schools)}
              disabled={id !== 'new'}
            />
          </Form.Item>
          <FormDocument form={form} label={t`document.viewFile`} />
          <Form.Item label={t`settings`} className="switch-with-label">
            <Form.Item name="required" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Typography.Text
              style={{ marginLeft: '8px' }}
            >{t`document.docRequired`}</Typography.Text>
          </Form.Item>
          <Space size="large" style={{ justifyContent: 'center' }}>
            <Button type="text" onClick={formReset}>
              {t`reset`}
            </Button>
            <Button type="primary" htmlType="submit">
              {t`save`}
            </Button>
          </Space>
        </Form>
      </Flex>
    </EnrollmentTabs>
  )
}

export default DocEdit
