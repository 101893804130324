import { useMemo, useState } from 'react'
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Flex,
  Layout,
  Menu,
  MenuProps,
  Space,
  theme,
} from 'antd'
const { Content, Sider, Header, Footer } = Layout
import { useLanguageContext } from '@/contexts/LanguageProvider'
import {
  ArrowRightOutlined,
  UsergroupAddOutlined,
  OrderedListOutlined,
  ArrowLeftOutlined,
  MenuOutlined,
  CloseOutlined,
  HeartOutlined,
  UserOutlined,
  CompassOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  TeamOutlined,
  CarryOutOutlined,
  KeyOutlined,
  MailOutlined,
  FolderOutlined,
  ApartmentOutlined,
  ContainerOutlined,
  CreditCardOutlined,
} from '@ant-design/icons'
import React from 'react'
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useAuthContext } from '@/contexts/AuthContext'
import { routePaths } from '@/routes'
import { useTranslation } from 'react-i18next'
import { MenuDropdown } from '@components/MenuDropdown'
import useIsDevice from '@/utils/hooks/useIsMobile'
import { Breadcrumbs } from '@components/Breadcrumbs'
import styles from './MailLayout.module.less'
import { addFilterToUrl } from '@/utils/functions/filterUtils'
import { SCHEDULE_TYPES } from '@pages/schedule/types'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { TourPopover } from '@components/Layout/TourPopover'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

export const MainLayout = () => {
  const { appLang } = useLanguageContext()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { isAdmin, logout, avatar, isUser, user, isOrganiser } =
    useAuthContext()
  const { isTablet, isMobile, isBothMobile } = useIsDevice()
  const navigate = useNavigate()
  const { showTour } = useGlobalContext()
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const [collapsed, setCollapsed] = useState(isMobile)

  const adminItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'profile',
        label: <Link to={routePaths.profile}>{t`profile.profile`}</Link>,
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: <div onClick={logout}>{t`logout`}</div>,
      },
    ],
    [logout, t],
  )

  const organizerItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'profile',
        label: (
          <Link to={routePaths.organizerProfile}>{t`profile.profile`}</Link>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: <div onClick={logout}>{t`logout`}</div>,
      },
    ],
    [logout, t],
  )

  const userItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'logout',
        label: <div onClick={logout}>{t`logout`}</div>,
      },
    ],
    [logout, t],
  )

  const menuItems: MenuItem[] = useMemo(() => {
    if (isAdmin)
      return [
        getItem(t`mySchools`, routePaths.schools.all, <HeartOutlined />),
        getItem(t`locations`, routePaths.locations.all, <CompassOutlined />),
        getItem(t`rooms`, routePaths.rooms.all, <KeyOutlined />),
        getItem(t`teachers`, routePaths.teachers.all, <ContactsOutlined />),
        getItem(t`groups`, routePaths.groups.all, <OrderedListOutlined />),
        getItem(
          t`schedule.title`,
          routePaths.schedule +
            addFilterToUrl({
              view: isBothMobile ? SCHEDULE_TYPES.DAY : SCHEDULE_TYPES.WEEK,
            }),
          <ScheduleOutlined />,
        ),
        getItem(
          t`attendance.attendance`,
          routePaths.attendance.all,
          <CarryOutOutlined />,
        ),
        getItem(
          t`notifications.notifications`,
          routePaths.notifications.sent,
          <MailOutlined />,
        ),
        getItem(
          t`enrollment.enrollment`,
          routePaths.enrollment.schools,
          <UsergroupAddOutlined />,
        ),
        // eslint-disable-next-line react/jsx-no-undef
        getItem(
          t`payments.payments`,
          t`payments.payments`,
          <CreditCardOutlined />,
          [getItem(t`payments.menPayments.tabTitle`, routePaths.payments.all)],
        ),
        getItem(
          t`tournaments.tournaments`,
          routePaths.admin.tournaments.all,
          <ApartmentOutlined />,
          [
            getItem(t`list`, routePaths.admin.tournaments.all),
            getItem(t`categories`, routePaths.admin.categories.all),
            getItem(t`students`, routePaths.admin.students.all),
          ],
        ),
      ]
    if (isUser)
      return [
        getItem(
          t`school.school`,
          routePaths.user.schools.all,
          <HeartOutlined />,
        ),
        getItem(t`students`, routePaths.user.students.all, <TeamOutlined />),
        getItem(
          t`mySchedule`,
          routePaths.user.mySchedule,
          <ContactsOutlined />,
        ),
        getItem(
          t`schedule.title`,
          routePaths.user.schedule,
          <ScheduleOutlined />,
        ),
        getItem(
          t`enrollment.enrollment`,
          routePaths.user.enrollment,
          <UsergroupAddOutlined />,
        ),
      ]
    if (isOrganiser)
      return [
        getItem(
          t`tournaments.tournaments`,
          routePaths.organiser.tournaments.all,
          <ApartmentOutlined />,
        ),
        getItem(
          t`tournaments.participants`,
          routePaths.organiser.participants.all,
          <UsergroupAddOutlined />,
        ),
        getItem(
          t`tournaments.categories`,
          routePaths.organiser.categories.all,
          <FolderOutlined />,
        ),
        getItem(
          t`tournaments.reports`,
          routePaths.organiser.reports.all,
          <ContainerOutlined />,
        ),
      ]
    return []
  }, [isAdmin, isBothMobile, isOrganiser, isUser, t])

  const menu = useMemo(
    () => (
      <Menu
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        items={menuItems}
        onClick={({ key }) => navigate(key)}
        defaultSelectedKeys={[pathname]}
      />
    ),
    [menuItems, navigate, pathname],
  )

  const bgColor = useMemo(() => {
    if (
      matchPath(routePaths.notifications.sent, pathname) ||
      matchPath(routePaths.attendance.specific(':id'), pathname) ||
      matchPath(routePaths.notifications.received, pathname) ||
      matchPath(routePaths.notifications.item(':id'), pathname) ||
      matchPath(routePaths.user.students.all, pathname) ||
      matchPath(routePaths.enrollment.schools, pathname) ||
      matchPath(routePaths.enrollment.docs.all, pathname) ||
      matchPath(routePaths.enrollment.groups, pathname) ||
      matchPath(routePaths.user.enrollment, pathname) ||
      matchPath(
        routePaths.enrollment.student(':schoolId', ':studentId'),
        pathname,
      ) ||
      matchPath(routePaths.enrollment.docs.specific(':id').edit, pathname)
    )
      return '#F6F6F9'
    return colorBgContainer
  }, [colorBgContainer, pathname])

  const padding = useMemo(() => {
    if (
      matchPath(routePaths.enrollment.schools, pathname) ||
      matchPath(routePaths.attendance.specific(':id'), pathname) ||
      matchPath(routePaths.enrollment.groups, pathname) ||
      matchPath(routePaths.enrollment.docs.all, pathname) ||
      matchPath(routePaths.notifications.sent, pathname) ||
      matchPath(routePaths.notifications.item(':id'), pathname) ||
      matchPath(routePaths.notifications.received, pathname) ||
      matchPath(
        routePaths.enrollment.student(':schoolId', ':studentId'),
        pathname,
      ) ||
      matchPath(routePaths.enrollment.docs.specific(':id').edit, pathname)
    )
      return '0px'
    return undefined
  }, [pathname])

  return (
    <Layout key={appLang} style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 20px',
          justifyContent: 'space-between',
          background: 'linear-gradient(90deg, #1D014B 0%, #0139C4 100%)',
        }}
      >
        {isMobile && (
          <Flex
            onClick={() => setCollapsed((old) => !old)}
            style={{ color: 'white', fontSize: '24px' }}
          >
            {collapsed ? <MenuOutlined /> : <CloseOutlined />}
          </Flex>
        )}
        {!isMobile && <img alt="Logo" src="/logo.png" width={98} />}
        {isAdmin && (
          <Space style={{ flex: 0 }} size="middle">
            {showTour && <TourPopover />}
            <MenuDropdown title={'Admin'} items={adminItems} />
            <Avatar
              src={avatar?.thumbLink || <UserOutlined />}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
            />
          </Space>
        )}
        {isOrganiser && (
          <Space style={{ flex: 0, whiteSpace: 'nowrap' }} size="middle">
            <MenuDropdown title={user.name} items={organizerItems} />
            <Avatar
              src={avatar?.thumbLink || <UserOutlined />}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
            />
          </Space>
        )}
        {isUser && (
          <Space style={{ flex: 0, whiteSpace: 'nowrap' }} size="middle">
            <MenuDropdown
              title={`${user.firstName} ${user.lastName}`}
              items={userItems}
            />
            <Avatar
              src={avatar?.thumbLink || <UserOutlined />}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
            />
          </Space>
        )}
      </Header>
      <Layout style={{ paddingBottom: 0 }}>
        {!isMobile && (
          <Sider
            width={205}
            style={{
              background: colorBgContainer,
            }}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            {isTablet && (
              <>
                <Flex
                  vertical
                  style={{ height: '40px', marginTop: '4px' }}
                  justify="center"
                  align={collapsed ? 'center' : 'end'}
                >
                  <Button
                    type="text"
                    onClick={() => setCollapsed((old) => !old)}
                  >
                    {collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                  </Button>
                </Flex>
                <Divider style={{ margin: '4px 0' }} />
              </>
            )}
            {menu}
          </Sider>
        )}
        <Layout className={styles.layout}>
          {isMobile && (
            <Drawer
              placement="left"
              width={240}
              onClose={() => setCollapsed(true)}
              open={!collapsed}
              getContainer={false}
              styles={{
                header: { display: 'none' },
                body: { padding: '4px 0' },
              }}
            >
              <Flex style={{ padding: '16px 32px' }}>
                <img alt="Logo" src="/logoBlack.png" width={98} />
              </Flex>
              {menu}
            </Drawer>
          )}
          <Breadcrumbs />
          <Content
            style={{
              background: bgColor,
              padding,
            }}
            className={styles.content}
          >
            <Outlet />
          </Content>
          <Footer className={styles.footer}>
            <Flex justify="space-between">{'Menagero © 2024'}</Flex>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}
