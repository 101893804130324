import { Form, FormInstance, UploadFile } from 'antd'
import { normFile } from '@pages/profile'
import { getBase64ToUpload, Uploader } from '@components/Uploader'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  name?: string
  form: FormInstance
  fullWidth?: boolean
  required?: boolean
}

export const FormAvatar: FC<Props> = ({
  label,
  form,
  name = 'avatar',
  fullWidth = true,
  required = false,
}) => {
  const avatar = Form.useWatch(name, form)
  const { t } = useTranslation()
  return (
    <Form.Item
      label={label}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      name={name}
      style={{ minHeight: '102px' }}
      className={fullWidth ? 'fullWidthUploader' : ''}
      rules={[...(required ? [{ required: true, message: t`required` }] : [])]}
    >
      <Uploader
        maxCount={1}
        length={avatar?.length}
        previewFile={(file) => getBase64ToUpload(file as unknown as UploadFile)}
      />
    </Form.Item>
  )
}
