export const routePaths = {
  app: '/app',
  login: '/login',
  verification: '/user/activation',
  resetPassword: '/user/password-reset',
  dashboard: '/app/dashboard',
  schedule: '/app/schedule',
  scheduleAdd: '/app/schedule/add',
  scheduleEdit: (id: string | number = '') => `/app/schedule/edit/${id}`,
  profile: '/app/profile',
  organizerProfile: '/app/organizerProfile',
  schoolInvite: (id: string | number = '') => `/app/invite/school/${id}`,
  tournamentInvite: (id: string | number = '') =>
    `/app/invite/tournament/${id}`,
  enrollment: {
    groups: '/app/enrollment/groups',
    schools: '/app/enrollment/schools',
    student: (
      schoolId: string | number = '',
      studentId: string | number = '',
    ) => `/app/enrollment/schools/${schoolId}/${studentId}`,
    docs: {
      all: '/app/enrollment/docs',
      specific: (id: string | number = '') => ({
        edit: `/app/enrollment/docs/${id}/edit`,
      }),
    },
  },
  locations: {
    all: '/app/locations',
    specific: (id: string | number = '') => ({
      edit: `/app/locations/${id}/edit`,
      view: `/app/locations/${id}/view`,
      rooms: {
        all: `/app/locations/${id}/rooms`,
        specific: (roomId: string | number = '') => ({
          edit: `/app/locations/${id}/edit/${roomId}`,
        }),
      },
    }),
  },
  rooms: {
    all: '/app/rooms',
    specific: (id: string | number = '') => ({
      edit: `/app/rooms/${id}/edit`,
    }),
  },
  groups: {
    all: '/app/groups',
    specific: (id: string | number = '') => ({
      edit: `/app/groups/${id}/edit`,
      view: `/app/groups/${id}/view`,
      schedule: `/app/groups/${id}/schedule`,
    }),
  },
  schools: {
    all: '/app/schools',
    specific: (id: string | number = '') => ({
      edit: `/app/schools/${id}/edit`,
      view: `/app/schools/${id}/view`,
    }),
  },
  attendance: {
    all: '/app/attendance',
    specific: (id: string | number = '') => `/app/attendance/${id}`,
  },
  teachers: {
    all: '/app/teachers',
    specific: (id: string | number = '') => ({
      edit: `/app/teachers/${id}/edit`,
      view: `/app/teachers/${id}/view`,
    }),
  },
  notifications: {
    sent: '/app/notifications/sent',
    received: '/app/notifications/received',
    item: (id: string | number = '') =>
      `/app/notifications/received/item/${id}`,
  },
  payments: {
    all: '/app/payments',
    specific: (id: string | number = '') => ({
      view: `/app/payments/${id}/view`,
    }),
  },
  user: {
    schools: {
      all: '/app/user/schools',
    },
    mySchedule: '/app/user/my-schedule',
    enrollment: '/app/user/enrollment',
    schedule: '/app/user/schedule',
    students: {
      all: '/app/user/students',
      specific: (id: string | number = '') => ({
        edit: `/app/user/students/${id}/edit`,
        view: `/app/user/students/${id}/view`,
      }),
    },
  },
  organiser: {
    tournaments: {
      all: '/app/organiser/tournaments/all',
      specific: (id: string | number = '') => ({
        edit: `/app/organiser/tournaments/${id}/edit`,
      }),
    },
    participants: {
      all: '/app/organiser/participants/all',
    },
    categories: {
      all: '/app/organiser/categories/all',
      createBulk: '/app/organiser/categories/createBulk',
      specific: (id: string | number = '') => ({
        edit: `/app/organiser/categories/${id}/edit`,
      }),
    },
    reports: {
      all: '/app/organiser/reports/all',
    },
  },
  admin: {
    tournaments: {
      all: '/app/admin/tournaments/all',
    },
    categories: {
      all: '/app/admin/categories/all',
    },
    students: {
      all: '/app/admin/students/all',
    },
  },
}
