import {
  Button,
  Flex,
  Form,
  Select,
  Space,
  Steps,
  Table,
  Typography,
} from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useIsMobile from '@/utils/hooks/useIsMobile'
import {
  useGetProfiles,
  useGetSpecializations,
  useGetTournaments,
} from '@/utils/hooks/entities'
import {
  optionsFromEntity,
  optionsFromEntityNoId,
} from '@/utils/functions/optionsFromEntity'
import { PlusOutlined } from '@ant-design/icons'
import { CategoryPanel } from '@pages/organiser/tournamentCategories/createBulk/CategoryPanel'
import CheckableTagFormField from '@/components/Form/FormCheckableTags'
import {
  generateCategoriesColumns,
  teamSizeName,
} from '@pages/organiser/constants'
import StringArrayInput from '@components/Form/StringArrayInput'
import {
  CreateTeacherRequestGenderEnum,
  CreateTournamentCategoryRequest,
} from '@/openapi-api/api'
import {
  generateCombinations,
  GeneratedCombination,
} from '@pages/organiser/generateCombinations'
import useApi from '@/contexts/api'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import { addFilterToUrl } from '@/utils/functions/filterUtils'

const { Step } = Steps

const CreateTournamentCategoryBulk = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [tableValues, setTableValues] = useState<GeneratedCombination[]>([])
  const [form] = Form.useForm()
  const { isBothMobile } = useIsMobile()
  const { tournamentCategoriesAdminControllerApi } = useApi()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const profiles = useGetProfiles()
  const tournaments = useGetTournaments()
  const profileId = Form.useWatch('profileId', form)
  const specializations = useGetSpecializations(profileId)
  const columns = useMemo(
    () =>
      generateCategoriesColumns(t, profiles, (index) =>
        setTableValues((values) => values.filter((_, i) => i !== index)),
      ),
    [profiles, t],
  )
  const { appLang } = useLanguageContext()

  const steps = [
    {
      title: t`tournaments.bulkFTitle`,
      description: t`tournaments.bulkFDescription`,
      content: (
        <>
          <Form.Item
            name="tournamentId"
            label={t`tournaments.tournament`}
            rules={[
              {
                required: true,
                message: t`required`,
              },
            ]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(tournaments)}
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="profileId"
            label={t`profile.profile`}
            rules={[
              {
                required: true,
                message: t`school.error.noProfile`,
                type: 'array',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(profiles)}
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="specialization"
            label={t`group.specialization`}
            rules={[
              {
                required: true,
                message: t`school.error.noProfile`,
                type: 'array',
              },
            ]}
          >
            <Select
              mode="tags"
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntityNoId(specializations)}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: t`tournaments.bulkSTitle`,
      description: t`tournaments.bulkSDescription`,
      content: (
        <Flex vertical gap={24}>
          <Flex vertical>
            <Space size="large">
              <Typography.Title
                style={{ margin: 0 }}
                level={5}
              >{t`tournaments.bulkSTitle`}</Typography.Title>
              <Typography.Text type="secondary">{`(${t`optional`})`}</Typography.Text>
            </Space>
            <Typography.Text>{t`tournaments.bulkAddCategoryDescription`}</Typography.Text>
          </Flex>

          <Form.List name="ageCategories">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <CategoryPanel
                    key={field.key}
                    index={index}
                    field={field}
                    remove={remove}
                    form={form}
                  />
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t`tournaments.bulkAddCategory`}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Flex>
      ),
    },
    {
      title: t`tournaments.bulkTTitle`,
      content: (
        <Flex vertical>
          <CheckableTagFormField
            name="formation"
            tags={Object.entries(teamSizeName).map(([key, value]) => ({
              ...value,
              teamSizeName: t(value.teamSizeName),
            }))}
            form={form}
            label={t`tournaments.bulkTeamSize`}
            required
          />
          <StringArrayInput
            name="level"
            form={form}
            required
            label={t`group.level`}
          />
          <Form.Item name="gender" label={t`teacher.gender`}>
            <Select
              mode="tags"
              placeholder={t`school.pleaseSelect`}
              options={[
                {
                  value: CreateTeacherRequestGenderEnum.FEMALE,
                  label: t`teacher.genderWoman`,
                },
                {
                  value: CreateTeacherRequestGenderEnum.MALE,
                  label: t`teacher.genderMan`,
                },
                {
                  value: CreateTeacherRequestGenderEnum.OTHER,
                  label: t`teacher.genderOther`,
                },
              ]}
            />
          </Form.Item>
        </Flex>
      ),
    },
    {
      title: t`result`,
      content: (
        <Flex vertical>
          <Table
            dataSource={tableValues}
            columns={columns}
            pagination={false}
          />
        </Flex>
      ),
    },
  ]

  const next = async () => {
    const values = form.getFieldsValue(true)
    console.log('Полученные данные:', values)

    try {
      await form.validateFields()
      setCurrentStep(currentStep + 1)
    } catch (errorInfo) {
      console.log('Ошибка валидации:', errorInfo)
    }
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleFinish = () => {
    const values = form.getFieldsValue(true)
    setTableValues(generateCombinations(values))
    setCurrentStep(currentStep + 1)
  }

  const saveCategories = useCallback(async () => {
    const tournament = tournaments.find(
      (tournament) => tournament.id === +tableValues[0].tournamentId,
    )
    const data: CreateTournamentCategoryRequest[] = tableValues.map((value) => {
      const name = `${profiles.find(
        (profile) => profile.id === +value.profileId,
      )?.name} ${value.specialization} ${value.teamAgeName || ''} ${
        value.teamSizeName
      } ${
        value.gender === 'male' || value.gender === 'female' ? value.gender : ''
      }`
      return {
        [`name${appLang}`]: name,
        tournamentId: value.tournamentId,
        profileId: value.profileId,
        specialization: value.specialization,
        level: value.level,
        gender: value.gender || undefined,
        [`teamSizeName${appLang}`]: value.teamSizeName,
        teamMinSize: value.teamMinSize,
        teamMaxSize: value.teamMaxSize,
        [`teamAgeName${appLang}`]: value.teamAgeName,
        teamMinAge: value.minAge,
        teamMaxAge: value.maxAge,
        teamMinAgeShift: value.teamMinAgeShift,
        teamMaxAgeShift: value.teamMaxAgeShift,
        teamMaxAgeRatio: value.teamMaxAgeRatio,
        teamMinAgeRatio: value.teamMinAgeRatio,
        teamAgeDifference: value.teamAgeDifference,
        ageCalculationMode: value.ageCalculationMode,
      } as any
    })
    await tournamentCategoriesAdminControllerApi.bulkCreate(data)
    navigate(
      routePaths.organiser.categories.all +
        addFilterToUrl({
          filters: {
            tournament: [tournament?.name],
          },
        }),
    )
  }, [
    appLang,
    navigate,
    profiles,
    tableValues,
    tournamentCategoriesAdminControllerApi,
    tournaments,
  ])

  return (
    <div>
      <Steps
        current={currentStep}
        direction={isBothMobile ? 'vertical' : 'horizontal'}
      >
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={
              <span style={{ whiteSpace: 'nowrap' }}>{step.description}</span>
            }
          />
        ))}
      </Steps>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          ageCategories: [{ minAge: '', maxAge: '', teamAgeName: '' }],
        }}
        style={{ maxWidth: currentStep < steps.length - 1 ? '850px' : '100%' }}
      >
        {currentStep === steps.length - 1 && (
          <Flex justify="flex-end">
            <Button
              style={{ marginLeft: 'auto', marginTop: 24 }}
              type="primary"
              onClick={saveCategories}
            >
              {t`save`}
            </Button>
          </Flex>
        )}
        <div style={{ marginTop: 24 }}>{steps[currentStep].content}</div>
        <Flex gap={16} justify="flex-end" style={{ marginTop: 24 }}>
          {currentStep > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prev}>
              {t`back`}
            </Button>
          )}
          {currentStep < steps.length - 2 && (
            <Button type="primary" onClick={next}>
              {t`next`}
            </Button>
          )}
          {currentStep === steps.length - 2 && (
            <Button type="primary" htmlType="submit">
              {t`next`}
            </Button>
          )}
        </Flex>
      </Form>
    </div>
  )
}

export default CreateTournamentCategoryBulk
