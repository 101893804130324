import { useState } from 'react'

import { LoginView } from '@pages/login/LoginView'
import { ForgotPasswordView } from '@pages/login/ForgotPasswordView'
import { RegistrationView } from '@pages/login/RegistrationView'
import { Layout } from '@pages/login/Layout'

const Login = () => {
  const [step, setStep] = useState('login')

  return (
    <Layout>
      {step === 'login' && <LoginView changeLayout={setStep} />}
      {step === 'forgot' && <ForgotPasswordView changeLayout={setStep} />}
      {step === 'registration' && <RegistrationView changeLayout={setStep} />}
    </Layout>
  )
}

export default Login
