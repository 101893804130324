export function hasTrueField<T extends object>(
  obj: T | undefined,
  field: string,
) {
  if (typeof obj === 'undefined' || !Object.hasOwnProperty.call(obj, field)) {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(obj as any)[field]
}
