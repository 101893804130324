import { Badge, Button, Flex, Space, Table, Typography } from 'antd'
import { routePaths } from '@/routes'
import { NotificationTabs } from './NotificationTabs'
import useApi from '@/contexts/api'
import { useTranslation } from 'react-i18next'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { SentNotificationViewDto } from '@/openapi-api/api'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { useNavigate, useParams } from 'react-router-dom'
import { TableParams } from '@/utils/types'
import { ArrowLeftOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const doQuery = ({ filters }: TableParams, id?: string) => {
  const query = []
  if (filters?.receiverName?.length) {
    query.push(`receiverName~~${filters.receiverName[0]}`)
  }
  query.push(`notificationListId==${id}`)
  return query.join(';') || undefined
}

const PAGE_SIZE = 7

export const SentNotificationsItem = () => {
  const { sentNotificationsViewControllerApi, notificationListsControllerApi } =
    useApi()
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const searchColumn = useSearchColumn<SentNotificationViewDto>()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: notifications } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        sentNotificationsViewControllerApi.find(
          (tableParams.pagination?.current ?? 1) - 1,
          tableParams.pagination?.pageSize ?? PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams, id),
        ),
      [id, sentNotificationsViewControllerApi, tableParams],
    ),
  })

  const { resource: list } = useAsyncResource({
    fetchResource: useCallback(async () => {
      const { data } = await notificationListsControllerApi.find(
        undefined,
        undefined,
        undefined,
        `id==${id}`,
      )

      return data.content?.[0]
    }, [id, notificationListsControllerApi]),
  })

  const columns: ColumnsType<SentNotificationViewDto> = useMemo(
    () => [
      {
        title: t`notifications.itemFio`,
        dataIndex: 'receiverName',
        sorter: true,
        filteredValue: tableParams.filters?.receiverName || null,
        ...searchColumn('receiverName'),
      },
      {
        title: t`profile.email`,
        dataIndex: 'receiverEmail',
        sorter: true,
      },
      {
        title: t`invite.status`,
        dataIndex: 'status',
        sorter: true,
        render: (item) =>
          item === 'sent' ? (
            <Badge status="success" text={t`notifications.itemStatusSuccess`} />
          ) : (
            <Badge status="error" text={t`notifications.itemStatusError`} />
          ),
      },
      {
        title: t`notifications.itemIsRead`,
        dataIndex: 'isRead',
        sorter: true,
        render: (_, entity) => {
          if (entity.status !== 'sent') return ''
          return entity.isRead ? t`yes` : t`no`
        },
      },
    ],
    [searchColumn, t, tableParams.filters],
  )

  return (
    <NotificationTabs active={routePaths.notifications.sent}>
      <Space style={{ marginBottom: '24px', paddingTop: '8px' }} size="large">
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.notifications.sent)}
        >
          {t`back`}
        </Button>
        <Typography.Title level={5} style={{ margin: 0 }}>
          {list?.subject}
        </Typography.Title>
      </Space>
      <Flex
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          rowGap: '16px',
          columnGap: '24px',
          marginBottom: '24px',
        }}
      >
        <Typography.Text strong>{t`notifications.itemTime`}</Typography.Text>
        <Typography>
          {dayjs(list?.createdDate).format('hh:mm DD.MM.YY')}
        </Typography>
        {list?.text && (
          <>
            <Typography.Text
              strong
            >{t`notifications.itemText`}</Typography.Text>
            <Typography>{list.text}</Typography>
          </>
        )}
        <Typography.Text
          strong
        >{t`notifications.itemReceipient`}</Typography.Text>
        <Typography>{`${list?.typeName} (${list?.typeDetails})`}</Typography>
        <Typography.Text strong>{`${t`invite.status`}:`}</Typography.Text>
        <Typography>{`${list?.receivedCount}/${list?.recipientsCount} ${t`notifications.itemWasSent`}`}</Typography>{' '}
        <Typography.Text
          strong
        >{`${t`notifications.itemIsRead`}:`}</Typography.Text>
        <Typography>{list?.readCount}</Typography>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={notifications?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: notifications?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </NotificationTabs>
  )
}
