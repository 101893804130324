import SingleSchedule from '@pages/groups/schedule/SingleSchedule'
import useApi from '@/contexts/api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { getColor } from '@pages/groups/GroupsList'

export const ScheduleEdit = () => {
  const [formData, setFormData] = useState<any>()
  const { id } = useParams()
  const { sessionsAdminControllerApi } = useApi()
  const { getLocaleField } = useLocaleHelpers()

  useEffect(() => {
    if (id) {
      sessionsAdminControllerApi.getByIdExtended(+id).then(({ data }) => {
        setFormData({
          generalRange: dayjs(data.startTime),
          timeRange: [
            dayjs(data.startTime),
            dayjs(data.startTime).add(data.duration as number, 'minutes'),
          ],
          name:
            getLocaleField('namePL', data) ||
            getLocaleField('namePL', data?.studentGroup!),
          description:
            getLocaleField('descriptionPL', data) ||
            getLocaleField('descriptionPL', data?.studentGroup!),
          id: data.id,
          profileId: String(data.profile?.id || data.studentGroup?.profile?.id),
          specialization: (
            data.specialization || data.studentGroup?.specialization
          )?.split(', '),
          color: getColor(data),
          locationId: String(data.room?.place?.id),
          roomId: String(data.room?.id),
          teacherId: String(data.teacher?.id),
          capacity: data.capacity || data.studentGroup?.capacity,
          groupId: data.studentGroup?.id,
          original: data,
          avatar: data.mediaFiles?.[0]
            ? [
                {
                  uid: data.mediaFiles[0].id,
                  status: 'done',
                  url: data.mediaFiles[0].link,
                },
              ]
            : null,
        })
      })
    }
  }, [getLocaleField, id, sessionsAdminControllerApi])

  if (!formData) return null

  return <SingleSchedule defaultValues={formData} />
}
