import { Button, Flex, Form, FormProps, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import { CreateRoomRequest } from '@/openapi-api/api'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { FormAvatar } from '@components/Form/FormAvatar'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'

const RoomEdit = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { appLang } = useLanguageContext()
  const { roomsAdminControllerApi, placesAdminControllerApi } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs, refreshTour } = useGlobalContext()
  const { id, locationId } = useParams()

  const { getLocaleField } = useLocaleHelpers()

  const { resource: location } = useAsyncResource({
    fetchResource: useCallback(
      () => placesAdminControllerApi.getById(locationId as any as number),
      [locationId, placesAdminControllerApi],
    ),
  })

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      roomsAdminControllerApi.getByIdExtended(+(id || 0)).then(({ data }) => {
        setFormData({
          name: getLocaleField('namePL', data),
          avatar: data.mediaFiles?.[0]
            ? [
                {
                  uid: data.mediaFiles[0].id,
                  status: 'done',
                  url: data.mediaFiles[0].link,
                },
              ]
            : null,
        })
        setReady(true)
      })
    }
  }, [getLocaleField, id, roomsAdminControllerApi])

  const title = useMemo(() => formData.name || t`room.new`, [formData.name, t])

  useEffect(() => {
    setBreadcrumbs([
      {
        name: location?.data.name as string,
        href: routePaths.locations.specific(locationId).rooms.all,
      },
      {
        name: title,
        href: '',
      },
    ])
  }, [location?.data.name, locationId, setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateRoomRequest = {
        [`name${appLang}`]: values.name,
        placeId: +(locationId as string),
      }

      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await roomsAdminControllerApi.create({
          ...dataToSend,
        })
        entityId = data.id as number
      } else {
        await roomsAdminControllerApi.update(id as any, dataToSend)
      }
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (id === 'new' || !formData.avatar?.length) {
          await roomsAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await roomsAdminControllerApi.updateFile(
            entityId,
            formData.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && formData.avatar?.length) {
        await roomsAdminControllerApi.deleteMediaFile(
          entityId,
          formData.avatar[0].uid as number,
        )
      }
      if (id === 'new') {
        navigate(routePaths.locations.specific(locationId).rooms.all)
      }
    } finally {
      refreshTour()
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() =>
            navigate(routePaths.locations.specific(locationId).rooms.all)
          }
        >
          {t`back`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Space>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <FormInput
          label={t`nazwa`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <FormAvatar label={t`location.image`} form={form} />
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default RoomEdit
