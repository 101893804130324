import { Loading } from '@components/Loading'
import { useAuthContext } from '@/contexts/AuthContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'

export const Dashboard = () => {
  const { loaded, isAdmin, isUser, isOrganiser } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!loaded) return
    if (isUser) navigate(routePaths.user.schools.all)
    if (isAdmin) navigate(routePaths.schools.all)
    if (isOrganiser) navigate(routePaths.organiser.tournaments.all)
  }, [isAdmin, isOrganiser, isUser, loaded, navigate])

  return <Loading />
}
