import { FC } from 'react'
import { Form, Input, Collapse, Flex, Typography, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { CreateTournamentCategoryRequestAgeCalculationModeEnum } from '@/openapi-api/api'

const { Panel } = Collapse

export const CategoryPanel: FC<{
  index: number
  field: any
  remove: (index: number) => void
  form: any
}> = ({ index, field, form }) => {
  const {
    minAge,
    maxAge,
    teamAgeName = '',
  } = Form.useWatch(['ageCategories', index], form) || {}

  const required = minAge || maxAge

  const validateRequired = (_: any, value: any) => {
    if (minAge || maxAge) {
      if (!value) {
        return Promise.reject(new Error(t`required`))
      }
    }
    return Promise.resolve()
  }

  const { t } = useTranslation()

  return (
    <Collapse key={field.key} defaultActiveKey={[`panel-${field.key}`]}>
      <Panel
        key={`panel-${field.key}`}
        header={`${t`tournaments.bulkAgeCategory`} [${teamAgeName}]`}
      >
        <Form.Item
          {...field}
          name={[field.name, 'teamAgeName']}
          label={t`tournaments.bulkAgeCategoryName`}
          key={field.key + 'teamAgeName'}
        >
          <Input />
        </Form.Item>
        <Flex
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '16px',
          }}
        >
          <Form.Item
            {...field}
            key={field.key + 'minAge'}
            name={[field.name, 'minAge']}
            label={
              <Typography.Text
                strong
              >{t`tournaments.bulkAgeMinAge`}</Typography.Text>
            }
          >
            <Input style={{ maxWidth: '100px' }} />
          </Form.Item>

          <Form.Item
            {...field}
            key={field.key + 'maxAge'}
            name={[field.name, 'maxAge']}
            label={
              <Typography.Text
                strong
              >{t`tournaments.bulkAgeMaxAge`}</Typography.Text>
            }
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            {...field}
            key={field.key + 'teamMinAgeRatio'}
            name={[field.name, 'teamMinAgeRatio']}
            label={t`tournaments.bulkTeamMinAgeRatio`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} addonAfter="%" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, 'teamMaxAgeRatio']}
            label={t`tournaments.bulkTeamMaxAgeRatio`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} addonAfter="%" />
          </Form.Item>
          <Form.Item
            {...field}
            key={field.key + 'teamMinAgeShift'}
            name={[field.name, 'teamMinAgeShift']}
            label={t`tournaments.bulkTeamAgeShift`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            {...field}
            key={field.key + 'teamMaxAgeShift'}
            name={[field.name, 'teamMaxAgeShift']}
            label={t`tournaments.bulkTeamAgeShift`}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
          <Form.Item
            {...field}
            key={field.key + 'ageCalculationMode'}
            name={[field.name, 'ageCalculationMode']}
            label={t`tournaments.bulkAgeCalculationMode`}
            rules={[{ validator: validateRequired }]}
            required={required}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={[
                {
                  value:
                    CreateTournamentCategoryRequestAgeCalculationModeEnum.YEAR_OF_BIRTH,
                  label: t`tournaments.bulkAgeCalculationModeYear`,
                },
                {
                  value:
                    CreateTournamentCategoryRequestAgeCalculationModeEnum.EXACT_BIRTH_DAY,
                  label: t`tournaments.bulkAgeCalculationModeDay`,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, 'teamAgeDifference']}
            label={t`tournaments.bulkAgeDifference`}
            key={field.key + 'teamAgeDifference'}
          >
            <Input type="number" style={{ maxWidth: '100px' }} />
          </Form.Item>
        </Flex>
      </Panel>
    </Collapse>
  )
}
