import { Button, Flex, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { SessionDto } from '@/openapi-api/api'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Dates } from '@pages/schedule/types'
import { days, generateCalendar } from './helpers'
import { MonthCard } from '@pages/schedule/MonthCard'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  sessions: SessionDto[]
  dates: Dates
  setDates: (dates: Dates) => void
  refresh: () => Promise<void>
}

const generateNewMonth = (date: Dayjs, add: boolean) => {
  let newDate = date.add(10, 'days')
  newDate = add ? newDate.add(1, 'month') : newDate.subtract(1, 'month')
  const array = generateCalendar(newDate.month(), newDate.year())
  return [array[0], array.reverse()[0]] as Dates
}

const ScheduleMonthView: FC<Props> = ({
  sessions,
  dates,
  setDates,
  refresh,
}) => {
  const { t } = useTranslation()

  return (
    <Flex vertical>
      <Flex justify="center" style={{ marginBottom: '16px' }}>
        <Space align="center" style={{ justifyContent: 'center' }} size="large">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => setDates(generateNewMonth(dates[0], false))}
          />
          <Typography.Title
            level={4}
            style={{ margin: 0 }}
            className="firstLetter"
          >
            {dates[0].add(10, 'days').format('MMMM YYYY')}
          </Typography.Title>
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() => setDates(generateNewMonth(dates[0], true))}
          />
        </Space>
      </Flex>
      <Flex
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7 ,minmax(0, 1fr))',
          gap: '4px',
          rowGap: '8px',
        }}
      >
        {days.map((d) => (
          <Typography
            style={{
              marginBottom: '8px',
              textAlign: 'right',
              padding: '0 8px',
            }}
            key={d}
          >
            {t(`days.${d}`)}
          </Typography>
        ))}
        {generateCalendar(
          dates[0].add(10, 'days').month(),
          dates[0].add(10, 'days').year(),
        ).map((date) => (
          <MonthCard
            sessions={sessions.filter((s) =>
              date.isSame(dayjs(s.startTime), 'day'),
            )}
            date={date}
            key={date.toISOString()}
            wrap
            refresh={refresh}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default ScheduleMonthView
