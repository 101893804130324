import { Button, Flex, Table, Typography } from 'antd'
import { EnrollmentTabs } from '@pages/enrollments/EnrollmentTabs'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo, useState } from 'react'
import { TableParams } from '@/utils/types'
import { ColumnsType } from 'antd/es/table'
import {
  SchoolEnrollmentViewDtoStatusEnum,
  StudentDocumentViewDto,
  StudentDocumentViewDtoStatusEnum,
} from '@/openapi-api/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { useTranslation } from 'react-i18next'
import { ArrowLeftOutlined, PaperClipOutlined } from '@ant-design/icons'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'

const doQuery = (
  { filters }: TableParams,
  studentId?: string,
  schoolId?: string,
) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  query.push(`schoolId~in~${schoolId}`)
  query.push(`studentId~in~${studentId}`)

  return query.join(';') || undefined
}

const PAGE_SIZE = 7

export const SchoolStudentEnrollmentDocuments = () => {
  const { schoolId, studentId } = useParams<{
    schoolId: string
    studentId: string
  }>()
  const [loading, setLoading] = useState<number[]>([])
  const { studentDocumentViewControllerApi, documentsAdminControllerApi } =
    useApi()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const searchColumn = useSearchColumn<StudentDocumentViewDto>()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: documents, fetch: refreshDocuments } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        studentDocumentViewControllerApi.find(
          (tableParams.pagination?.current ?? 1) - 1,
          tableParams.pagination?.pageSize ?? PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams, studentId, schoolId),
        ),
      [schoolId, studentDocumentViewControllerApi, studentId, tableParams],
    ),
  })

  const acceptDoc = useCallback(
    async (id?: number) => {
      try {
        setLoading((old) => [...old, id as number])
        await documentsAdminControllerApi.accept(id as number)
        await refreshDocuments()
      } finally {
        setLoading((old) => old.filter((i) => i !== id))
      }
    },
    [documentsAdminControllerApi, refreshDocuments],
  )

  const declineDoc = useCallback(
    async (id?: number) => {
      try {
        setLoading((old) => [...old, id as number])
        await documentsAdminControllerApi.reject(id as number, {})
        await refreshDocuments()
      } finally {
        setLoading((old) => old.filter((i) => i !== id))
      }
    },
    [documentsAdminControllerApi, refreshDocuments],
  )

  const columns: ColumnsType<StudentDocumentViewDto> = useMemo(
    () => [
      {
        title: t`document.name`,
        dataIndex: 'name',
        sorter: true,
        filteredValue: tableParams.filters?.name || null,
        ...searchColumn('name'),
      },
      {
        title: t`document.required`,
        dataIndex: 'required',
        sorter: true,
        render: (required: boolean) => (required ? t`yes` : t`no`),
      },
      {
        title: t`file`,
        dataIndex: 'file',
        render: (_, entity) =>
          entity.mediaFile ? (
            <Flex style={{ width: 'auto' }}>
              <PaperClipOutlined
                style={{
                  fontSize: '22px',
                  color: 'rgba(0, 0, 0, 0.45)',
                }}
              />
              <Button
                type="link"
                onClick={async () => {
                  const { data } =
                    await documentsAdminControllerApi.getDownloadLink(
                      entity.mediaFile?.entityId as number,
                      entity.mediaFile?.id as number,
                    )
                  window.open(data, '_blank')
                  return false
                }}
                style={{
                  height: '22px',
                  padding: 0,
                  marginLeft: '8px',
                }}
              >
                {t`viewDoc`}
              </Button>
            </Flex>
          ) : null,
      },
      {
        title: t`enrollment.docStatus`,
        dataIndex: 'status',
        render: (item: SchoolEnrollmentViewDtoStatusEnum) =>
          renderStatusBadge(item, t),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => {
          return entity.mediaFile &&
            entity.status ===
              StudentDocumentViewDtoStatusEnum.DOCUMENTS_REQUESTED ? (
            <Flex justify="space-between" align="center">
              <Button
                loading={loading.includes(entity.id as number)}
                disabled={loading.includes(entity.id as number)}
                onClick={() => acceptDoc(entity.mediaFile?.entityId)}
              >{t`accept`}</Button>
              <Button
                type="link"
                loading={loading.includes(entity.id as number)}
                disabled={loading.includes(entity.id as number)}
                onClick={() => declineDoc(entity.mediaFile?.entityId)}
              >{t`decline`}</Button>
            </Flex>
          ) : null
        },
      },
    ],
    [
      acceptDoc,
      declineDoc,
      documentsAdminControllerApi,
      loading,
      searchColumn,
      t,
      tableParams.filters?.name,
    ],
  )

  return (
    <EnrollmentTabs active={routePaths.enrollment.schools}>
      <Flex align="center" gap={24} style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.enrollment.schools)}
        >
          {t`back`}
        </Button>
        <Typography.Title
          level={4}
          style={{ margin: 0 }}
        >{`${t`documents`} ${documents?.data?.content?.[0]?.student
          ?.name} ${documents?.data?.content?.[0]?.school
          ?.name}`}</Typography.Title>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={documents?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: documents?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </EnrollmentTabs>
  )
}
