import { useNavigate, useParams } from 'react-router-dom'
import useApi from '@/contexts/api'
import { useCallback, useMemo } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import {
  Avatar,
  Button,
  Descriptions,
  Flex,
  Modal,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import {
  CreateSchoolEnrollmentResponseStatusEnum,
  StudentDto,
} from '@/openapi-api/api'
import { GlobalOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { routePaths } from '@/routes'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'
import { ButtonAccordion } from '@components/ButtonAccordion'

export const Invite = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    schoolsAdminControllerApi,
    studentsAdminControllerApi,
    schoolEnrollmentsAdminControllerApi,
  } = useApi()

  const { resource: school } = useAsyncResource({
    fetchResource: useCallback(
      () => schoolsAdminControllerApi.getById(+(id as string)),
      [id, schoolsAdminControllerApi],
    ),
  })
  const { resource: students } = useAsyncResource({
    fetchResource: useCallback(
      () => studentsAdminControllerApi.find(0, 1000),
      [studentsAdminControllerApi],
    ),
  })
  const { resource: enrollments, fetch: refreshEnrollments } = useAsyncResource(
    {
      fetchResource: useCallback(
        () =>
          schoolEnrollmentsAdminControllerApi.find(
            0,
            1000,
            undefined,
            `school.id~in~${id}`,
          ),
        [id, schoolEnrollmentsAdminControllerApi],
      ),
    },
  )

  const join = useCallback(
    async (student: StudentDto) => {
      try {
        const { data } = await schoolEnrollmentsAdminControllerApi.create({
          schoolId: +(id as string),
          studentId: student.id as number,
        })
        refreshEnrollments()
        const needDocs =
          data.status ===
          CreateSchoolEnrollmentResponseStatusEnum.DOCUMENTS_REQUESTED
        const { destroy } = Modal.info({
          title: needDocs ? t`invite.requested` : t`invite.accepted`,
          icon: null,
          okType: 'link',
          okText: 'Zamknij',
          okButtonProps: { style: { alignSelf: 'flex-start', padding: 0 } },
          content: needDocs ? (
            <Flex vertical>
              <Typography.Text style={{ marginBottom: '20px' }}>
                <Trans
                  i18nKey="invite.requestedInfo1"
                  values={{
                    schoolName: school?.data.name,
                    name: student.name,
                  }}
                  components={{
                    a: (
                      <Button
                        type="link"
                        style={{ padding: 0, height: 'auto' }}
                        onClick={() => {
                          destroy()
                          navigate(
                            `${routePaths.user.enrollment}?schools=${school?.data.id}&students=${student.id}`,
                          )
                        }}
                      />
                    ),
                  }}
                />
              </Typography.Text>
              <Typography.Text>{t('invite.requestedInfo2')}</Typography.Text>
            </Flex>
          ) : (
            <Flex vertical>
              <Typography.Text style={{ marginBottom: '20px' }}>
                {t('invite.acceptedInfo1', {
                  name: student.name,
                  schoolName: school?.data.name,
                })}
              </Typography.Text>
              <Typography.Text>
                <Trans
                  i18nKey="invite.acceptedInfo2"
                  components={{
                    a: (
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                          destroy()
                          navigate(
                            `${routePaths.user.schedule}?schools=${school?.data.id}`,
                          )
                        }}
                      />
                    ),
                  }}
                />
              </Typography.Text>
            </Flex>
          ),
        })
      } catch {}
    },
    [
      id,
      navigate,
      refreshEnrollments,
      school?.data.id,
      schoolEnrollmentsAdminControllerApi,
      t,
    ],
  )

  const columns: TableColumnsType<StudentDto> = useMemo(
    () => [
      {
        title: t`teacher.fio`,
        dataIndex: 'name',
        render: (_, entity) => (
          <Space size="middle">
            <Avatar
              shape="circle"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.thumbLink || <GlobalOutlined />}
            />
            <Flex vertical>
              <Typography>{entity.name}</Typography>
              <Typography>
                {entity.birthDate
                  ? dayjs(entity.birthDate).format('DD MMM, YYYY')
                  : ''}
              </Typography>
            </Flex>
          </Space>
        ),
      },
      {
        title: t`invite.status`,
        dataIndex: 'status',
        render: (_, entity) => {
          const enrollStatus = enrollments?.data.content?.find(
            (e) => e.student?.id === entity.id,
          )?.status
          if (!enrollStatus)
            return (
              <Button onClick={() => join(entity)}>{t`invite.join`}</Button>
            )
          return renderStatusBadge(enrollStatus, t)
        },
      },
    ],
    [enrollments?.data.content, join, t],
  )
  return (
    <Flex vertical align="center" id="invite">
      <Flex vertical style={{ maxWidth: '500px' }}>
        {school?.data.mediaFiles?.[0] && (
          <div style={{ margin: '-16px', width: 'calc(100% + 32px)' }}>
            <img
              style={{ aspectRatio: '16/9', objectFit: 'cover', width: '100%' }}
              src={school.data.mediaFiles[0].link}
            />
          </div>
        )}
        <Typography.Title
          level={4}
          style={{
            textAlign: 'center',
            marginTop: '30px',
            marginBottom: '15px',
          }}
        >
          {t('invite.welcome', { name: school?.data.name })}
        </Typography.Title>
        <Typography.Text>{t`invite.addStudent`}</Typography.Text>
        <ButtonAccordion title={t`invite.moreAboutSchool`}>
          <Descriptions title="" bordered>
            {school?.data.description && (
              <Descriptions.Item label={t`group.description`} span={3}>
                {school.data.description}
              </Descriptions.Item>
            )}
            {school?.data.description && (
              <Descriptions.Item label={t`profile.email`} span={3}>
                <a href={`mailto: ${school.data.email}`}>{school.data.email}</a>
              </Descriptions.Item>
            )}
            {school?.data.facebookLink && (
              <Descriptions.Item label={'Facebook'} span={3}>
                <a
                  href={school.data.facebookLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {school.data.facebookLink}
                </a>
              </Descriptions.Item>
            )}
            {school?.data.instagramLink && (
              <Descriptions.Item label={'Instagram'} span={3}>
                <a
                  href={school.data.instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {school.data.instagramLink}
                </a>
              </Descriptions.Item>
            )}
            {school?.data.instagramLink && (
              <Descriptions.Item label={t`profile.phone`} span={3}>
                <a
                  href={`tel:${school.data.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${school.data.phone}`}
                </a>
              </Descriptions.Item>
            )}
            {school?.data.schoolSite && (
              <Descriptions.Item label={t`school.site`} span={3}>
                <a
                  href={school.data.schoolSite}
                  target="_blank"
                  rel="noreferrer"
                >
                  {school.data.schoolSite}
                </a>
              </Descriptions.Item>
            )}
          </Descriptions>
        </ButtonAccordion>
        <Table
          columns={columns}
          dataSource={students?.data.content || []}
          style={{ marginTop: '16px' }}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Button
          type="link"
          style={{
            marginTop: '20px',
            marginLeft: '45px',
            width: 'fit-content',
          }}
          onClick={() =>
            navigate(
              `${
                routePaths.user.students.specific('new').edit
              }?fromSchool=${id}`,
            )
          }
        >{t`invite.newStudent`}</Button>
      </Flex>
    </Flex>
  )
}
