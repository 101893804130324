/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { SchoolViewDto } from './school-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StudentViewDto } from './student-view-dto';

/**
 * 
 * @export
 * @interface SchoolEnrollmentViewDto
 */
export interface SchoolEnrollmentViewDto {
    /**
     * 
     * @type {number}
     * @memberof SchoolEnrollmentViewDto
     */
    'id'?: number;
    /**
     * 
     * @type {SchoolViewDto}
     * @memberof SchoolEnrollmentViewDto
     */
    'school'?: SchoolViewDto;
    /**
     * 
     * @type {StudentViewDto}
     * @memberof SchoolEnrollmentViewDto
     */
    'student'?: StudentViewDto;
    /**
     * 
     * @type {string}
     * @memberof SchoolEnrollmentViewDto
     */
    'status'?: SchoolEnrollmentViewDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SchoolEnrollmentViewDto
     */
    'documentRequested'?: number;
    /**
     * 
     * @type {number}
     * @memberof SchoolEnrollmentViewDto
     */
    'documentProvided'?: number;
    /**
     * 
     * @type {string}
     * @memberof SchoolEnrollmentViewDto
     */
    'enrollmentDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SchoolEnrollmentViewDtoStatusEnum {
    DOCUMENTS_REQUESTED = 'documents_requested',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected'
}


