import { Button, Flex, Modal, QRCode, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { TournamentDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { addFilterToUrl } from '@/utils/functions/filterUtils'
import {
  CopyOutlined,
  PrinterOutlined,
  FileImageOutlined,
} from '@ant-design/icons'
import printJS from 'print-js'
import useIsDevice from '@/utils/hooks/useIsMobile'
import dayjs from 'dayjs'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.city?.length) {
    query.push(`city.name~~${filters.city[0]}`)
  }
  return query.join(';') || undefined
}

const PAGE_SIZE = 10

const TournamentsList = () => {
  const { t } = useTranslation()
  const { tournamentsAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { isBothMobile } = useIsDevice()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: tournaments } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        tournamentsAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          doSort(tableParams, { dates: 'dateStart' }),
          doQuery(tableParams),
        ),
      [tournamentsAdminControllerApi, tableParams],
    ),
  })

  const searchColumn = useSearchColumn<TournamentDto>()

  const handlePrint = () => {
    const canvas = document.querySelector(
      '#myqrcode canvas',
    ) as HTMLCanvasElement
    printJS({
      printable: canvas.toDataURL(),
      type: 'image',
      imageStyle: 'width:100%',
    })
  }

  const columns: ColumnsType<TournamentDto> = useMemo(
    () => [
      {
        title: t`tournaments.name`,
        dataIndex: 'name',
        ...searchColumn('name'),
      },
      {
        title: t`city`,
        dataIndex: 'city',
        ...searchColumn('city'),
        render: (_, entity) => entity.city?.name,
      },
      {
        title: t`date`,
        sorter: true,
        dataIndex: 'dates',
        render: (_, entity) => {
          const from = entity.dateStart
            ? dayjs(entity.dateStart).format('DD.MM.YYYY')
            : ''
          const to = entity.dateEnd
            ? dayjs(entity.dateEnd).format('DD.MM.YYYY')
            : ''
          return `${from} - ${to}`
        },
      },
      {
        title: t`tournaments.registrationEnd`,
        dataIndex: 'registrationDateEnd',
        sorter: true,
        render: (val) => (val ? dayjs(val).format('DD.MM.YYYY') : '-'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={routePaths.organiser.tournaments.specific(entity.id).edit}
            >{t`Edit`}</Link>
            <Link
              to={
                routePaths.organiser.categories.all +
                addFilterToUrl({
                  filters: {
                    tournament: [entity.name],
                  },
                })
              }
            >{t`tournaments.categories`}</Link>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                const url =
                  window.location.origin +
                  routePaths.tournamentInvite(entity.id)
                Modal.confirm({
                  icon: null,
                  width: 'fit-content',
                  content: (
                    <Flex vertical gap={8} id="myqrcode">
                      <QRCode value={url} type="canvas" />
                      <Flex vertical gap={4}>
                        <Button
                          icon={<CopyOutlined />}
                          onClick={() => navigator.clipboard.writeText(url)}
                        >
                          {t`copyUrl`}
                        </Button>
                        <Button
                          icon={<FileImageOutlined />}
                          onClick={() => {
                            const canvas = document.querySelector(
                              '#myqrcode canvas',
                            ) as HTMLCanvasElement
                            if (canvas) {
                              canvas.toBlob((blob) => {
                                if (blob) {
                                  const item = new ClipboardItem({
                                    'image/png': blob,
                                  })
                                  console.log(item)
                                  navigator.clipboard.write([item])
                                }
                              })
                            }
                          }}
                        >
                          {t`copyAsImage`}
                        </Button>
                        <Button
                          icon={<PrinterOutlined />}
                          onClick={handlePrint}
                        >
                          {t`print`}
                        </Button>
                      </Flex>
                    </Flex>
                  ),
                  cancelButtonProps: { style: { display: 'none' } },
                })
              }}
            >{t`qrCode`}</Button>
          </Space>
        ),
      },
    ],
    [isBothMobile, searchColumn, t],
  )

  return (
    <Flex vertical>
      <div style={{ marginBottom: '24px' }}>
        <Button
          onClick={() =>
            navigate(routePaths.organiser.tournaments.specific('new').edit)
          }
        >{t`tournaments.add`}</Button>
      </div>
      <Typography.Title
        level={5}
      >{t`tournaments.tournaments`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={tournaments?.data.content || []}
        onChange={handleTableChange}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default TournamentsList
