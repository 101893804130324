import { Form, FormInstance, Upload } from 'antd'
import { normFile } from '@pages/profile'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InboxOutlined } from '@ant-design/icons'

interface Props {
  label: string
  name?: string
  form: FormInstance
  required?: boolean
}

export const FormDocument: FC<Props> = ({
  label,
  form,
  name = 'document',
  required = false,
}) => {
  const avatar = Form.useWatch('avatar', form)
  const { t } = useTranslation()
  return (
    <Form.Item
      label={label}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      name={name}
      style={{ minHeight: '102px' }}
      rules={[...(required ? [{ required: true, message: t`required` }] : [])]}
    >
      <Upload.Dragger
        name="files"
        maxCount={1}
        multiple={false}
        beforeUpload={() => false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {'Click or drag file to this area to upload'}
        </p>
        <p className="ant-upload-hint">
          {'Support for a single or bulk upload.'}
        </p>
      </Upload.Dragger>
    </Form.Item>
  )
}
