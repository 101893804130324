import { FC, useState } from 'react'
import {
  Button,
  Modal,
  Table,
  Upload,
  message,
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import useApi from '@/contexts/api'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { useTranslation } from 'react-i18next'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { useGetSchools } from '@/utils/hooks/entities'
import { getValue } from '@/utils/functions/dataUtils'

const { Option } = Select

interface Student {
  name?: string | null
  comments?: string | null
  email?: string | null
  birthDate?: string | null
  gender?: string | null
  phone?: string | null
}

interface Props {
  refresh: () => Promise<void>
}

const ImportStudents: FC<Props> = ({ refresh }) => {
  const [students, setStudents] = useState<Student[]>([])
  const [editingKey, setEditingKey] = useState<number | undefined>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { studentsAdminControllerApi } = useApi()
  const { t } = useTranslation()
  const { getLocaleField } = useLocaleHelpers()
  const { appLang } = useLanguageContext()
  const schools = useGetSchools()

  const uploadProps = {
    beforeUpload: (file: File) => {
      setLoading(true)
      setEditingKey(undefined)
      studentsAdminControllerApi
        .importStudents(file)
        .then((response) => {
          setStudents(
            response.data.map((item) => ({
              name: getLocaleField('namePL', item),
              comments: getLocaleField('commentsPL', item),
              birthDate: item.birthDate,
              email: item.email,
              gender: item.gender,
              phone: item.phone,
            })),
          )
          setIsModalVisible(true)
          setLoading(false)
        })
        .catch(() => {
          message.error('Ошибка загрузки файла.')
          setLoading(false)
        })

      return false
    },
    fileList: [],
  }

  const isEditing = (i: number) => i === editingKey

  const edit = (record: Student, i: number) => {
    form.setFieldsValue({
      name: record.name,
      comments: record.comments,
      email: record.email,
      birthDate: record.birthDate ? dayjs(record.birthDate) : null,
      gender: record.gender,
      phone: record.phone,
    })
    setEditingKey(i)
  }

  const save = async (index: number) => {
    try {
      const row = await form.validateFields()
      const newData = [...students]
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
          birthDate: row.birthDate ? row.birthDate.format('YYYY-MM-DD') : null,
        })
        setStudents(newData)
        setEditingKey(undefined)
      }
    } catch (err) {
      console.log('Ошибка сохранения:', err)
    }
  }

  const columns = [
    {
      title: t`login.name`,
      dataIndex: 'name',
      editable: true,
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="name">
            <Input />
          </Form.Item>
        ) : (
          record.name
        ),
    },
    {
      title: t`student.comments`,
      dataIndex: 'comments',
      editable: true,
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="comments">
            <Input />
          </Form.Item>
        ) : (
          record.comments
        ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="email">
            <Input />
          </Form.Item>
        ) : (
          record.email
        ),
    },
    {
      title: 'Birth Date',
      dataIndex: 'birthDate',
      editable: true,
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="birthDate">
            <DatePicker />
          </Form.Item>
        ) : record.birthDate ? (
          dayjs(record.birthDate).format('YYYY-MM-DD')
        ) : (
          '-'
        ),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      editable: true,
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="gender">
            <Select>
              <Option value="male">{t`teacher.genderMan`}</Option>
              <Option value="female">{t`teacher.genderWoman`}</Option>
              <Option value="other">{t`teacher.genderOther`}</Option>
            </Select>
          </Form.Item>
        ) : (
          record.gender || '-'
        ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      editable: true,
      render: (_: any, record: Student, index: number) =>
        isEditing(index) ? (
          <Form.Item name="phone">
            <Input />
          </Form.Item>
        ) : (
          record.phone || '-'
        ),
    },
    {
      title: 'Action',
      render: (_: any, record: Student, index: number) => {
        const editable = isEditing(index)
        return editable ? (
          <span>
            <Button onClick={() => save(index)} type="link">
              {t`save`}
            </Button>
          </span>
        ) : (
          <Button
            disabled={editingKey !== undefined}
            onClick={() => edit(record, index)}
            type="link"
          >
            {t`Edit`}
          </Button>
        )
      },
    },
  ]

  const handleSubmit = () => {
    const schoolId = form.getFieldValue('schoolId')
    if (!schoolId?.length) {
      form.setFields([{ name: 'schoolId', errors: [t`required`] }])
      return
    }
    studentsAdminControllerApi
      .importStudents_1(
        schoolId.map(getValue),
        students.map(
          (s) =>
            ({
              parentEmail: s.email || '',
              birthDate: s.birthDate || '',
              gender: s.gender || '',
              phone: s.phone || '',
              [`name${appLang}`]: s.name,
              [`comments${appLang}`]: s.comments,
            }) as any,
        ),
      )
      .then(() => {
        refresh()
        setIsModalVisible(false)
      })
      .catch(() => {})
  }
  console.log(form.getFieldsValue())
  return (
    <>
      <Upload {...uploadProps}>
        <Button
          icon={<UploadOutlined />}
          loading={loading}
          style={{ marginBottom: '16px' }}
        >
          {loading ? t`fileLoading` : t`uploadStudentsFile`}
        </Button>
      </Upload>

      <Modal
        title={t`editStudents`}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
        footer={false}
      >
        <Form form={form} component={false}>
          <Form.Item
            name="schoolId"
            label={t`school.school`}
            rules={[
              {
                required: true,
                message: t`required`,
                type: 'array',
              },
            ]}
          >
            <Select
              placeholder={t`school.pleaseSelect`}
              options={optionsFromEntity(schools)}
              mode="tags"
            />
          </Form.Item>
          <Table
            columns={columns}
            dataSource={students}
            rowKey="email"
            pagination={false}
            scroll={{ x: '100%' }}
          />
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: '16px' }}
            onClick={handleSubmit}
          >
            {t`save`}
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default ImportStudents
