/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserRegistrationRequest
 */
export interface UserRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'role': UserRegistrationRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'firstNamePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'lastNamePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'firstNameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'lastNameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'firstNameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'lastNameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'firstNameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'lastNameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserRegistrationRequest
     */
    'countryId': number;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    'password': string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRegistrationRequestRoleEnum {
    ADMIN = 'admin',
    USER = 'user',
    ORGANISER = 'organiser'
}


