import {
  CountriesAdminControllerApi,
  Configuration,
  RegistrationControllerApi,
  LoginControllerApi,
  UserControllerApi,
  AdminsAdminControllerApi,
  SchoolsAdminControllerApi,
  ProfilesAdminControllerApi,
  PlacesAdminControllerApi,
  RoomsAdminControllerApi,
  TeachersAdminControllerApi,
  SpecializationsAdminControllerApi,
  StudentsAdminControllerApi,
  StudentGroupsAdminControllerApi,
  SessionsAdminControllerApi,
  SchoolEnrollmentsAdminControllerApi,
  DocumentsAdminControllerApi,
  DocumentTypesAdminControllerApi,
  StudentDocumentViewControllerApi,
  SchoolEnrollmentsViewControllerApi,
  StudentGroupEnrollmentsAdminControllerApi,
  SessionStudentsAdminControllerApi,
  StudentSessionEnrollmentViewControllerApi,
  SessionAttendanceViewControllerApi,
  StudentGroupEnrollmentViewControllerApi,
  NotificationsControllerApi,
  NotificationListsControllerApi,
  SentNotificationsViewControllerApi,
  ReceivedNotificationsViewControllerApi,
  OrganisersAdminControllerApi,
  CitiesAdminControllerApi,
  TournamentsAdminControllerApi,
  TournamentCategoriesAdminControllerApi,
  PaymentsAdminControllerApi,
  ActiveStudentsViewControllerApi,
  SchoolTournamentsAdminControllerApi,
} from '@/openapi-api/api'
import { createContext } from 'react'

import { IApiContext } from './types'

export const api: IApiContext = {
  countriesControllerApi: new CountriesAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  registrationControllerApi: new RegistrationControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  loginControllerApi: new LoginControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  userControllerApi: new UserControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  adminsAdminControllerApi: new AdminsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  schoolsAdminControllerApi: new SchoolsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  profilesAdminControllerApi: new ProfilesAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  placesAdminControllerApi: new PlacesAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  roomsAdminControllerApi: new RoomsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  teachersAdminControllerApi: new TeachersAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  specializationsAdminControllerApi: new SpecializationsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentsAdminControllerApi: new StudentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentGroupsAdminControllerApi: new StudentGroupsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  sessionsAdminControllerApi: new SessionsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  schoolEnrollmentsAdminControllerApi: new SchoolEnrollmentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  documentsAdminControllerApi: new DocumentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  documentTypesAdminControllerApi: new DocumentTypesAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentDocumentViewControllerApi: new StudentDocumentViewControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  schoolEnrollmentsViewControllerApi: new SchoolEnrollmentsViewControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentGroupEnrollmentsAdminControllerApi:
    new StudentGroupEnrollmentsAdminControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_BASE_URL + '/api',
      }),
    ),
  sessionStudentsAdminControllerApi: new SessionStudentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentSessionEnrollmentViewControllerApi:
    new StudentSessionEnrollmentViewControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_BASE_URL + '/api',
      }),
    ),
  sessionAttendanceViewControllerApi: new SessionAttendanceViewControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  studentGroupEnrollmentViewControllerApi:
    new StudentGroupEnrollmentViewControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_BASE_URL + '/api',
      }),
    ),
  notificationsControllerApi: new NotificationsControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  notificationListsControllerApi: new NotificationListsControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  sentNotificationsViewControllerApi: new SentNotificationsViewControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  receivedNotificationsViewControllerApi:
    new ReceivedNotificationsViewControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_BASE_URL + '/api',
      }),
    ),
  organisersAdminControllerApi: new OrganisersAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  citiesAdminControllerApi: new CitiesAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  tournamentsAdminControllerApi: new TournamentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  tournamentCategoriesAdminControllerApi:
    new TournamentCategoriesAdminControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_BASE_URL + '/api',
      }),
    ),
  paymentsAdminControllerApi: new PaymentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  activeStudentsViewControllerApi: new ActiveStudentsViewControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
  schoolTournamentsAdminControllerApi: new SchoolTournamentsAdminControllerApi(
    new Configuration({
      basePath: process.env.REACT_APP_BASE_URL + '/api',
    }),
  ),
}

const Context = createContext<IApiContext>(api)

export default Context
