import { FC, ReactNode } from 'react'

export const ExternalLinkWrapper: FC<{ url?: string; title?: ReactNode }> = ({
  url,
  title,
}) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer">
      {title || url}
    </a>
  ) : null
