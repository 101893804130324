import { Flex, Form, FormInstance } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'

interface Props {
  name?: string
  label: string
  form: FormInstance
}

const colors = [
  '#BAF1FF',
  '#C6FCFF',
  '#C7FFEF',
  '#C9FFC7',
  '#DFFFBA',
  '#F3FFC7',
  '#FFFCBA',
  '#FFF4C6',
  '#FFDABA',
  '#FFD8C6',
  '#FFBABD',
  '#FFC7D7',
  '#FFC6EE',
  '#E6C6FF',
  '#D3C7FF',
  '#BACEFF',
  '#C6DFFF',
  '#C7E5FF',
]

const ColorItem: FC<{
  color: string
  selected: boolean
  onClick: () => void
}> = ({ color, selected, onClick }) => (
  <div
    onClick={onClick}
    style={{
      width: '32px',
      height: '32px',
      padding: '4px',
      borderRadius: '6px',
      outline: `1px solid ${selected ? '#1677FF' : '#D9D9D9'}`,
      cursor: 'pointer',
    }}
  >
    <div
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        backgroundColor: color,
      }}
    />
  </div>
)

export const FormColorPicker: FC<Props> = ({ name = 'color', label, form }) => {
  const { t } = useTranslation()
  const active = Form.useWatch(name, form)
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: true,
          message: t`required`,
        },
      ]}
    >
      <Flex gap={8} wrap="wrap">
        {colors.map((c) => (
          <ColorItem
            key={c}
            color={c}
            selected={c === active}
            onClick={() => form.setFieldValue(name, c)}
          />
        ))}
      </Flex>
    </Form.Item>
  )
}
