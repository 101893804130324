import { useEffect, useRef } from 'react'
import axios, { AxiosResponse } from 'axios'
import { RefreshTokenRequest, UserLoginResponse } from '@/openapi-api/api'
import useApi from '@/contexts/api'
import { hasTrueField } from '../functions/hasTrueField'
import { useLanguageContext } from '@/contexts/LanguageProvider'

export function useAuthInterceptorsEffect(
  jwtData: UserLoginResponse | null,
  setJwtData: (value: UserLoginResponse | null) => unknown,
  logout: () => Promise<void>,
) {
  const { loginControllerApi } = useApi()
  const { appLang } = useLanguageContext()

  const isRefreshingNowLockRef = useRef(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doTokenRefresh = async (originalRequest?: any) => {
      if (!jwtData?.refreshToken) {
        return
      }

      isRefreshingNowLockRef.current = true

      let refreshResp: AxiosResponse<RefreshTokenRequest>
      try {
        refreshResp = await loginControllerApi.refreshAccessToken(jwtData, {
          headers: { Authorization: '' },
        })
      } catch (error) {
        await logout()
        return Promise.reject(error)
      }

      setJwtData(refreshResp.data)

      if (!originalRequest) {
        isRefreshingNowLockRef.current = false
        return
      }

      // resend original request
      originalRequest.headers.Authorization = `Bearer ${refreshResp.data.accessToken}`
      const originalResult = await axios(originalRequest)

      isRefreshingNowLockRef.current = false

      return originalResult
    }

    const authRequestInterceptor = axios.interceptors.request.use(
      function (config) {
        if (!jwtData?.accessToken) {
          return config
        }
        if (config.headers.Authorization === '') {
          config.headers.Authorization = undefined
        } else if (!hasTrueField(config.headers, 'Authorization')) {
          config.headers.Authorization = `Bearer ${jwtData.accessToken}`
        }
        config.headers['festivale-language'] = appLang

        return config
      },
    )

    const authResponseInterceptor = axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const { config, response } = error

        switch (response?.status) {
          case 401:
            if (jwtData === null || isRefreshingNowLockRef.current) {
              break
            }

            return doTokenRefresh(config)
          default:
          // do nothing
        }

        return Promise.reject(error)
      },
    )

    return () => {
      axios.interceptors.request.eject(authRequestInterceptor)
      axios.interceptors.response.eject(authResponseInterceptor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtData, axios, loginControllerApi, appLang])
}
