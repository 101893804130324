import useApi from '@/contexts/api'
import { useCallback } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { Flex } from 'antd'
import { SchoolTabs } from '@pages/user/components/SchoolTabs'

export const UserSchools = () => {
  const { schoolsAdminControllerApi, profilesAdminControllerApi } = useApi()

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(
      () => schoolsAdminControllerApi.find(0, 1000),
      [schoolsAdminControllerApi],
    ),
  })

  return (
    <Flex
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(347px, 1fr))',
      }}
      gap="middle"
    >
      {schools?.data.content?.map((s) => <SchoolTabs key={s.id} school={s} />)}
    </Flex>
  )
}
