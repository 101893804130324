/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SendUserNotificationRequest
 */
export interface SendUserNotificationRequest {
    /**
     * 
     * @type {number}
     * @memberof SendUserNotificationRequest
     */
    'entityId': number;
    /**
     * 
     * @type {string}
     * @memberof SendUserNotificationRequest
     */
    'type': SendUserNotificationRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SendUserNotificationRequest
     */
    'sentToAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SendUserNotificationRequest
     */
    'schoolId': number;
    /**
     * 
     * @type {string}
     * @memberof SendUserNotificationRequest
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof SendUserNotificationRequest
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof SendUserNotificationRequest
     */
    'lang': SendUserNotificationRequestLangEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SendUserNotificationRequestTypeEnum {
    ADMIN = 'admin',
    COUNTRY = 'country',
    CITY = 'city',
    SCHOOL = 'school',
    SCHOOL_ENROLLMENT = 'school_enrollment',
    TEACHER = 'teacher',
    USER = 'user',
    STUDENT = 'student',
    SCHEDULE = 'schedule',
    STUDENT_GROUP = 'student_group',
    GROUP_ENROLLMENT = 'group_enrollment',
    SESSION = 'session',
    DOCUMENT_TYPE = 'document_type',
    DOCUMENT = 'document',
    ROOM = 'room',
    PLACE = 'place',
    TOURNAMENT = 'tournament',
    SCHOOL_TOURNAMENT = 'school_tournament',
    ORGANISER = 'organiser',
    TOURNAMENT_CATEGORY = 'tournament_category',
    TOURNAMENT_CATEGORY_REGISTRATION = 'tournament_category_registration'
}
/**
    * @export
    * @enum {string}
    */
export enum SendUserNotificationRequestLangEnum {
    PL = 'PL',
    EN = 'EN',
    UA = 'UA',
    RU = 'RU'
}


