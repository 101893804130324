import { Flex, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import {
  ProfileDto,
  TournamentCategoryDto,
  TournamentDto,
} from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'

import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.tournament?.length) {
    query.push(`tournament.name~~${filters.tournament[0]}`)
  }
  if (filters?.profile?.length) {
    query.push(`profile.name~~${filters.profile[0]}`)
  }
  if (filters?.specialization?.length) {
    query.push(`specialization~~${filters.specialization[0]}`)
  }
  if (filters?.teamSizeName?.length) {
    query.push(`teamSizeName~~${filters.teamSizeName[0]}`)
  }
  if (filters?.level?.length) {
    query.push(`level~~${filters.level[0]}`)
  }
  if (filters?.teamAgeName?.length) {
    query.push(`teamAgeName~~${filters.teamAgeName[0]}`)
  }

  return query.join(';') || undefined
}

const PAGE_SIZE = 20

const AdminTournamentCategoriesList = () => {
  const { t } = useTranslation()
  const { tournamentCategoriesAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: tournaments, fetch: refresh } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        tournamentCategoriesAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          doSort(tableParams, { tournament: 'tournament.name' }),
          doQuery(tableParams),
        ),
      [tournamentCategoriesAdminControllerApi, tableParams],
    ),
  })

  const searchColumn = useSearchColumn<TournamentCategoryDto>()

  const columns: ColumnsType<TournamentCategoryDto> = useMemo(
    () => [
      {
        title: t`tournaments.name`,
        dataIndex: 'tournament',
        key: 'tournament',
        filteredValue: tableParams.filters?.tournament || null,
        ...searchColumn('tournament'),
        sorter: true,
        render: (val: TournamentDto) => val.name,
      },
      {
        title: t`profile.profile`,
        dataIndex: 'profile',
        filteredValue: tableParams.filters?.profile || null,
        ...searchColumn('profile'),
        sorter: true,
        key: 'profile',
        render: (val: ProfileDto) => val.name,
      },
      {
        title: t`group.specialization`,
        filteredValue: tableParams.filters?.specialization || null,
        ...searchColumn('specialization'),
        sorter: true,
        dataIndex: 'specialization',
        key: 'specialization',
      },
      {
        title: t`tournaments.bulkTeamSize`,
        dataIndex: 'teamSizeName',
        key: 'teamSizeName',
        filteredValue: tableParams.filters?.teamSizeName || null,
        ...searchColumn('teamSizeName'),
        sorter: true,
        render: (val: string) => t(val),
      },
      {
        title: t`group.level`,
        dataIndex: 'level',
        key: 'level',
        filteredValue: tableParams.filters?.level || null,
        ...searchColumn('level'),
        sorter: true,
      },
      {
        title: t`tournaments.bulkAgeCategoryName`,
        dataIndex: 'teamAgeName',
        key: 'teamAgeName',
        filteredValue: tableParams.filters?.teamAgeName || null,
        ...searchColumn('teamAgeName'),
        sorter: true,
      },
      {
        title: t`teacher.gender`,
        dataIndex: 'gender',
        key: 'gender',
        sorter: true,
        render: (val) => {
          switch (val) {
            case 'male':
              return t`teacher.genderMan`
            case 'female':
              return t`teacher.genderWoman`
            case 'other':
              return t`teacher.genderOther`
            default:
              return ''
          }
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (i, entity, index) => <Space size="middle"></Space>,
      },
    ],
    [
      searchColumn,
      t,
      tableParams.filters?.level,
      tableParams.filters?.profile,
      tableParams.filters?.specialization,
      tableParams.filters?.teamAgeName,
      tableParams.filters?.teamSizeName,
      tableParams.filters?.tournament,
    ],
  )

  return (
    <Flex vertical>
      <Typography.Title level={5}>{t`tournaments.categories`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={tournaments?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: tournaments?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default AdminTournamentCategoriesList
