import { ThemeConfig } from 'antd'

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: 'San Francisco Pro',
    colorPrimary: 'rgba(0, 58, 201, 1)',
  },
  components: {
    Typography: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    Form: { labelColor: 'rgba(0, 0, 0, 0.88)' },
  },
}
