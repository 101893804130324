import './App.less'
import './vars.less'
import PL from 'antd/es/locale/pl_PL'
import { Locale } from 'antd/es/locale'
import { useEffect, useMemo } from 'react'
import { ConfigProvider } from 'antd'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { MainLayout } from '@components/Layout/MainLayout'
import { routePaths } from '@/routes'
import Login from '@pages/login'
import './i18n'
import { themeConfig } from '@/theme'
import { Verification } from '@pages/login/Verification'
import { Dashboard } from '@pages/Dashboard'
import { useAuthContext } from '@/contexts/AuthContext'
import Profile from '@pages/profile'
import Schools from '@pages/schools'
import EditSchool from '@pages/schools/edit'
import ViewSchool from '@pages/schools/view'
import Locations from '@pages/locations/LocationsList'
import LocationEdit from '@pages/locations/edit'
import RoomsList from '@pages/locations/rooms/RoomsList'
import TeachersList from '@pages/teachers'
import EditTeacher from './pages/teachers/edit'
import { ForgotPasswordView } from '@pages/login/ResetPassword'
import TeacherView from './pages/teachers/view/TeacherView'
import { UserSchools } from '@pages/user/Schools'
import GroupsList from '@pages/groups/GroupsList'
import GroupEdit from '@pages/groups/edit/GroupEdit'
import RoomEdit from './pages/locations/rooms/edit'
import GroupSchedule from '@pages/groups/schedule/GroupSchedule'
import ScheduleViewWrapper from '@pages/schedule/ScheduleViewWrapper'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import weekday from 'dayjs/plugin/weekday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Invite from '@pages/Invite'
import Students from '@pages/user/Students'
import EditStudent from './pages/user/Students/edit'
import { SchoolEnrollment } from '@pages/enrollments/SchoolEnrollment'
import { DocsEnrollment } from '@pages/enrollments/DocsEnrollment'
import { GroupsEnrollment } from '@pages/enrollments/GroupsEnrollment'
import DocEdit from './pages/enrollments/DocEdit'
import { ParentEnrollment } from '@pages/enrollments/ParentEnrollment'
import { SchoolStudentEnrollmentDocuments } from '@pages/enrollments/SchoolStudentEnrollmentDocuments'
import UserScheduleViewWrapper from '@pages/schedule/UserScheduleViewWrapper'
import MySchedule from '@pages/schedule/MySchedule'
import AttendanceWrapper from './pages/attendance/AttendanceWrapper'
import { AttendanceStudentsWrapper } from '@pages/attendance/AttendanceStudentsWrapper'
import TagManager from 'react-gtm-module'
import AllRoomsList from '@pages/roomsAll/AllRoomsList'
import AllRoomEdit from '@pages/roomsAll/edit'
import NewSchedule from '@pages/groups/schedule/NewSchedule'
import isoWeek from 'dayjs/plugin/isoWeek'
import { ScheduleEdit } from '@pages/groups/schedule/ScheduleEdit'
import { SentNotifications } from '@pages/notifications/SentNotifications'
import { ReceivedNotifications } from '@pages/notifications/ReceivedNotifications'
import { SentNotificationsItem } from '@pages/notifications/SentNotificationsItem'
import Tournaments from '@pages/organiser/tournaments'
import EditTournament from '@pages/organiser/tournaments/edit'
import TournamentCategoriesList from '@pages/organiser/tournamentCategories'
import CreateTournamentCategoryBulk from '@pages/organiser/tournamentCategories/createBulk'
import OrganizerProfile from '@pages/organiser/profile'
import EditTournamentCategory from '@pages/organiser/tournamentCategories/edit'
import Payments from '@pages/payments'
import PaymentView from '@pages/payments/view'
import TournamentInvite from './pages/InviteTornament'
import AdminTournamentsList from '@pages/admin/tournaments'
import AdminTournamentCategoriesList from '@pages/admin/tournamentCategories'
import AdminTournamentsStudents from '@pages/admin/students'

const languageMapping: { [key: string]: Locale } = {
  PL,
}

dayjs.extend(isToday)
dayjs.locale('PL')
dayjs.extend(weekday)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isoWeek)

function RequireAuth({ children }: Readonly<{ children: JSX.Element }>) {
  const location = useLocation()
  const { isLoggedIn } = useAuthContext()
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

function App() {
  const { appLang } = useLanguageContext()
  const providerLanguage = useMemo(() => languageMapping[appLang], [appLang])

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTAG || '' })
  }, [])

  return (
    <ConfigProvider locale={providerLanguage} theme={themeConfig}>
      <Routes>
        <Route path="/" element={<Navigate to={routePaths.app} replace />} />
        <Route
          path={routePaths.app}
          element={<Navigate to={routePaths.dashboard} replace />}
        />
        <Route path={routePaths.login} element={<Login />} />
        <Route path={routePaths.verification} element={<Verification />} />
        <Route
          path={routePaths.resetPassword}
          element={<ForgotPasswordView />}
        />
        <Route
          path={routePaths.app}
          element={
            <RequireAuth>
              <MainLayout />
            </RequireAuth>
          }
        >
          <Route path={routePaths.dashboard} element={<Dashboard />} />
          <Route path={routePaths.schedule} element={<ScheduleViewWrapper />} />
          <Route path={routePaths.profile} element={<Profile />} />
          <Route path={routePaths.payments.all} element={<Payments />} />
          <Route path={routePaths.schools.all} element={<Schools />} />
          <Route path={routePaths.groups.all} element={<GroupsList />} />
          <Route
            path={routePaths.schools.specific(':id').edit}
            element={<EditSchool />}
          />
          <Route
            path={routePaths.payments.specific(':id').view}
            element={<PaymentView />}
          />
          <Route
            path={routePaths.groups.specific(':id').edit}
            element={<GroupEdit />}
          />
          <Route
            path={routePaths.groups.specific(':id').schedule}
            element={<GroupSchedule />}
          />
          <Route
            path={routePaths.attendance.all}
            element={<AttendanceWrapper />}
          />
          <Route
            path={routePaths.attendance.specific(':id')}
            element={<AttendanceStudentsWrapper />}
          />
          <Route
            path={routePaths.schools.specific(':id').view}
            element={<ViewSchool />}
          />
          <Route
            path={routePaths.teachers.specific(':id').view}
            element={<TeacherView />}
          />
          <Route path={routePaths.locations.all} element={<Locations />} />
          <Route
            path={routePaths.locations.specific(':id').edit}
            element={<LocationEdit />}
          />
          <Route
            path={
              routePaths.locations.specific(':locationId').rooms.specific(':id')
                .edit
            }
            element={<RoomEdit />}
          />
          <Route
            path={routePaths.teachers.specific(':id').edit}
            element={<EditTeacher />}
          />
          <Route
            path={routePaths.locations.specific(':id').rooms.all}
            element={<RoomsList />}
          />
          <Route path={routePaths.schoolInvite(':id')} element={<Invite />} />
          <Route
            path={routePaths.tournamentInvite(':id')}
            element={<TournamentInvite />}
          />
          <Route path={routePaths.teachers.all} element={<TeachersList />} />
          <Route path={routePaths.user.schools.all} element={<UserSchools />} />
          <Route path={routePaths.user.students.all} element={<Students />} />
          <Route path={routePaths.user.mySchedule} element={<MySchedule />} />
          <Route
            path={routePaths.user.schedule}
            element={<UserScheduleViewWrapper />}
          />
          <Route
            path={routePaths.user.students.specific(':id').edit}
            element={<EditStudent />}
          />
          <Route
            path={routePaths.enrollment.docs.all}
            element={<DocsEnrollment />}
          />
          <Route
            path={routePaths.enrollment.schools}
            element={<SchoolEnrollment />}
          />
          <Route
            path={routePaths.enrollment.groups}
            element={<GroupsEnrollment />}
          />
          <Route
            path={routePaths.user.enrollment}
            element={<ParentEnrollment />}
          />
          <Route
            path={routePaths.enrollment.docs.specific(':id').edit}
            element={<DocEdit />}
          />
          <Route
            path={routePaths.enrollment.student(':schoolId', ':studentId')}
            element={<SchoolStudentEnrollmentDocuments />}
          />
          <Route path={routePaths.rooms.all} element={<AllRoomsList />} />
          <Route
            path={routePaths.rooms.specific(':id').edit}
            element={<AllRoomEdit />}
          />
          <Route path={routePaths.scheduleAdd} element={<NewSchedule />} />
          <Route
            path={routePaths.scheduleEdit(':id')}
            element={<ScheduleEdit />}
          />
          <Route
            path={routePaths.notifications.sent}
            element={<SentNotifications />}
          />
          <Route
            path={routePaths.notifications.received}
            element={<ReceivedNotifications />}
          />
          <Route
            path={routePaths.notifications.item(':id')}
            element={<SentNotificationsItem />}
          />
          <Route
            path={routePaths.organiser.tournaments.all}
            element={<Tournaments />}
          />
          <Route
            path={routePaths.admin.tournaments.all}
            element={<AdminTournamentsList />}
          />
          <Route
            path={routePaths.organiser.tournaments.specific(':id').edit}
            element={<EditTournament />}
          />
          <Route
            path={routePaths.organiser.categories.specific(':id').edit}
            element={<EditTournamentCategory />}
          />
          <Route
            path={routePaths.organiser.categories.all}
            element={<TournamentCategoriesList />}
          />
          <Route
            path={routePaths.admin.categories.all}
            element={<AdminTournamentCategoriesList />}
          />
          <Route
            path={routePaths.admin.students.all}
            element={<AdminTournamentsStudents />}
          />
          <Route
            path={routePaths.organiser.categories.createBulk}
            element={<CreateTournamentCategoryBulk />}
          />
          <Route
            path={routePaths.organizerProfile}
            element={<OrganizerProfile />}
          />
        </Route>
      </Routes>
    </ConfigProvider>
  )
}

export default App
