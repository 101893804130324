import {
  SchoolEnrollmentDtoStatusEnum,
  SchoolEnrollmentViewDtoStatusEnum,
  StudentDocumentViewDtoStatusEnum,
  StudentGroupEnrollmentDtoStatusEnum,
  StudentGroupEnrollmentViewDtoStatusEnum,
  StudentSchoolEnrollmentStatusDtoStatusEnum,
} from '@/openapi-api/api'
import { Badge } from 'antd'
import { TFunction } from 'i18next'

export const renderStatusBadge = (
  enrollStatus:
    | SchoolEnrollmentDtoStatusEnum
    | StudentSchoolEnrollmentStatusDtoStatusEnum
    | SchoolEnrollmentViewDtoStatusEnum
    | StudentDocumentViewDtoStatusEnum
    | StudentGroupEnrollmentViewDtoStatusEnum
    | StudentGroupEnrollmentDtoStatusEnum,
  t: TFunction,
) => {
  switch (enrollStatus) {
    case SchoolEnrollmentDtoStatusEnum.DOCUMENTS_REQUESTED:
      return <Badge status="warning" text={t`invite.requested`} />
    case SchoolEnrollmentDtoStatusEnum.ACCEPTED:
      return <Badge status="success" text={t`invite.accepted`} />
    case SchoolEnrollmentDtoStatusEnum.REJECTED:
      return <Badge status="error" text={t`invite.error`} />
    default:
      return <Badge status="warning" text={t`invite.requested`} />
  }
}
