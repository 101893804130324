import { FormInput } from '@components/Form/FormInput'
import { Button, Flex, Form, Typography } from 'antd'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginViewProps } from '@pages/login/types'
import useApi from '@/contexts/api'
import { isAxiosError } from 'axios'
import { EMAIL_REGEXP } from '@/utils/constants'

export const ForgotPasswordView: FC<LoginViewProps> = ({ changeLayout }) => {
  const [formStep, setFormStep] = useState(true)
  const { loginControllerApi } = useApi()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const handleOk = useCallback(
    async ({ login }: { login: string }) => {
      try {
        await loginControllerApi.initPasswordReset({ email: login })
        setFormStep(false)
      } catch (e) {
        if (isAxiosError(e)) {
          form.setFields([{ name: 'login', errors: [t`smthWentWrong`] }])
        }
      }
    },
    [form, loginControllerApi, t],
  )

  return formStep ? (
    <Form
      layout="vertical"
      onFinish={handleOk}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      form={form}
      size="large"
    >
      <Typography.Text
        style={{
          padding: '8px 0',
          marginBottom: '16px',
          fontSize: '16px',
          whiteSpace: 'pre-line',
          textAlign: 'center',
        }}
      >{t`login.forgotPasswordDescription`}</Typography.Text>
      <FormInput
        label={t`email/login`}
        name="login"
        rules={[
          { required: true, message: t`required` },
          {
            pattern: new RegExp(EMAIL_REGEXP),
            message: t`wrongEmail`,
          },
        ]}
      />
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        style={{
          width: '100%',
          marginBottom: '16px',
          marginTop: '8px',
        }}
      >
        {t`login.forgotPasswordAction`}
      </Button>
      <Flex justify="center" style={{ marginTop: '8px' }}>
        <Button
          type="link"
          size="large"
          onClick={() => changeLayout('login')}
        >{t`login.backToLogin`}</Button>
      </Flex>
    </Form>
  ) : (
    <div>
      <Typography.Text
        style={{
          padding: '8px 0',
          fontSize: '16px',
          whiteSpace: 'pre-line',
          textAlign: 'center',
          display: 'block',
        }}
      >{t`login.forgotPasswordSuccess`}</Typography.Text>
      <Flex justify="center" style={{ marginTop: '8px' }}>
        <Button
          type="link"
          size="large"
          onClick={() => changeLayout('login')}
        >{t`login.backToLogin`}</Button>
      </Flex>
    </div>
  )
}
