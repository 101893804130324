import { Avatar, Button, Flex, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { PlaceDto, SchoolDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { filterOptionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { GlobalOutlined } from '@ant-design/icons'
import { useGetSchools } from '@/utils/hooks/entities'

const PAGE_SIZE = 6

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.schoolId?.length) {
    query.push(`school.id~in~${filters.schoolId.toString()}`)
  }
  if (filters?.address?.length) {
    query.push(`address~~${filters.address[0]}`)
  }
  return query.join(';') || undefined
}

const LocationsList = () => {
  const { t } = useTranslation()
  const { placesAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const schools = useGetSchools()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)
  const searchColumn = useSearchColumn<SchoolDto>()

  const { resource: locations } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        placesAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams),
        ),
      [placesAdminControllerApi, tableParams],
    ),
  })

  let columns: ColumnsType<PlaceDto>
  columns = useMemo(
    () => [
      {
        title: t`nazwa`,
        dataIndex: 'name',
        sorter: true,
        ...searchColumn('name'),
        filteredValue: tableParams.filters?.name || null,
        render: (_: string, entity) => (
          <Space size="middle">
            <Avatar
              shape="square"
              size="large"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.thumbLink || <GlobalOutlined />}
            />
            <Typography>{entity.name}</Typography>
          </Space>
        ),
      },
      {
        title: t`adres`,
        dataIndex: 'address',
        sorter: true,
        ...searchColumn('address'),
        filteredValue: tableParams.filters?.address || null,
      },
      {
        title: t`rooms`,
        dataIndex: 'roomCount',
      },
      {
        title: t`school.school`,
        dataIndex: 'schoolId',
        sorter: true,
        filteredValue: tableParams.filters?.schoolId || null,
        filters: filterOptionsFromEntity(schools),
        render: (id: number) => schools?.find((s) => s.id === id)?.name,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={routePaths.locations.specific(entity.id).edit}
            >{t`Edit`}</Link>
            <Link
              to={routePaths.locations.specific(entity.id).rooms.all}
            >{t`rooms`}</Link>
          </Space>
        ),
      },
    ],
    [
      schools,
      searchColumn,
      t,
      tableParams.filters?.address,
      tableParams.filters?.name,
      tableParams.filters?.schoolId,
    ],
  )

  return (
    <Flex vertical>
      <div style={{ marginBottom: '24px' }}>
        <Button
          onClick={() => navigate(routePaths.locations.specific('new').edit)}
        >{t`location.add`}</Button>
      </div>
      <Typography.Title level={5}>{t`locations`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={locations?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: locations?.data.totalElements,
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default LocationsList
