import { Button, Flex, Form, Space, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useCallback, useEffect } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { FormViewMode } from '@components/FormViewMode'
import { genderMapping } from '@/utils/constants'
import { TeacherDtoGenderEnum } from '@/openapi-api/api'
import { renderTagsFromEntity } from '@/utils/functions/renderTagsFromEntity'
import { Separator } from '@components/Separator'
import { addFilterToUrl } from '@/utils/functions/filterUtils'
import { SCHEDULE_TYPES } from '@pages/schedule/types'
import useIsDevice from '@/utils/hooks/useIsMobile'

const TeacherView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { teachersAdminControllerApi } = useApi()
  const { setBreadcrumbs } = useGlobalContext()
  const { isBothMobile } = useIsDevice()
  const { id } = useParams()

  const { resource: entity } = useAsyncResource({
    fetchResource: useCallback(
      () => teachersAdminControllerApi.getById(id as any as number),
      [id, teachersAdminControllerApi],
    ),
  })

  useEffect(() => {
    setBreadcrumbs([
      {
        name: entity?.data.name as string,
        href: '',
      },
    ])
  }, [entity?.data.name, setBreadcrumbs])

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.teachers.all)}
        >
          {t`cancel`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {entity?.data.name}
        </Typography.Title>
      </Space>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        }}
        autoComplete="off"
        labelAlign="left"
      >
        <FormViewMode label={t`image`} type="image">
          {entity?.data.mediaFiles?.[0]?.link}
        </FormViewMode>
        <FormViewMode label={t`teacher.fio`}>{entity?.data.name}</FormViewMode>
        <FormViewMode label={t`teacher.nickname`}>
          {entity?.data.pseudonim}
        </FormViewMode>
        <FormViewMode label={t`teacher.gender`}>
          {t(genderMapping[entity?.data.gender as TeacherDtoGenderEnum])}
        </FormViewMode>
        <FormViewMode label={t`profile.profile`}>
          <Space size="middle">
            {renderTagsFromEntity(entity?.data.profiles)}
          </Space>
        </FormViewMode>
        {entity?.data.specializations && (
          <FormViewMode label={t`teacher.specialization`}>
            <Space size="middle">
              {entity?.data.specializations
                .split(', ')
                .map((s) => <Tag key={s}>{s}</Tag>)}
            </Space>
          </FormViewMode>
        )}
        <FormViewMode label={t`teacher.bio`}>{entity?.data.bio}</FormViewMode>
        <FormViewMode label={t`profile.phone`} type="phone">
          {entity?.data.phone}
        </FormViewMode>
        <FormViewMode label={t`profile.email`} type="email">
          {entity?.data.email}
        </FormViewMode>
        <FormViewMode label={t`teacher.social`} type="link">
          {entity?.data.socialMediaLink}
        </FormViewMode>
        <FormViewMode label={t`settings`}>
          {t(
            entity?.data.loginCredentials ? 'teacher.logOn' : 'teacher.logOff',
          )}
        </FormViewMode>
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button
            type="primary"
            onClick={() =>
              navigate(routePaths.teachers.specific(id as string).edit)
            }
          >
            {t`Edit`}
          </Button>
        </Space>
      </Form>
      <Separator />
      <Flex justify="center">
        <Link
          to={
            routePaths.schedule +
            addFilterToUrl({
              teachers: [entity?.data.id],
              view: isBothMobile ? SCHEDULE_TYPES.DAY : SCHEDULE_TYPES.WEEK,
            })
          }
        >{t`teacher.schedule`}</Link>
      </Flex>
    </Flex>
  )
}

export default TeacherView
