import { Flex, Radio, Space, Tag } from 'antd'
import { Dates, SCHEDULE_TYPES } from '@pages/schedule/types'
import { useEffect, useMemo, useState } from 'react'
import { parseFiltersFormUrl } from '@/utils/functions/filterUtils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useGetAllStudents } from '@/utils/hooks/entities'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { FilterSelect } from '@pages/schedule/FilterSelect'
import useIsMobile from '@/utils/hooks/useIsMobile'
import MyScheduleContent from './MyScheduleContent'

const MySchedule = () => {
  const [view, setView] = useState<SCHEDULE_TYPES>(
    parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY,
  )
  const { isMobile } = useIsMobile()
  const [students, setStudents] = useState<string[]>([])

  const studentOptions = useGetAllStudents()

  const hasFilters = useMemo(() => !!students.length, [students])

  const [dates, setDates] = useState<Dates>(() => {
    const type = parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY
    switch (type) {
      case SCHEDULE_TYPES.DAY:
        return [dayjs().startOf('day'), dayjs().endOf('day')]
      default:
        return [dayjs().startOf('week'), dayjs().endOf('week')]
    }
  })

  useEffect(() => {
    if (view) {
      if (view === SCHEDULE_TYPES.DAY) {
        setDates([dayjs().startOf('day'), dayjs().endOf('day')])
      } else if (view === SCHEDULE_TYPES.WEEK) {
        setDates([dayjs().startOf('week'), dayjs().endOf('week')])
      }
    }
  }, [view])

  const { t } = useTranslation()

  const tagFilters = useMemo(
    () =>
      hasFilters ? (
        <Flex
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr auto',
            marginBottom: '21px',
          }}
        >
          <Space style={{ flexWrap: 'wrap' }}>
            {students.map((studentId) => (
              <Tag
                bordered={false}
                closable
                key={`student-${studentId}`}
                onClose={() =>
                  setStudents((old) => old.filter((id) => id !== studentId))
                }
              >
                {t`student.student`}
                {': '}
                {studentOptions.find(({ id }) => id === +studentId)?.name}
              </Tag>
            ))}
          </Space>
        </Flex>
      ) : null,
    [hasFilters, isMobile, studentOptions, students, t],
  )

  const filters = useMemo(
    () => (
      <Flex
        style={{
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          gap: '8px',
        }}
      >
        <FilterSelect
          label={t`student.student`}
          options={optionsFromEntity(studentOptions)}
          value={students}
          onChange={setStudents}
        />
      </Flex>
    ),
    [isMobile, studentOptions, students, t],
  )

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '16px' }}>{filters}</Flex>
      {tagFilters}
      <Flex justify="flex-end" style={{ marginBottom: '16px' }}>
        <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button
            value={SCHEDULE_TYPES.DAY}
          >{t`schedule.day`}</Radio.Button>
          <Radio.Button
            value={SCHEDULE_TYPES.WEEK}
          >{t`schedule.week`}</Radio.Button>
        </Radio.Group>
      </Flex>
      <MyScheduleContent
        view={view}
        dates={dates}
        setDates={setDates}
        students={students}
      />
    </Flex>
  )
}

export default MySchedule
