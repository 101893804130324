import { Flex, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { PaymentDto, SchoolDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import dayjs from 'dayjs'
import { useTableFilters } from '@/utils/hooks/useTableFilters'
import { routePaths } from '@/routes'
import { TableParams } from '@/utils/types'

const PAGE_SIZE = 7

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.school?.length) {
    query.push(`school.name~~${filters.school[0]}`)
  }
  return query.join(';') || undefined
}

const Payments = () => {
  const { t } = useTranslation()
  const { paymentsAdminControllerApi } = useApi()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: payments } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        paymentsAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          undefined,
          doQuery(tableParams),
        ),
      [paymentsAdminControllerApi, tableParams],
    ),
  })

  const searchColumn = useSearchColumn<PaymentDto>()

  const columns: ColumnsType<PaymentDto> = useMemo(
    () => [
      {
        title: t`payments.menPayments.school`,
        dataIndex: 'school',
        sorter: true,
        ...searchColumn('school'),
        render: (_, entity) => entity.school?.name,
      },
      {
        title: t`payments.menPayments.registrationDate`,
        dataIndex: 'registrationDate',
        sorter: true,
        render: (school: SchoolDto) =>
          dayjs(school?.createdDate).format('DD MMM, YYYY'),
      },
      {
        title: t`payments.menPayments.invoiceDate`,
        dataIndex: 'createdDate',
        sorter: true,
        render: (_, entity) =>
          dayjs(entity?.createdDate).format('DD MMM, YYYY'),
      },
      {
        title: t`payments.menPayments.amount`,
        dataIndex: 'amount',
        sorter: true,
        render: (_, entity) => (
          <Link to={routePaths.payments.specific(entity.id).view}>
            {entity.amount + ' ' + t`currency`}
          </Link>
        ),
      },
      {
        title: t`payments.menPayments.paymentDue`,
        dataIndex: 'paymentDue',
        sorter: true,
        render: (_, entity) => dayjs(entity.dueDate).format('DD MMM, YYYY'),
      },
      {
        title: t`payments.menPayments.status`,
        dataIndex: 'status',
        sorter: true,
      },
      {
        title: t`payments.menPayments.action`,
        dataIndex: 'action',
        render: (_, entity) =>
          entity.paymentLink && (
            <Link to={entity.paymentLink}>
              {t`payments.menPayments.actionLabel`}
            </Link>
          ),
      },
    ],
    [searchColumn, t],
  )

  return (
    <Flex vertical>
      <Typography.Title
        level={5}
      >{t`payments.menPayments.pageTitle`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={payments?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: payments?.data.totalElements,
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default Payments
