import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useCallback } from 'react'

export const useLocaleHelpers = () => {
  const { appLang } = useLanguageContext()
  const getLocaleField = useCallback(
    <T extends object, K extends keyof T>(key: K, data: T): T[K] => {
      // @ts-ignore
      return data?.[`${key.replace('PL', '')}${appLang}`] as T[K]
    },
    [appLang],
  )

  return { getLocaleField }
}
