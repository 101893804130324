import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocalStorageState } from 'ahooks'
export enum LanguagesEnum {
  PL = 'PL',
}
import i18n from '../i18n'
import 'dayjs/locale/pl'
import dayjs from 'dayjs'

export interface IAuthContext {
  appLang: LanguagesEnum
  setApplicationLanguage: (lang: LanguagesEnum) => void
}

const LanguageContext = React.createContext<IAuthContext | undefined>(undefined)

const LanguageProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [lsLang, setLsLang] = useLocalStorageState<LanguagesEnum>('lang')
  const [appLang, setAppLang] = useState<LanguagesEnum>(
    lsLang || LanguagesEnum.PL,
  )

  const setApplicationLanguage = useCallback(
    (l: LanguagesEnum) => {
      setLsLang(l)
      setAppLang(l)
      i18n.changeLanguage(l as never as string)
    },
    [setLsLang],
  )

  useEffect(() => {
    if (appLang) {
      dayjs.locale(appLang)
    }
  }, [appLang])

  let value = {
    appLang,
    setApplicationLanguage,
  }

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguageContext() {
  const context = useContext(LanguageContext)

  if (typeof context === 'undefined') {
    throw new Error('useLanguageContext must be used within an AuthProvider')
  }

  return context
}

export default LanguageProvider
