/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Country } from './country';
// May contain unused imports in some cases
// @ts-ignore
import { LoginCredentials } from './login-credentials';
// May contain unused imports in some cases
// @ts-ignore
import { MediaFileDto } from './media-file-dto';
// May contain unused imports in some cases
// @ts-ignore
import { Profile } from './profile';
// May contain unused imports in some cases
// @ts-ignore
import { SchoolDto } from './school-dto';

/**
 * 
 * @export
 * @interface TeacherDto
 */
export interface TeacherDto {
    /**
     * 
     * @type {number}
     * @memberof TeacherDto
     */
    'id'?: number;
    /**
     * 
     * @type {LoginCredentials}
     * @memberof TeacherDto
     */
    'loginCredentials'?: LoginCredentials;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'pseudonim'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'gender'?: TeacherDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'phone'?: string;
    /**
     * 
     * @type {Country}
     * @memberof TeacherDto
     */
    'country'?: Country;
    /**
     * 
     * @type {Array<Profile>}
     * @memberof TeacherDto
     */
    'profiles'?: Array<Profile>;
    /**
     * 
     * @type {Array<SchoolDto>}
     * @memberof TeacherDto
     */
    'schools'?: Array<SchoolDto>;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'specializations'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherDto
     */
    'socialMediaLink'?: string;
    /**
     * 
     * @type {Array<MediaFileDto>}
     * @memberof TeacherDto
     */
    'mediaFiles'?: Array<MediaFileDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeacherDtoGenderEnum {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
    UNKNOWN = 'unknown'
}


