import { Avatar, Button, Card, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { Text } from '@components/Text'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback } from 'react'
import dayjs from 'dayjs'
import { ScheduleTwoTone, UserOutlined } from '@ant-design/icons'

const Students = () => {
  const { t } = useTranslation()
  const { studentsAdminControllerApi } = useApi()
  const navigate = useNavigate()

  const { resource: students } = useAsyncResource({
    fetchResource: useCallback(
      () => studentsAdminControllerApi.find(0, 1000),
      [studentsAdminControllerApi],
    ),
  })

  return (
    <Flex vertical style={{ backgroundColor: '#F6F6F9' }}>
      <Flex
        style={{ marginBottom: '24px' }}
        align="center"
        justify="space-between"
      >
        <Text type="LG/Strong">{t`student.list`}</Text>
        <Button
          type="primary"
          onClick={() =>
            navigate(routePaths.user.students.specific('new').edit)
          }
        >{t`student.add`}</Button>
      </Flex>
      <Flex vertical gap={16}>
        {students?.data.content?.map((student) => (
          <Card
            hoverable
            style={{ border: '1px solid #D9D9D9', padding: 0 }}
            styles={{ body: { padding: 16 } }}
            key={student.id}
          >
            <Flex justify="space-between">
              <Flex gap="16px">
                <Avatar
                  style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
                  src={student.mediaFiles?.[0]?.thumbLink || <UserOutlined />}
                  shape="square"
                  size={52}
                />

                <Flex vertical justify="space-between">
                  <Link to={routePaths.user.students.specific(student.id).edit}>
                    {student.name}
                  </Link>
                  <Typography.Text>
                    {student.birthDate
                      ? dayjs(student.birthDate).format('DD MMM, YYYY')
                      : ''}
                  </Typography.Text>
                </Flex>
              </Flex>
              <ScheduleTwoTone style={{ fontSize: '24px' }} />
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  )
}

export default Students
