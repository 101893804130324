import { FC, PropsWithChildren } from 'react'
import { Typography } from 'antd'

interface Props extends PropsWithChildren {
  type: 'LG/Strong'
}
export const Text: FC<Props> = ({ children, type }) => {
  switch (type) {
    case 'LG/Strong':
      return (
        <Typography.Text
          style={{ fontSize: '16px', lineHeight: '150%' }}
          strong
        >
          {children}
        </Typography.Text>
      )
    default:
      return <Typography.Text>{children}</Typography.Text>
  }
}
