import { Avatar, Button, Flex, Modal, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useEffect, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { RoomDto, SchoolDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { ArrowLeftOutlined, GlobalOutlined } from '@ant-design/icons'
import { useGlobalContext } from '@/contexts/GlobalContext'

const doQuery = ({ filters }: TableParams, id?: string) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.address?.length) {
    query.push(`address~~${filters.address[0]}`)
  }
  if (id) {
    query.push(`place.id==${id}`)
  }
  return query.join(';') || undefined
}

const RoomsList = () => {
  const { t } = useTranslation()
  const { roomsAdminControllerApi, placesAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { id } = useParams()

  const { tableParams, handleTableChange } = useTableFilters()
  const searchColumn = useSearchColumn<SchoolDto>()

  const { resource: locations, fetch: refresh } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        roomsAdminControllerApi.find(
          0,
          1000,
          doSort(tableParams),
          doQuery(tableParams, id),
        ),
      [id, roomsAdminControllerApi, tableParams],
    ),
  })

  const { setBreadcrumbs } = useGlobalContext()

  const { resource: location } = useAsyncResource({
    fetchResource: useCallback(
      () => placesAdminControllerApi.getById(id as any as number),
      [id, placesAdminControllerApi],
    ),
  })

  useEffect(() => {
    setBreadcrumbs([
      {
        name: location?.data.name as string,
        href: '',
      },
    ])
  }, [location?.data.name, setBreadcrumbs])

  const columns: ColumnsType<RoomDto> = useMemo(
    () => [
      {
        title: t`nazwa`,
        dataIndex: 'name',
        sorter: true,
        ...searchColumn('name'),
        render: (_: string, entity) => (
          <Space size="middle">
            <Avatar
              shape="square"
              size="large"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.thumbLink || <GlobalOutlined />}
            />
            <Typography>{entity.name}</Typography>
          </Space>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (i, entity) => (
          <Space size="middle">
            <Link
              to={
                routePaths.locations.specific(id).rooms.specific(entity.id).edit
              }
            >{t`Edit`}</Link>
            <a
              onClick={() => {
                Modal.confirm({
                  content: t`deleteConfirm`,
                  onOk: async () => {
                    await roomsAdminControllerApi.deleteById(
                      entity.id as number,
                    )
                    await refresh()
                  },
                })
              }}
            >{t`delete`}</a>
          </Space>
        ),
      },
    ],
    [id, refresh, roomsAdminControllerApi, searchColumn, t],
  )

  return (
    <Flex vertical>
      <div
        style={{
          marginBottom: '24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.locations.all)}
        >
          {t`back`}
        </Button>
        <Button
          onClick={() =>
            navigate(
              routePaths.locations.specific(id).rooms.specific('new').edit,
            )
          }
        >{t`room.add`}</Button>
      </div>
      <Typography.Title level={5}>{t`rooms`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={locations?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: locations?.data.totalElements,
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default RoomsList
