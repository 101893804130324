import { Button, Flex, Space, Typography } from 'antd'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { SessionDto } from '@/openapi-api/api'
import dayjs from 'dayjs'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { groupAndSortSessions } from '@pages/schedule/helpers'
import { Dates } from '@pages/schedule/types'
import { UserSessionCard } from '@pages/schedule/UserSessionCard'
import { Here } from '@pages/schedule/Here'

interface Props {
  sessions: SessionDto[]
  dates: Dates
  setDates: (dates: Dates) => void
  scheduleUser?: (session: SessionDto) => Promise<void>
  isUserView?: boolean
}

const UserScheduleWeekView: FC<Props> = ({
  sessions,
  dates,
  setDates,
  scheduleUser,
}) => {
  const dataToRender = useMemo(() => groupAndSortSessions(sessions), [sessions])

  const detectCurrentTime = useCallback(
    (current?: string, next?: string, id?: number) => {
      if (!dayjs(current).isToday()) return false
      const currentTime = dayjs()

      if (current && !next && currentTime.isAfter(dayjs(current))) return true

      return (
        currentTime.isAfter(dayjs(current)) && currentTime.isBefore(dayjs(next))
      )
    },
    [],
  )

  return (
    <Flex vertical>
      <Flex justify="center" style={{ marginBottom: '16px' }}>
        <Space align="center" style={{ justifyContent: 'center' }} size="large">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              setDates([
                dates[0].subtract(7, 'days'),
                dates[1].subtract(7, 'days'),
              ])
            }
          />
          <Typography.Title level={4} style={{ margin: 0 }}>
            {dates[0].format('DD.MM')}
            {' - '}
            {dates[1].format('DD.MM')}
          </Typography.Title>
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() =>
              setDates([dates[0].add(7, 'days'), dates[1].add(7, 'days')])
            }
          />
        </Space>
      </Flex>
      {dataToRender.map(({ sessions, date }) => (
        <Flex key={date} vertical gap={8} style={{ marginBottom: '8px' }}>
          <Typography.Text type="secondary">
            {dayjs(date).format('DD MMMM, dddd')}
          </Typography.Text>
          {sessions.map((session, i) => (
            <Fragment key={session.id}>
              <UserSessionCard session={session} scheduleUser={scheduleUser} />
              {detectCurrentTime(
                session.startTime,
                sessions[i + 1]?.startTime,
                session.id,
              ) && <Here />}
            </Fragment>
          ))}
        </Flex>
      ))}
    </Flex>
  )
}

export default UserScheduleWeekView
