import { MediaFileDto } from '@/openapi-api/api'
import { parsePhoneNumber } from 'libphonenumber-js'

export const extractAvatar = <T extends { mediaFiles?: MediaFileDto[] }>(
  entity?: T,
) => entity?.mediaFiles?.[0]?.link

export const getValue = (data?: any, asNumber?: boolean) => {
  const item = data?.value || data
  return item ? (asNumber ? Number(item) : String(item)) : ''
}

export function formatPhoneNumber(phoneNumber?: string) {
  const phoneNumberObj = parsePhoneNumber(phoneNumber ? `+${phoneNumber}` : '')
  if (phoneNumberObj) {
    const countryCallingCode = phoneNumberObj.countryCallingCode // Код страны
    const nationalNumber = phoneNumberObj.formatNational() // Национальный номер без кода страны

    // Форматирование номера с кодом страны в скобках
    return `(${countryCallingCode}) ${nationalNumber}`
  }

  return phoneNumber
}
