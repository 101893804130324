import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback } from 'react'

export const useGetProfiles = () => {
  const { profilesAdminControllerApi } = useApi()

  const { resource: profiles } = useAsyncResource({
    fetchResource: useCallback(
      () => profilesAdminControllerApi.find(),
      [profilesAdminControllerApi],
    ),
  })

  return profiles?.data.content || []
}

export const useGetSpecializations = (profileIds: string[] | string) => {
  const { specializationsAdminControllerApi } = useApi()

  const { resource: specializations } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!profileIds.length) {
        return { data: { content: [] } }
      }
      return specializationsAdminControllerApi.find(
        0,
        1000,
        undefined,
        `profile.id~in~${profileIds.toString()}`,
      )
    }, [profileIds, specializationsAdminControllerApi]),
  })

  return specializations?.data.content || []
}

export const useGetSchools = () => {
  const { schoolsAdminControllerApi } = useApi()

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return schoolsAdminControllerApi.find(0, 1000)
    }, [schoolsAdminControllerApi]),
  })

  return schools?.data.content || []
}

export const useGetTournaments = () => {
  const { tournamentsAdminControllerApi } = useApi()

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return tournamentsAdminControllerApi.find(0, 1000)
    }, [tournamentsAdminControllerApi]),
  })

  return schools?.data.content || []
}

export const useGetTeachers = (schoolsId: string[] | string) => {
  const { teachersAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!schoolsId.length) {
        return { data: { content: [] } }
      }
      return teachersAdminControllerApi.find(
        0,
        1000,
        undefined,
        `schools.id$~$${schoolsId.toString()}`,
      )
    }, [schoolsId, teachersAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetLocations = (schoolsId: string[] | string) => {
  const { placesAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!schoolsId.length) {
        return { data: { content: [] } }
      }
      return placesAdminControllerApi.find(
        0,
        1000,
        undefined,
        `school.id~in~${schoolsId.toString()}`,
      )
    }, [schoolsId, placesAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetAllLocations = () => {
  const { placesAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return placesAdminControllerApi.find(0, 1000, undefined)
    }, [placesAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetRooms = (locationsId: string[] | string) => {
  const { roomsAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      if (!locationsId.length) {
        return { data: { content: [] } }
      }
      return roomsAdminControllerApi.find(
        0,
        1000,
        undefined,
        `place.id~in~${locationsId.toString()}`,
      )
    }, [locationsId, roomsAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetAllRooms = () => {
  const { roomsAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return roomsAdminControllerApi.find(0, 1000, undefined)
    }, [roomsAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetAllTeachers = () => {
  const { teachersAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return teachersAdminControllerApi.find(0, 1000, undefined)
    }, [teachersAdminControllerApi]),
  })

  return teachers?.data.content || []
}

export const useGetAllGroups = () => {
  const { studentGroupsAdminControllerApi } = useApi()

  const { resource: groups } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return studentGroupsAdminControllerApi.find(0, 1000, undefined)
    }, [studentGroupsAdminControllerApi]),
  })

  return groups?.data.content || []
}

export const useGetAllStudents = () => {
  const { studentsAdminControllerApi } = useApi()

  const { resource: students } = useAsyncResource({
    fetchResource: useCallback(
      () => studentsAdminControllerApi.find(0, 1000),
      [studentsAdminControllerApi],
    ),
  })

  return students?.data.content || []
}

export const useGetCities = () => {
  const { citiesAdminControllerApi } = useApi()

  const { resource: teachers } = useAsyncResource({
    fetchResource: useCallback(async () => {
      return citiesAdminControllerApi.find(0, 1000, undefined)
    }, [citiesAdminControllerApi, fetch]),
  })

  return teachers?.data.content || []
}
