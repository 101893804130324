import styles from '@/utils/hooks/index.module.less'
import { Select, SelectProps } from 'antd'
import { FC } from 'react'
import useIsMobile from '@/utils/hooks/useIsMobile'

interface Props extends SelectProps {
  label: string
}

export const FilterSelect: FC<Props> = ({ label, ...rest }) => {
  const { isMobile } = useIsMobile()
  return (
    <Select
      className={styles.selectColumns}
      mode="multiple"
      style={{ width: isMobile ? '100%' : '200px' }}
      placeholder={label}
      maxTagCount={0}
      filterOption={(input, option) =>
        ((option?.label as string) ?? '')
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      tagRender={() => <span style={{ marginLeft: '8px' }}>{label}</span>}
      {...rest}
    />
  )
}
