import {
  SchoolViewDto,
  StudentDocumentViewDto,
  StudentSchoolEnrollmentStatusDtoStatusEnum,
  StudentViewDto,
} from '@/openapi-api/api'
import { Avatar, Button, Card, Flex, Tabs, Typography, Upload } from 'antd'
import { FC, useState } from 'react'
import Meta from 'antd/es/card/Meta'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { routePaths } from '@/routes'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'
import useApi from '@/contexts/api'
import { getBase64ToUpload } from '@components/Uploader'
import { PaperClipOutlined } from '@ant-design/icons'

interface Props {
  docs?: StudentDocumentViewDto[]
  student?: StudentViewDto
  school?: SchoolViewDto
  status?: StudentSchoolEnrollmentStatusDtoStatusEnum
  refresh: () => Promise<void>
}

export const StudentEnrollment: FC<Props> = ({
  student,
  docs,
  school,
  refresh,
  status,
}) => {
  const { t } = useTranslation()
  const { documentsAdminControllerApi } = useApi()

  const [loading, setLoading] = useState<number[]>([])
  return (
    <Tabs
      defaultActiveKey={String(student?.id)}
      type="card"
      className="tabs-fill-width"
      items={[
        {
          label: student?.name,
          key: String(student?.id),
          children: (
            <Card
              style={{
                marginTop: '-16px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              }}
              actions={[
                <Flex
                  key={`${student?.id}`}
                  vertical
                  style={{ paddingLeft: '16px' }}
                  justify="flex-start"
                  align="flex-start"
                >
                  <Typography.Text>{t`school.school`}</Typography.Text>
                  <Link
                    to={routePaths.schoolInvite(school?.id)}
                    style={{ color: '#1677ff', textAlign: 'left' }}
                  >
                    {school?.name}
                  </Link>
                </Flex>,
                <Flex
                  key={`${student?.id}-${school?.id}-status`}
                  vertical
                  style={{ paddingLeft: '16px' }}
                  justify="flex-start"
                  align="flex-start"
                >
                  <Typography.Text
                    style={{ paddingLeft: '14px' }}
                  >{t`invite.status`}</Typography.Text>
                  {renderStatusBadge(
                    status as StudentSchoolEnrollmentStatusDtoStatusEnum,
                    t,
                  )}
                </Flex>,
              ]}
            >
              <Meta
                avatar={
                  <Avatar
                    src={student?.thumbLink}
                    shape="square"
                    style={{ width: '52px', height: '53px' }}
                  />
                }
                title={student?.name}
                description={
                  student?.birthDate
                    ? dayjs(student?.birthDate).format('DD MMM, YYYY')
                    : ''
                }
              />
            </Card>
          ),
        },
        {
          label: t`documents`,
          key: String(student?.id + ' documents'),
          children: (
            <Card
              style={{
                marginTop: '-16px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              }}
            >
              <Flex vertical gap={16}>
                {docs?.map((doc) => (
                  <Flex
                    key={doc.id}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto 1fr',
                      gap: '24px',
                    }}
                  >
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>
                      {doc.name}
                    </Typography.Text>
                    {!doc.mediaFile ? (
                      <div style={{ justifySelf: 'flex-end' }}>
                        <Upload
                          showUploadList={false}
                          beforeUpload={async (file) => {
                            setLoading((old) => [...old, doc.id as number])
                            const parsedFile = await getBase64ToUpload(
                              file,
                              true,
                            )
                            const { data } =
                              await documentsAdminControllerApi.create({
                                schoolEnrollmentId: doc.schoolEnrollment
                                  ?.id as number,
                                documentTypeId: doc.documentType?.id as number,
                              })
                            await documentsAdminControllerApi.createMediaFile(
                              data.id as number,
                              {
                                fileContent: parsedFile as any,
                                fileName: file.name,
                              },
                            )
                            await refresh()
                            setLoading((old) =>
                              old.filter((id) => id !== doc.id),
                            )
                            return false
                          }}
                        >
                          <Button
                            loading={loading.includes(doc.id as number)}
                            disabled={loading.includes(doc.id as number)}
                          >{t`enrollment.docAdd`}</Button>
                        </Upload>
                      </div>
                    ) : (
                      <Flex
                        style={{ width: 'auto', justifyContent: 'flex-end' }}
                      >
                        <PaperClipOutlined
                          style={{
                            fontSize: '22px',
                            color: 'rgba(0, 0, 0, 0.45)',
                          }}
                        />
                        <Button
                          type="link"
                          loading={loading.includes(doc.id as number)}
                          disabled={loading.includes(doc.id as number)}
                          onClick={async () => {
                            setLoading((old) => [...old, doc.id as number])
                            const { data } =
                              await documentsAdminControllerApi.getDownloadLink(
                                doc.mediaFile?.entityId as number,
                                doc.mediaFile?.id as number,
                              )
                            window.open(data, '_blank')
                            setLoading((old) =>
                              old.filter((id) => id !== doc.id),
                            )
                            return false
                          }}
                          style={{
                            height: '22px',
                            padding: 0,
                            marginLeft: '8px',
                          }}
                        >
                          {doc.mediaFile?.fileName}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Card>
          ),
        },
      ]}
    />
  )
}
