import { useLayoutEffect, useState } from 'react'
import { debounce } from 'lodash'

const useIsDevice = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1440,
  )

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < 768)
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1440)
    }
    window.addEventListener('resize', debounce(updateSize, 250))
    return (): void => window.removeEventListener('resize', updateSize)
  }, [])

  return { isMobile, isTablet, isBothMobile: isTablet || isMobile }
}

export default useIsDevice
