import {
  Button,
  DatePicker,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TimePicker,
} from 'antd'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'

import { FC, useCallback, useMemo, useState } from 'react'
import { useFormReset } from '@/utils/hooks/useFormReset'
import {
  CreateSessionRequest,
  UpdateSessionRequestUpdateTypeEnum,
} from '@/openapi-api/api'
import {
  useGetAllLocations,
  useGetProfiles,
  useGetRooms,
  useGetSpecializations,
  useGetTeachers,
} from '@/utils/hooks/entities'
import { getValue } from '@/utils/functions/dataUtils'
import { startDateInFuture } from '@/utils/functions/validators'
import { FormInput } from '@components/Form/FormInput'
import {
  optionsFromEntity,
  optionsFromEntityNoId,
} from '@/utils/functions/optionsFromEntity'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { FormColorPicker } from '@components/Form/FormColorPicker'
import { FormAvatar } from '@components/Form/FormAvatar'
import { getBase64ToUpload } from '@components/Uploader'
import { useNotificationContext } from '@/contexts/NotificationProvider'
import { routePaths } from '@/routes'
import { useNavigate } from 'react-router-dom'
import { disableDatesInPast } from '@/utils/functions/dateUtils'

interface Props {
  defaultValues?: any
}

const SingleSchedule: FC<Props> = ({ defaultValues }) => {
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sessionsAdminControllerApi } = useApi()
  const [form] = Form.useForm()
  const locationId = Form.useWatch('locationId', form)
  const { appLang } = useLanguageContext()

  const locations = useGetAllLocations()
  const schoolId = useMemo(() => {
    if (!locationId) return
    return locations.find(({ id }) => +locationId === id)?.schoolId
  }, [locationId, locations])
  const profileId = Form.useWatch('profileId', form)
  const teachers = useGetTeachers(schoolId ? String(schoolId) : '')
  const rooms = useGetRooms(getValue(locationId) as string)
  const profiles = useGetProfiles()
  const specializations = useGetSpecializations(getValue(profileId) as string)
  const { info } = useNotificationContext()

  const uploadAvatar = useCallback(
    async (value: any, entityId: number) => {
      if (value.avatar?.length && !value.avatar[0].url) {
        const file = await getBase64ToUpload(value.avatar[0], true)

        if (!defaultValues?.avatar?.length) {
          await sessionsAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: value.avatar[0].name,
          })
        } else {
          await sessionsAdminControllerApi.updateFile(
            entityId,
            defaultValues?.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: value.avatar[0].name,
            },
          )
        }
      }
      if (!value.avatar?.length && defaultValues?.avatar?.length) {
        await sessionsAdminControllerApi.deleteMediaFile(
          entityId,
          defaultValues?.avatar[0].uid as number,
        )
      }
    },
    [defaultValues?.avatar, sessionsAdminControllerApi],
  )

  const onFinish: FormProps<any>['onFinish'] = async (value) => {
    setSubmitting(true)
    const startTime = `${value.generalRange.format(
      'YYYY-MM-DD',
    )}T${value.timeRange[0].format('HH:mm:ss')}`

    try {
      let entityId = +(defaultValues?.id || 0)

      const dataToSend: CreateSessionRequest = {
        [`name${appLang}`]: value.name,
        [`description${appLang}`]: value.description,
        roomId: getValue(value.roomId, true) as number,
        teacherId: getValue(value.teacherId, true) as number,
        startTime,
        duration: value.timeRange[1].diff(value.timeRange[0], 'minute'),
        capacity: value.capacity,
        profileId: getValue(value.profileId) as number,
        specialization: value.specialization?.join(', '),
        color: value.color,
      }
      if (defaultValues?.id) {
        if (!defaultValues.groupId) {
          await sessionsAdminControllerApi.update(entityId, dataToSend)
        } else {
          let selectedRadio = UpdateSessionRequestUpdateTypeEnum.SINGLE

          const { destroy } = Modal.confirm({
            title: t`schedule.chooseVariant`,
            icon: null,
            content: (
              <Flex vertical>
                <Radio.Group
                  onChange={(e) => {
                    selectedRadio = e.target.value
                  }}
                  name="type"
                  defaultValue={UpdateSessionRequestUpdateTypeEnum.SINGLE}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={UpdateSessionRequestUpdateTypeEnum.SINGLE}>
                    {t`schedule.toOneSession`}
                  </Radio>
                  <Radio value={UpdateSessionRequestUpdateTypeEnum.ALL}>
                    {t`schedule.toAllSession`}
                  </Radio>
                  <Radio value={UpdateSessionRequestUpdateTypeEnum.ONGOING}>
                    {t`schedule.toOngoingSession`}
                  </Radio>
                </Radio.Group>
              </Flex>
            ),
            onOk: async () => {
              destroy()
              await sessionsAdminControllerApi.update(entityId, {
                ...dataToSend,
                updateType: selectedRadio,
              })
              await uploadAvatar(value, entityId)
              navigate(routePaths.schedule)
            },
          })
          return
        }
      } else {
        const response = await sessionsAdminControllerApi.create(dataToSend)
        entityId = response.data.id as number
      }
      await uploadAvatar(value, entityId)
      navigate(routePaths.schedule)
    } catch (e) {
      const message = (e as any)?.response?.data?.message
      if (message) {
        info({ message })
        return
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  return (
    <Flex vertical>
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: '480px',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        initialValues={defaultValues}
      >
        <Flex
          style={{
            marginLeft: '25%',
            display: 'grid',
            justifyContent: 'space-between',
            width: '75%',
            gridTemplateColumns: '50% 50%',
          }}
        >
          <Form.Item
            name={'generalRange'}
            rules={[
              { required: true, message: t`required` },
              {
                validator: startDateInFuture,
                message: t`validation.startDateInFuture`,
              },
            ]}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 23 }}
          >
            <DatePicker needConfirm={false} disabledDate={disableDatesInPast} />
          </Form.Item>
          <Form.Item
            name="timeRange"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 23 }}
            rules={[{ required: true, message: t`required` }]}
          >
            <TimePicker.RangePicker
              minuteStep={5}
              showHour
              showMinute
              needConfirm={false}
              placeholder={[t`event.start`, t`event.end`]}
            />
          </Form.Item>
        </Flex>
        <FormInput
          label={t`group.name`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <Form.Item name="description" label={t`group.description`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item
          name="profileId"
          label={t`profile.profile`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
            },
          ]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(profiles)}
          />
        </Form.Item>
        <Form.Item name="specialization" label={t`group.specialization`}>
          <Select
            mode="tags"
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntityNoId(specializations)}
          />
        </Form.Item>
        <Form.Item
          name="locationId"
          label={t`location.location`}
          rules={[{ required: true, message: t`required` }]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(locations)}
          />
        </Form.Item>
        <Form.Item
          name="roomId"
          label={t`room.room`}
          rules={[{ required: true, message: t`required` }]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(rooms)}
          />
        </Form.Item>
        <Form.Item
          name="teacherId"
          label={t`group.teacher`}
          rules={[{ required: true, message: t`required` }]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(teachers)}
          />
        </Form.Item>
        <Form.Item label={t`group.maxStudents`} name="capacity">
          <Input type="number" />
        </Form.Item>
        <FormAvatar label={t`image`} form={form} />
        <FormColorPicker label={t`group.pickColor`} form={form} />
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default SingleSchedule
