/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { SchoolExtendedDto } from './school-extended-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StudentExtendedDto } from './student-extended-dto';

/**
 * 
 * @export
 * @interface CreateSchoolEnrollmentResponse
 */
export interface CreateSchoolEnrollmentResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateSchoolEnrollmentResponse
     */
    'id'?: number;
    /**
     * 
     * @type {SchoolExtendedDto}
     * @memberof CreateSchoolEnrollmentResponse
     */
    'school'?: SchoolExtendedDto;
    /**
     * 
     * @type {StudentExtendedDto}
     * @memberof CreateSchoolEnrollmentResponse
     */
    'student'?: StudentExtendedDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolEnrollmentResponse
     */
    'status'?: CreateSchoolEnrollmentResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSchoolEnrollmentResponseStatusEnum {
    DOCUMENTS_REQUESTED = 'documents_requested',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected'
}


