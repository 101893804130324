import { Avatar, Button, Flex, Space, Table, Typography } from 'antd'
import { EnrollmentTabs } from '@pages/enrollments/EnrollmentTabs'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { TableParams } from '@/utils/types'
import { ColumnsType } from 'antd/es/table'
import {
  SchoolEnrollmentViewDto,
  SchoolEnrollmentViewDtoStatusEnum,
  SchoolViewDto,
} from '@/openapi-api/api'
import { Link } from 'react-router-dom'
import { filterOptionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { UserOutlined, PaperClipOutlined } from '@ant-design/icons'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { useGetSchools } from '@/utils/hooks/entities'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useTableDynamicColumns } from '@/utils/hooks/useTableDynamicColumns'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.studentName?.length) {
    query.push(`studentName~~${filters.studentName[0]}`)
  }
  if (filters?.status?.length) {
    query.push(`status~in~${filters.status.toString()}`)
  }
  if (filters?.school?.length) {
    query.push(`schoolId~in~${filters.school.toString()}`)
  }

  return query.join(';') || undefined
}

const PAGE_SIZE = 7

export const SchoolEnrollment = () => {
  const {
    schoolEnrollmentsViewControllerApi,
    schoolEnrollmentsAdminControllerApi,
  } = useApi()
  const { t } = useTranslation()
  const searchColumn = useSearchColumn<SchoolEnrollmentViewDto>()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)
  const schools = useGetSchools()
  const { resource: enrollments, fetch: refreshEnrollments } = useAsyncResource(
    {
      fetchResource: useCallback(
        () =>
          schoolEnrollmentsViewControllerApi.find(
            (tableParams.pagination?.current ?? 1) - 1,
            tableParams.pagination?.pageSize ?? PAGE_SIZE,
            doSort(tableParams, { school: 'schoolName' }),
            doQuery(tableParams),
          ),
        [schoolEnrollmentsViewControllerApi, tableParams],
      ),
    },
  )

  const acceptEnrollment = useCallback(
    async (id?: number) => {
      try {
        await schoolEnrollmentsAdminControllerApi.accept(id as number)
        await refreshEnrollments()
      } catch (error) {
        console.error(error)
      }
    },
    [refreshEnrollments, schoolEnrollmentsAdminControllerApi],
  )

  const rejectEnrollment = useCallback(
    async (id?: number) => {
      try {
        await schoolEnrollmentsAdminControllerApi.reject(id as number, {
          rejectReason: 'reason',
        })
        await refreshEnrollments()
      } catch (error) {
        console.error(error)
      }
    },
    [refreshEnrollments, schoolEnrollmentsAdminControllerApi],
  )

  const columns: ColumnsType<SchoolEnrollmentViewDto> = useMemo(
    () => [
      {
        title: t`teacher.fio`,
        dataIndex: 'studentName',
        sorter: true,
        filteredValue: tableParams.filters?.studentName || null,
        ...searchColumn('studentName'),
        render: (_, entity) => (
          <Space size="middle">
            <Avatar
              shape="circle"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.student?.thumbLink || <UserOutlined />}
            />
            <Flex vertical>
              <Typography>{entity.student?.name}</Typography>
              <Typography>
                {entity.student?.birthDate
                  ? dayjs(entity.student.birthDate).format('DD MMM, YYYY')
                  : ''}
              </Typography>
            </Flex>
          </Space>
        ),
      },
      {
        title: t`enrollment.status`,
        dataIndex: 'status',
        sorter: true,
        filteredValue: tableParams.filters?.status || null,
        filters: [
          {
            text: t`invite.accepted`,
            value: SchoolEnrollmentViewDtoStatusEnum.ACCEPTED,
          },
          {
            text: t`invite.requested`,
            value: SchoolEnrollmentViewDtoStatusEnum.DOCUMENTS_REQUESTED,
          },
          {
            text: t`invite.error`,
            value: SchoolEnrollmentViewDtoStatusEnum.REJECTED,
          },
        ],
        render: (item: SchoolEnrollmentViewDtoStatusEnum) =>
          renderStatusBadge(item, t),
      },
      {
        title: t`school.school`,
        dataIndex: 'school',
        sorter: true,
        filteredValue: tableParams.filters?.school || null,
        filters: filterOptionsFromEntity(schools),
        render: ({ id }: SchoolViewDto) => {
          const school = schools?.find((s) => s.id === id)

          return (
            <Link to={routePaths.schools.specific(school?.id).view}>
              {school?.name}
            </Link>
          )
        },
      },
      {
        title: t`documents`,
        dataIndex: 'docs',
        render: (_, entity) => {
          const link = routePaths.enrollment.student(
            entity.school?.id,
            entity.student?.id,
          )
          return (
            <Space>
              <Link to={link}>
                {entity.documentProvided}
                {'/'}
                {entity.documentRequested}
              </Link>
              <Link to={link}>{t`see`}</Link>
              <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
            </Space>
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => {
          return entity.status ===
            SchoolEnrollmentViewDtoStatusEnum.DOCUMENTS_REQUESTED ? (
            <Space>
              <Button
                onClick={() => acceptEnrollment(entity.id)}
              >{t`accept`}</Button>
              <a onClick={() => rejectEnrollment(entity.id)}>{t`decline`}</a>
            </Space>
          ) : null
        },
      },
    ],
    [
      acceptEnrollment,
      rejectEnrollment,
      schools,
      searchColumn,
      t,
      tableParams.filters,
    ],
  )

  const { control, filteredColumns } = useTableDynamicColumns(columns)

  return (
    <EnrollmentTabs active={routePaths.enrollment.schools}>
      <Flex
        align="center"
        justify="space-between"
        style={{ marginBottom: '8px' }}
      >
        <Typography.Title
          level={5}
          style={{ margin: 0 }}
        >{t`students`}</Typography.Title>
        {control}
      </Flex>
      <Table
        columns={filteredColumns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={enrollments?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: enrollments?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </EnrollmentTabs>
  )
}
