import { useState, useEffect, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

function useUrlState(key: string) {
  const [searchParams, setSearchParams] = useSearchParams()

  const getValueFromUrl = useCallback(() => {
    const param = searchParams.get(key)
    return param ? param.split(',') : []
  }, [searchParams, key])

  const [value, setValue] = useState<string[]>(getValueFromUrl)

  const updateValue = useCallback(
    (newValue: string | string[]) => {
      if (Array.isArray(newValue)) {
        // If an array is passed, replace the current value entirely
        setValue(newValue)
        const newParam = newValue.join(',')
        if (newParam) {
          searchParams.set(key, newParam)
        } else {
          searchParams.delete(key)
        }
        setSearchParams(searchParams)
      } else {
        // If a single value is passed, toggle it in the array
        setValue((prevValue) => {
          const updatedValue = prevValue.includes(newValue)
            ? prevValue.filter((val) => val !== newValue)
            : [...prevValue, newValue]

          const newParam = updatedValue.join(',')
          if (newParam) {
            searchParams.set(key, newParam)
          } else {
            searchParams.delete(key)
          }
          setSearchParams(searchParams)

          return updatedValue
        })
      }
    },
    [key, searchParams, setSearchParams],
  )

  useEffect(() => {
    const currentValue = getValueFromUrl()
    if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
      setValue(currentValue)
    }
  }, [getValueFromUrl, value])

  return [value, updateValue] as const
}

export function useClearSearchParams() {
  const [_, setSearchParams] = useSearchParams()

  const clearSearchParams = () => {
    setSearchParams({})
  }

  return clearSearchParams
}

export default useUrlState
