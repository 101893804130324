import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Modal,
  Radio,
  Space,
  Tag,
  Typography,
} from 'antd'
import Schedule from '@pages/schedule/Schedule'
import { Dates, SCHEDULE_TYPES } from '@pages/schedule/types'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { parseFiltersFormUrl } from '@/utils/functions/filterUtils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { generateCalendar } from '@pages/schedule/helpers'
import { FilterTwoTone, FilterOutlined, UserOutlined } from '@ant-design/icons'
import {
  useGetAllGroups,
  useGetAllLocations,
  useGetAllTeachers,
  useGetProfiles,
  useGetSchools,
} from '@/utils/hooks/entities'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { FilterSelect } from '@pages/schedule/FilterSelect'
import useIsMobile from '@/utils/hooks/useIsMobile'
import {
  CreateStudentGroupEnrollmentResponseStatusEnum,
  SessionDto,
  StudentViewDto,
} from '@/openapi-api/api'
import useApi from '@/contexts/api'
import { routePaths } from '@/routes'
import { useNavigate } from 'react-router-dom'
import useUrlState, { useClearSearchParams } from '@/utils/hooks/useUrlState'

const EnrollToSession: FC<{
  submit: (all: boolean) => void
  close: () => void
}> = ({ submit, close }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(false)
  return (
    <Flex vertical>
      <Checkbox
        checked={value}
        onChange={() => setValue(!value)}
        style={{ marginBottom: '16px' }}
      >
        {t`schedule.allSessions`}
      </Checkbox>
      <Flex justify="flex-end" gap={16}>
        <Button onClick={() => close()}>{t`invite.close`}</Button>
        <Button
          type="primary"
          onClick={() => submit(value)}
        >{t`schedule.enrollStudents`}</Button>
      </Flex>
    </Flex>
  )
}

const UserScheduleViewWrapper = () => {
  const [view, setView] = useState<SCHEDULE_TYPES>(
    parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY,
  )
  const navigator = useNavigate()
  const { isMobile } = useIsMobile()
  const {
    studentGroupEnrollmentsAdminControllerApi,
    schoolEnrollmentsViewControllerApi,
    sessionStudentsAdminControllerApi,
  } = useApi()
  const [open, setOpen] = useState(false)
  const [locations, setLocations] = useUrlState('locations')
  const [teacher, setTeacher] = useUrlState('teachers')
  const [schools, setSchools] = useUrlState('schools')
  const [profile, setProfile] = useUrlState('profile')
  const [group, setGroup] = useUrlState('group')
  const schoolOptions = useGetSchools()
  const locationOptions = useGetAllLocations()
  const teacherOptions = useGetAllTeachers()
  const profileOptions = useGetProfiles()
  const groupOptions = useGetAllGroups()
  const clear = useClearSearchParams()

  const hasFilters = useMemo(
    () =>
      !!(
        schools.length ||
        locations.length ||
        teacher.length ||
        profile.length ||
        group.length
      ),
    [
      group.length,
      profile.length,
      locations.length,
      schools.length,
      teacher.length,
    ],
  )

  const [dates, setDates] = useState<Dates>(() => {
    const type = parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY
    switch (type) {
      case SCHEDULE_TYPES.DAY:
        return [dayjs().startOf('day'), dayjs().endOf('day')]
      case SCHEDULE_TYPES.WEEK:
        return [dayjs().startOf('week'), dayjs().endOf('week')]
      default: {
        const dates = generateCalendar(dayjs().month(), dayjs().year())
        return [dates[0], dates.reverse()[0]]
      }
    }
  })

  useEffect(() => {
    if (view) {
      if (view === SCHEDULE_TYPES.DAY) {
        setDates([dayjs().startOf('day'), dayjs().endOf('day')])
      } else if (view === SCHEDULE_TYPES.WEEK) {
        setDates([dayjs().startOf('week'), dayjs().endOf('week')])
      } else {
        const dates = generateCalendar(dayjs().month(), dayjs().year())
        setDates([dates[0], dates.reverse()[0]])
      }
    }
  }, [view])

  const { t } = useTranslation()

  const tagFilters = useMemo(
    () =>
      hasFilters ? (
        <Flex
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr auto',
            marginBottom: '21px',
            marginTop: isMobile ? '21px' : 0,
          }}
        >
          <Space style={{ flexWrap: 'wrap' }}>
            {!isMobile && <Typography>{t`filters`}</Typography>}
            {schools.map((schoolId) => (
              <Tag
                bordered={false}
                closable
                key={`school-${schoolId}`}
                onClose={() => setSchools(schoolId)}
              >
                {t`school.school`}
                {': '}
                {schoolOptions.find(({ id }) => id === +schoolId)?.name}
              </Tag>
            ))}
            {locations.map((locationId) => (
              <Tag
                bordered={false}
                closable
                key={`location-${locationId}`}
                onClose={() => setLocations(locationId)}
              >
                {t`location.location`}
                {': '}
                {locationOptions.find(({ id }) => id === +locationId)?.name}
              </Tag>
            ))}
            {teacher.map((teacherId) => (
              <Tag
                bordered={false}
                closable
                key={`teacher-${teacherId}`}
                onClose={() => setTeacher(teacherId)}
              >
                {t`group.teacher`}
                {': '}
                {teacherOptions.find(({ id }) => id === +teacherId)?.name}
              </Tag>
            ))}
            {profile.map((profileId) => (
              <Tag
                bordered={false}
                closable
                key={`profile-${profileId}`}
                onClose={() => setProfile(profileId)}
              >
                {t`profile.profile`}
                {': '}
                {profileOptions.find(({ id }) => id === +profileId)?.name}
              </Tag>
            ))}
            {group.map((groupId) => (
              <Tag
                bordered={false}
                closable
                key={`group-${groupId}`}
                onClose={() => setGroup(groupId)}
              >
                {t`group.group`}
                {': '}
                {groupOptions.find(({ id }) => id === +groupId)?.name}
              </Tag>
            ))}
          </Space>
          {!isMobile && <Button onClick={clear}>{t`clearFilter`}</Button>}
        </Flex>
      ) : null,
    [
      hasFilters,
      isMobile,
      t,
      schools,
      locations,
      teacher,
      profile,
      group,
      clear,
      schoolOptions,
      setSchools,
      locationOptions,
      setLocations,
      teacherOptions,
      setTeacher,
      profileOptions,
      setProfile,
      groupOptions,
      setGroup,
    ],
  )

  const filters = useMemo(
    () => (
      <Flex
        style={{
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          gap: '8px',
        }}
      >
        <FilterSelect
          label={t`school.school`}
          options={optionsFromEntity(schoolOptions)}
          value={schools}
          onChange={setSchools}
        />
        <FilterSelect
          label={t`location.location`}
          options={optionsFromEntity(locationOptions)}
          value={locations}
          onChange={setLocations}
        />
        <FilterSelect
          label={t`teachers`}
          options={optionsFromEntity(teacherOptions)}
          value={teacher}
          onChange={setTeacher}
        />
        <FilterSelect
          label={t`profile.profile`}
          options={optionsFromEntity(profileOptions)}
          value={profile}
          onChange={setProfile}
        />
        <FilterSelect
          label={t`groups`}
          options={optionsFromEntity(groupOptions)}
          value={group}
          onChange={setGroup}
        />
      </Flex>
    ),
    [
      isMobile,
      t,
      schoolOptions,
      schools,
      setSchools,
      locationOptions,
      locations,
      setLocations,
      teacherOptions,
      teacher,
      setTeacher,
      profileOptions,
      profile,
      setProfile,
      groupOptions,
      group,
      setGroup,
    ],
  )

  const handleUserSelect = useCallback(
    async (session: SessionDto, student?: StudentViewDto) => {
      try {
        const { data } = await studentGroupEnrollmentsAdminControllerApi.create(
          {
            groupId: session.studentGroup?.id as number,
            studentId: student?.id as number,
          },
        )
        if (
          data.status ===
          CreateStudentGroupEnrollmentResponseStatusEnum.RESERVED
        ) {
          Modal.info({
            icon: null,
            okText: t`invite.close`,
            content: t`invite.reservedEnroll`,
          })
        } else if (
          data.status ===
          CreateStudentGroupEnrollmentResponseStatusEnum.REQUESTED
        ) {
          Modal.info({
            icon: null,
            okText: t`invite.close`,
            content: t`invite.waitAdminApprove`,
          })
        } else if (
          data.status ===
          CreateStudentGroupEnrollmentResponseStatusEnum.REJECTED
        ) {
          Modal.info({
            icon: null,
            okText: t`invite.close`,
            content: t`invite.rejectedByADmin`,
          })
        } else {
          const { destroy } = Modal.info({
            icon: null,
            okButtonProps: { style: { display: 'none' } },
            content: (
              <EnrollToSession
                close={() => destroy()}
                submit={async (all) => {
                  destroy()
                  try {
                    await sessionStudentsAdminControllerApi.create({
                      sessionId: session.id as number,
                      studentId: student?.id as number,
                      applyToAllGroupSessions: all,
                    })
                    Modal.info({
                      title: t`enrollment.successSessionEnroll`,
                    })
                  } catch {
                    Modal.error({
                      title: t`login.activationError`,
                    })
                  }
                }}
              />
            ),
          })
        }
      } catch (e) {}
    },
    [
      sessionStudentsAdminControllerApi,
      studentGroupEnrollmentsAdminControllerApi,
      t,
    ],
  )

  const scheduleUser = useCallback(
    async (session: SessionDto) => {
      if (session.studentGroup) {
        const { data } = await schoolEnrollmentsViewControllerApi.find(
          0,
          1000,
          undefined,
          `status~in~accepted;schoolId~in~${session.studentGroup.school?.id}`,
        )
        if (data.content?.length) {
          const { destroy } = Modal.info({
            icon: null,
            content: (
              <Flex vertical gap={8}>
                {data.content?.map(({ student }) => (
                  <Flex
                    key={student?.id}
                    style={{
                      border: '1px solid #D9D9D9',
                      borderRadius: '8px',
                      padding: '8px',
                    }}
                    justify="space-between"
                    align="center"
                  >
                    <Space size="middle">
                      <Avatar
                        shape="circle"
                        style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
                        src={student?.thumbLink || <UserOutlined />}
                      />
                      <Flex vertical>
                        <Typography>{student?.name}</Typography>
                        <Typography>
                          {student?.birthDate
                            ? dayjs(student.birthDate).format('DD MMM, YYYY')
                            : ''}
                        </Typography>
                      </Flex>
                    </Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        destroy()
                        handleUserSelect(session, student)
                      }}
                    >{t`save`}</Button>
                  </Flex>
                ))}
              </Flex>
            ),
            okText: t`invite.close`,
          })
        } else {
          Modal.confirm({
            icon: null,
            content: (
              <Flex vertical gap={8}>
                <Typography>{t`invite.noStudents`}</Typography>
              </Flex>
            ),
            okText: t`invite.goToSchoolEnroll`,
            cancelText: t`invite.close`,
            onOk: () =>
              navigator(
                routePaths.schoolInvite(
                  session.studentGroup?.school?.id ||
                    session.room?.place?.schoolId,
                ),
              ),
          })
        }
      } else {
        console.warn('Not implemented yet', session)
      }
    },
    [handleUserSelect, navigator, schoolEnrollmentsViewControllerApi, t],
  )

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '21px' }}>
        {isMobile && (
          <Flex justify="space-between">
            <Button
              icon={hasFilters ? <FilterTwoTone /> : <FilterOutlined />}
              ghost={hasFilters}
              type={hasFilters ? 'primary' : undefined}
              onClick={() => setOpen(true)}
            >{t`filters`}</Button>
            <Button onClick={clear}>{t`clearFilter`}</Button>
          </Flex>
        )}
        {!isMobile && filters}
      </Flex>
      {tagFilters}
      <Flex justify="flex-end" style={{ marginBottom: '16px' }}>
        <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button
            value={SCHEDULE_TYPES.DAY}
          >{t`schedule.day`}</Radio.Button>
          <Radio.Button
            value={SCHEDULE_TYPES.WEEK}
          >{t`schedule.week`}</Radio.Button>
        </Radio.Group>
      </Flex>
      <Schedule
        view={view}
        dates={dates}
        schools={schools}
        locations={locations}
        teachers={teacher}
        profiles={profile}
        rooms={[]}
        groups={group}
        setDates={setDates}
        isUserView
        scheduleUser={scheduleUser}
      />
      <Drawer
        placement="right"
        width={'100%'}
        destroyOnClose
        open={open}
        closable={false}
      >
        {filters}
        {tagFilters}
        <Flex justify="space-between" gap="24px" style={{ marginTop: '16px' }}>
          <Button
            onClick={clear}
            style={{ width: '100%' }}
          >{t`clearFilter`}</Button>
          <Button
            style={{ width: '100%' }}
            onClick={() => setOpen(false)}
            type="primary"
          >{t`show`}</Button>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default UserScheduleViewWrapper
