import { Flex, Space, Table, Tooltip, Typography } from 'antd'
import { routePaths } from '@/routes'
import { NotificationTabs } from './NotificationTabs'
import useApi from '@/contexts/api'
import { useTranslation } from 'react-i18next'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { NotificationList } from '@/openapi-api/api'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { TableParams } from '@/utils/types'
import dayjs from 'dayjs'
import { InfoCircleOutlined } from '@ant-design/icons'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.subject?.length) {
    query.push(`subject~~${filters.subject[0]}`)
  }

  return query.join(';') || undefined
}

const PAGE_SIZE = 7

export const SentNotifications = () => {
  const { notificationListsControllerApi } = useApi()

  const { t } = useTranslation()
  const searchColumn = useSearchColumn<NotificationList>()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: notifications, fetch: refreshEnrollments } =
    useAsyncResource({
      fetchResource: useCallback(
        () =>
          notificationListsControllerApi.find(
            (tableParams.pagination?.current ?? 1) - 1,
            tableParams.pagination?.pageSize ?? PAGE_SIZE,
            doSort(tableParams),
            doQuery(tableParams),
          ),
        [notificationListsControllerApi, tableParams],
      ),
    })

  const columns: ColumnsType<NotificationList> = useMemo(
    () => [
      {
        title: t`nazwa`,
        dataIndex: 'subject',
        sorter: true,
        filteredValue: tableParams.filters?.subject || null,
        ...searchColumn('subject'),
        render: (_, entity) => (
          <Link to={routePaths.notifications.item(entity.id)}>
            {entity.subject}
          </Link>
        ),
      },
      {
        title: t`date`,
        dataIndex: 'createdDate',
        sorter: true,
        render: (item: string) => dayjs(item).format('DD MMM, YYYY'),
      },
      {
        title: t`notification.text`,
        dataIndex: 'text',
        sorter: true,
      },
      {
        title: (
          <Space size="small">
            {t`invite.status`}{' '}
            <Tooltip
              placement="rightTop"
              title={t`notification.tooltip`}
              style={{ fontSize: '24px' }}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        ),
        dataIndex: 'status',
        render: (_, entity) =>
          `${entity.recipientsCount}/${entity.receivedCount}/${entity.readCount}`,
      },
      {
        title: t`notifications.groupType`,
        dataIndex: 'typeName',
      },
      {
        title: t`notifications.more`,
        dataIndex: 'typeDetails',
      },
    ],
    [searchColumn, t, tableParams.filters],
  )

  return (
    <NotificationTabs active={routePaths.notifications.sent}>
      <Flex
        align="center"
        justify="space-between"
        style={{ marginBottom: '8px' }}
      >
        <Typography.Title
          level={5}
          style={{ margin: 0 }}
        >{t`notifications.notifications`}</Typography.Title>
      </Flex>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={notifications?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: notifications?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </NotificationTabs>
  )
}
