/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateSessionRequest
 */
export interface UpdateSessionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'roomId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'teacherId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'updateType'?: UpdateSessionRequestUpdateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'status'?: UpdateSessionRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'profileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'namePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'minAge'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSessionRequest
     */
    'maxAge'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'color'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateSessionRequestUpdateTypeEnum {
    SINGLE = 'single',
    ALL = 'all',
    ONGOING = 'ongoing'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateSessionRequestStatusEnum {
    SCHEDULED = 'scheduled',
    CANCELED = 'canceled',
    CONFLICT = 'conflict'
}


