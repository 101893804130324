import React, { useState } from 'react'
import { Form, Tag, Input, Modal } from 'antd'
import { FormInstance } from 'antd/es/form'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface TeamSize {
  teamSizeName: string
  teamMinSize: number
  teamMaxSize: number
}

interface CheckableTagFormFieldProps {
  name: string
  tags: TeamSize[]
  form: FormInstance
  required?: boolean
  label?: string
}

const CheckableTagFormField: React.FC<CheckableTagFormFieldProps> = ({
  name,
  tags,
  form,
  required = false,
  label,
}) => {
  const { t } = useTranslation()
  const currentSelectedTags = Form.useWatch(name, form) || []

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newTeamSize, setNewTeamSize] = useState<TeamSize>({
    teamSizeName: '',
    teamMinSize: NaN, // Используем NaN, чтобы убрать 0
    teamMaxSize: NaN, // Используем NaN, чтобы убрать 0
  })
  const [formInModal] = Form.useForm()

  const handleTagChange = (value: TeamSize, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...currentSelectedTags, value]
      : currentSelectedTags.filter(
          (tag: TeamSize) => tag.teamSizeName !== value.teamSizeName,
        )
    form.setFieldsValue({ [name]: nextSelectedTags })
  }

  const handleAddCustomTag = async () => {
    try {
      await formInModal.validateFields()
      const newTag = {
        teamSizeName: newTeamSize.teamSizeName,
        teamMinSize: newTeamSize.teamMinSize,
        teamMaxSize: newTeamSize.teamMaxSize,
      }

      form.setFieldsValue({
        [name]: [...currentSelectedTags, newTag],
      })

      setIsModalOpen(false) // Закрыть модальное окно
      setNewTeamSize({ teamSizeName: '', teamMinSize: NaN, teamMaxSize: NaN }) // Очистить поля
      formInModal.resetFields()
    } catch (error) {
      // Если валидация не пройдена, ничего не делаем
    }
  }

  const isCustomTag = (tag: TeamSize) => {
    return !tags.some((t) => t.teamSizeName === tag.teamSizeName)
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: t`required`,
          type: 'array',
        },
      ]}
    >
      <>
        {tags.map((tag) => (
          <Tag.CheckableTag
            key={tag.teamSizeName}
            checked={currentSelectedTags.some(
              (selected: TeamSize) =>
                selected.teamSizeName === tag.teamSizeName,
            )}
            onChange={(checked: boolean) => handleTagChange(tag, checked)}
          >
            {tag.teamSizeName}
          </Tag.CheckableTag>
        ))}

        {currentSelectedTags.filter(isCustomTag).map((customTag: TeamSize) => (
          <Tag.CheckableTag
            key={customTag.teamSizeName}
            checked={currentSelectedTags.some(
              (selected: TeamSize) =>
                selected.teamSizeName === customTag.teamSizeName,
            )}
            onChange={(checked: boolean) => handleTagChange(customTag, checked)}
          >
            {`${customTag.teamSizeName} (${customTag.teamMinSize}-${customTag.teamMaxSize})`}{' '}
          </Tag.CheckableTag>
        ))}

        <PlusOutlined
          onClick={() => setIsModalOpen(true)}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
        />

        <Modal
          title={t('tournaments.bulkAddCustomTeamSize')}
          open={isModalOpen}
          onOk={handleAddCustomTag}
          onCancel={() => setIsModalOpen(false)}
        >
          <Form layout="vertical" form={formInModal}>
            <Form.Item
              label={t('tournaments.bulkCustomTeamSizeName')}
              name="teamSizeName"
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <Input
                value={newTeamSize.teamSizeName}
                onChange={(e) =>
                  setNewTeamSize((prev) => ({
                    ...prev,
                    teamSizeName: e.target.value,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              label={t('tournaments.bulkMinSize')}
              name="teamMinSize"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input
                type="number"
                value={
                  isNaN(newTeamSize.teamMinSize) ? '' : newTeamSize.teamMinSize
                }
                onChange={(e) =>
                  setNewTeamSize((prev) => ({
                    ...prev,
                    teamMinSize: Number(e.target.value),
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              label={t('tournaments.bulkMaxSize')}
              name="teamMaxSize"
              rules={[{ required: true, message: t('required') }]}
            >
              <Input
                type="number"
                value={
                  isNaN(newTeamSize.teamMaxSize) ? '' : newTeamSize.teamMaxSize
                }
                onChange={(e) =>
                  setNewTeamSize((prev) => ({
                    ...prev,
                    teamMaxSize: Number(e.target.value),
                  }))
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    </Form.Item>
  )
}

export default CheckableTagFormField
