import { FormInput } from '@components/Form/FormInput'
import { Button, Form, message, Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.less'
import useApi from '@/contexts/api'
import { Layout } from '@pages/login/Layout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routePaths } from '@/routes'

export const ForgotPasswordView = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { loginControllerApi } = useApi()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleOk = useCallback(
    async (formData: any) => {
      const email = searchParams.get('email')
      const code = searchParams.get('code')
      try {
        await loginControllerApi.completePasswordReset({
          email: email as string,
          code: code as string,
          password: formData.password,
        })
        navigate(routePaths.login)
      } catch (e) {
        message.error(t`smthWentWrong`)
      }
    },
    [loginControllerApi, navigate, searchParams, t],
  )

  return (
    <Layout>
      <Typography.Text
        style={{ textAlign: 'center', display: 'block' }}
      >{t`login.resetTitle`}</Typography.Text>
      <Form
        layout="vertical"
        onFinish={handleOk}
        form={form}
        className={styles.registrationWrapper}
        size="large"
      >
        <FormInput
          label={t`password`}
          name="password"
          rules={[
            {
              required: true,
              pattern: new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)'),
            },
            { min: 8, max: 50 },
          ]}
          type="password"
          help={t`login.passwordExtra`}
        />
        <FormInput
          style={{ marginTop: '16px' }}
          label={t`login.repeatPassword`}
          name="rePassword"
          rules={[
            {
              required: true,
              message: t`required`,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t`login.passwordDontMatch`))
              },
            }),
          ]}
          type="password"
        />

        <Button
          type="primary"
          htmlType="submit"
          style={{ marginBottom: '16px', marginTop: '8px' }}
          className={styles.sighInButton}
        >
          {t`login.resetAction`}
        </Button>
      </Form>
    </Layout>
  )
}
