import {
  Button,
  Checkbox,
  Flex,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import { CreateSchoolRequest, SchoolDto } from '@/openapi-api/api'
import { FormAvatar } from '@components/Form/FormAvatar'
import { EMAIL_REGEXP } from '@/utils/constants'

const Profile = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const [initialData, setInitialData] = useState<SchoolDto>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    schoolsAdminControllerApi,
    profilesAdminControllerApi,
    placesAdminControllerApi,
  } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const naturalPerson = Form.useWatch('naturalPerson', form)
  const { refreshTour } = useGlobalContext()

  const { resource: profiles } = useAsyncResource({
    fetchResource: useCallback(
      () => profilesAdminControllerApi.find(),
      [profilesAdminControllerApi],
    ),
  })

  const { resource: schoolsLength } = useAsyncResource({
    fetchResource: useCallback(
      () => schoolsAdminControllerApi.getCount(),
      [schoolsAdminControllerApi],
    ),
  })

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      schoolsAdminControllerApi.getById(+(id || 0)).then(({ data }) => {
        setInitialData(data)
        setFormData({
          address: data.places?.[0].address,
          googleMapsUrl: data.places?.[0].googleMapsUrl,
          name: data.name,
          needRegistrationDocs: data.needRegistrationDocs,
          profileId: data.profiles?.map((p) => p.id),
          description: data.description,
          companyName: data.companyName,
          nip: data.nip,
          regon: data.regon,
          email: data.email,
          phone: data.phone,
          naturalPerson: data.naturalPerson,
          schoolSite: data.schoolSite,
          facebookLink: data.facebookLink,
          instagramLink: data.instagramLink,
          tikTokLink: data.tikTokLink,
          avatar: data.mediaFiles?.[0]
            ? [
                {
                  uid: data.mediaFiles[0].id,
                  status: 'done',
                  url: data.mediaFiles[0].link,
                },
              ]
            : null,
        })
        setReady(true)
      })
    }
  }, [id, schoolsAdminControllerApi])

  const title = useMemo(
    () =>
      formData.name ||
      `${t`school.schoolProfile`} ${(schoolsLength?.data ?? 0) + 1}`,
    [formData.name, schoolsLength?.data, t],
  )

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateSchoolRequest = {
        namePL: values.name,
        needRegistrationDocs: values.needRegistrationDocs,
        profileIds: values.profileId,
        descriptionPL: values.description,
        companyName: values.companyName,
        nip: values.nip,
        regon: values.regon,
        email: values.email,
        phone: values.phone,
        schoolSite: values.schoolSite,
        facebookLink: values.facebookLink,
        instagramLink: values.instagramLink,
        tikTokLink: values.tikTokLink,
        naturalPerson: values.naturalPerson,
      }
      const placeData = {
        addressPL: values.address,
        googleMapsUrl: values.googleMapsUrl,
      }

      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await schoolsAdminControllerApi.create({
          ...dataToSend,
          ...placeData,
        })
        entityId = data.id as number
      } else {
        await placesAdminControllerApi.update(
          initialData.places?.[0].id as number,
          placeData,
        )
        await schoolsAdminControllerApi.update(id as any, dataToSend)
      }
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (id === 'new' || !formData.avatar?.length) {
          await schoolsAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await schoolsAdminControllerApi.updateFile(
            entityId,
            formData.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && formData.avatar?.length) {
        await schoolsAdminControllerApi.deleteMediaFile(
          entityId,
          formData.avatar[0].uid as number,
        )
      }
      if (id === 'new') {
        refreshTour()
        navigate(routePaths.schools.all)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Space size="large" align="center" style={{ marginBottom: '24px' }}>
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() =>
            id !== 'new'
              ? navigate(routePaths.schools.specific(id).view)
              : navigate(routePaths.schools.all)
          }
        >
          {t`cancel`}
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Space>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <FormInput
          label={t`school.name`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <Tooltip placement="left" title={t`school.needDocsTooltip`}>
          <Form.Item
            name="needRegistrationDocs"
            valuePropName="checked"
            wrapperCol={{ offset: 6, span: 18 }}
          >
            <Checkbox>{t`school.needDocs`}</Checkbox>
          </Form.Item>
        </Tooltip>
        <Form.Item name="description" label={t`school.description`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item
          name="profileId"
          label={t`profile.profile`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
              type: 'array',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={t`school.pleaseSelect`}
            options={
              profiles?.data.content?.map(({ id, name }) => ({
                label: name,
                value: id,
              })) || []
            }
            optionFilterProp="label"
          />
        </Form.Item>
        <FormInput
          label={t`school.companyName`}
          name="companyName"
          rules={[
            { required: !naturalPerson, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <Form.Item
          label={t`school.nip`}
          name="nip"
          rules={[
            { required: !naturalPerson, message: t`required` },
            {
              min: 10,
              max: 10,
              message: t`10digits`,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="naturalPerson"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox>{t`school.naturalPerson`}</Checkbox>
        </Form.Item>

        <Form.Item
          label={t`school.regon`}
          name="regon"
          rules={[
            { required: !naturalPerson, message: t`required` },
            {
              min: 1,
              max: 9,
              message: t`max9numbers`,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="address"
          label={t`school.address`}
          rules={[{ required: true, message: t`school.noAddress` }]}
        >
          <Input.TextArea rows={2} />
        </Form.Item>
        <FormPhoneInput label={t`school.phone`} />
        <FormInput
          name="email"
          label={t`school.email`}
          rules={[
            {
              pattern: new RegExp(EMAIL_REGEXP),
              message: t`school.wrongEmail`,
            },
            {
              required: true,
              message: t`required`,
            },
          ]}
        />
        <FormInput
          name="schoolSite"
          label={t`school.site`}
          rules={[
            {
              type: 'url',
              message: t`school.wrongLink`,
            },
          ]}
        />

        <FormInput
          name="googleMapsUrl"
          label={t`school.gmaps`}
          rules={[
            {
              type: 'url',
              message: t`school.wrongLink`,
            },
          ]}
        />
        <FormInput
          name="facebookLink"
          label={t`school.fb`}
          rules={[
            {
              type: 'url',
              message: t`school.wrongLink`,
            },
          ]}
        />
        <FormInput
          name="instagramLink"
          label={t`school.instagram`}
          rules={[
            {
              type: 'url',
              message: t`school.wrongLink`,
            },
          ]}
        />
        <FormInput
          name="tikTokLink"
          label={t`school.tiktok`}
          rules={[
            {
              type: 'url',
              message: t`school.tikTokLink`,
            },
          ]}
        />
        <FormAvatar label={t`schools.image`} form={form} />
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default Profile
