import { Button, Drawer, Flex, Radio, Space, Tag, Typography } from 'antd'
import Schedule from '@pages/schedule/Schedule'
import { Dates, SCHEDULE_TYPES } from '@pages/schedule/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { parseFiltersFormUrl } from '@/utils/functions/filterUtils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { generateCalendar } from '@pages/schedule/helpers'
import { FilterTwoTone, FilterOutlined } from '@ant-design/icons'
import {
  useGetAllGroups,
  useGetAllLocations,
  useGetAllRooms,
  useGetAllTeachers,
  useGetProfiles,
  useGetSchools,
} from '@/utils/hooks/entities'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { FilterSelect } from '@pages/schedule/FilterSelect'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'

const ScheduleViewWrapper = () => {
  const [view, setView] = useState<SCHEDULE_TYPES>(
    parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY,
  )
  const { isMobile } = useIsMobile()
  const [schools, setSchools] = useState<string[]>(
    parseFiltersFormUrl().schools || [],
  )
  const [open, setOpen] = useState(false)
  const [locations, setLocations] = useState<string[]>([])
  const [rooms, setRooms] = useState<string[]>([])
  const [teacher, setTeacher] = useState<string[]>(
    parseFiltersFormUrl().teachers || [],
  )
  const [profile, setProfile] = useState<string[]>([])
  const [group, setGroup] = useState<string[]>([])
  const schoolOptions = useGetSchools()
  const locationOptions = useGetAllLocations()
  const roomOptions = useGetAllRooms()
  const teacherOptions = useGetAllTeachers()
  const profileOptions = useGetProfiles()
  const groupOptions = useGetAllGroups()

  const hasFilters = useMemo(
    () =>
      !!(
        schools.length ||
        rooms.length ||
        locations.length ||
        teacher.length ||
        profile.length ||
        group.length
      ),
    [
      group.length,
      locations.length,
      profile.length,
      rooms.length,
      schools.length,
      teacher.length,
    ],
  )

  const [dates, setDates] = useState<Dates>(() => {
    const type = parseFiltersFormUrl().view || SCHEDULE_TYPES.DAY
    switch (type) {
      case SCHEDULE_TYPES.DAY:
        return [dayjs().startOf('day'), dayjs().endOf('day')]
      case SCHEDULE_TYPES.WEEK:
        return [dayjs().startOf('week'), dayjs().endOf('week')]
      default: {
        const dates = generateCalendar(dayjs().month(), dayjs().year())
        return [dates[0], dates.reverse()[0]]
      }
    }
  })

  useEffect(() => {
    if (view) {
      if (view === SCHEDULE_TYPES.DAY) {
        setDates([dayjs().startOf('day'), dayjs().endOf('day')])
      } else if (view === SCHEDULE_TYPES.WEEK) {
        setDates([dayjs().startOf('week'), dayjs().endOf('week')])
      } else {
        const dates = generateCalendar(dayjs().month(), dayjs().year())
        setDates([dates[0], dates.reverse()[0]])
      }
    }
  }, [view])

  const { t } = useTranslation()
  const navigate = useNavigate()

  const clearFilters = useCallback(() => {
    setRooms([])
    setLocations([])
    setSchools([])
    setGroup([])
    setProfile([])
    setTeacher([])
  }, [])

  const tagFilters = useMemo(
    () =>
      hasFilters ? (
        <Flex
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr auto',
            marginBottom: '21px',
            marginTop: isMobile ? '21px' : 0,
          }}
        >
          <Space style={{ flexWrap: 'wrap' }}>
            {!isMobile && <Typography>{t`filters`}</Typography>}
            {schools.map((schoolId) => (
              <Tag
                bordered={false}
                closable
                key={`school-${schoolId}`}
                onClose={() =>
                  setSchools((old) => old.filter((id) => id !== schoolId))
                }
              >
                {t`school.school`}
                {': '}
                {schoolOptions.find(({ id }) => id === +schoolId)?.name}
              </Tag>
            ))}
            {locations.map((locationId) => (
              <Tag
                bordered={false}
                closable
                key={`location-${locationId}`}
                onClose={() =>
                  setLocations((old) => old.filter((id) => id !== locationId))
                }
              >
                {t`location.location`}
                {': '}
                {locationOptions.find(({ id }) => id === +locationId)?.name}
              </Tag>
            ))}
            {rooms.map((roomId) => (
              <Tag
                bordered={false}
                closable
                key={`room-${roomId}`}
                onClose={() =>
                  setRooms((old) => old.filter((id) => id !== roomId))
                }
              >
                {t`room.room`}
                {': '}
                {roomOptions.find(({ id }) => id === +roomId)?.name}
              </Tag>
            ))}
            {teacher.map((teacherId) => (
              <Tag
                bordered={false}
                closable
                key={`teacher-${teacherId}`}
                onClose={() =>
                  setTeacher((old) => old.filter((id) => id !== teacherId))
                }
              >
                {t`group.teacher`}
                {': '}
                {teacherOptions.find(({ id }) => id === +teacherId)?.name}
              </Tag>
            ))}
            {profile.map((profileId) => (
              <Tag
                bordered={false}
                closable
                key={`profile-${profileId}`}
                onClose={() =>
                  setProfile((old) => old.filter((id) => id !== profileId))
                }
              >
                {t`profile.profile`}
                {': '}
                {profileOptions.find(({ id }) => id === +profileId)?.name}
              </Tag>
            ))}
            {group.map((groupId) => (
              <Tag
                bordered={false}
                closable
                key={`group-${groupId}`}
                onClose={() =>
                  setGroup((old) => old.filter((id) => id !== groupId))
                }
              >
                {t`group.group`}
                {': '}
                {groupOptions.find(({ id }) => id === +groupId)?.name}
              </Tag>
            ))}
          </Space>
          {!isMobile && (
            <Button onClick={clearFilters}>{t`clearFilter`}</Button>
          )}
        </Flex>
      ) : null,
    [
      clearFilters,
      group,
      groupOptions,
      hasFilters,
      isMobile,
      locationOptions,
      locations,
      profile,
      profileOptions,
      roomOptions,
      rooms,
      schoolOptions,
      schools,
      t,
      teacher,
      teacherOptions,
    ],
  )

  const filters = useMemo(
    () => (
      <Flex
        style={{
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          gap: '8px',
        }}
      >
        <FilterSelect
          label={t`school.school`}
          options={optionsFromEntity(schoolOptions)}
          value={schools}
          onChange={setSchools}
        />
        <FilterSelect
          label={t`location.location`}
          options={optionsFromEntity(locationOptions)}
          value={locations}
          onChange={setLocations}
        />
        <FilterSelect
          label={t`room.room`}
          options={optionsFromEntity(roomOptions)}
          value={rooms}
          onChange={setRooms}
        />
        <FilterSelect
          label={t`teachers`}
          options={optionsFromEntity(teacherOptions)}
          value={teacher}
          onChange={setTeacher}
        />
        <FilterSelect
          label={t`profile.profile`}
          options={optionsFromEntity(profileOptions)}
          value={profile}
          onChange={setProfile}
        />
        <FilterSelect
          label={t`groups`}
          options={optionsFromEntity(groupOptions)}
          value={group}
          onChange={setGroup}
        />
      </Flex>
    ),
    [
      group,
      groupOptions,
      isMobile,
      locationOptions,
      locations,
      profile,
      profileOptions,
      roomOptions,
      rooms,
      schoolOptions,
      schools,
      t,
      teacher,
      teacherOptions,
    ],
  )

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '21px' }}>
        {isMobile && (
          <Flex justify="space-between">
            <Button
              icon={hasFilters ? <FilterTwoTone /> : <FilterOutlined />}
              ghost={hasFilters}
              type={hasFilters ? 'primary' : undefined}
              onClick={() => setOpen(true)}
            >{t`filters`}</Button>
            <Button onClick={clearFilters}>{t`clearFilter`}</Button>
          </Flex>
        )}
        {!isMobile && filters}
      </Flex>
      {tagFilters}
      <Flex justify="space-between" style={{ marginBottom: '16px' }}>
        <Button type="primary" onClick={() => navigate(routePaths.scheduleAdd)}>
          {isMobile ? '+' : t`schedule.addEvent`}
        </Button>
        <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button
            value={SCHEDULE_TYPES.DAY}
          >{t`schedule.day`}</Radio.Button>
          <Radio.Button
            value={SCHEDULE_TYPES.WEEK}
          >{t`schedule.week`}</Radio.Button>
          <Radio.Button
            value={SCHEDULE_TYPES.MONTH}
          >{t`schedule.month`}</Radio.Button>
        </Radio.Group>
      </Flex>
      <Schedule
        view={view}
        dates={dates}
        locations={locations}
        schools={schools}
        teachers={teacher}
        profiles={profile}
        rooms={rooms}
        groups={group}
        setDates={setDates}
      />
      <Drawer
        placement="right"
        width={'100%'}
        destroyOnClose
        open={open}
        closable={false}
      >
        {filters}
        {tagFilters}
        <Flex justify="space-between" gap="24px" style={{ marginTop: '16px' }}>
          <Button
            onClick={clearFilters}
            style={{ width: '100%' }}
          >{t`clearFilter`}</Button>
          <Button
            style={{ width: '100%' }}
            onClick={() => setOpen(false)}
            type="primary"
          >{t`show`}</Button>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default ScheduleViewWrapper
