interface AgeCategory {
  minAge: string
  maxAge: string
  name: string
  teamAgeName: string
  teamMinAgeRatio: string
  teamMinAgeShift: string
  teamMaxAgeRatio: string
  teamMaxAgeShift: string
  ageCalculationMode: string
  teamAgeDifference: string
}

interface InputData {
  ageCategories: AgeCategory[]
  tournamentId: string
  profileId: string[]
  specialization: string[]
  tags: string[]
  level: string[]
  gender: string[]
  formation: string[]
}

export interface GeneratedCombination extends AgeCategory {
  tournamentId: string
  profileId: string
  specialization: string
  level: string
  gender: string
  teamSizeName: string
  teamMinSize: number
  teamMaxSize: number
}

function cartesianProduct(arrays: any[][]): any[][] {
  return arrays.reduce(
    (accumulator, currentArray) => {
      return accumulator.flatMap((accItem) => {
        return currentArray.map((currentItem) => {
          return [...accItem, currentItem]
        })
      })
    },
    [[]],
  )
}

export function generateCombinations(
  inputData: InputData,
): GeneratedCombination[] {
  const {
    ageCategories,
    tournamentId,
    profileId,
    specialization,
    level,
    gender,
    formation,
  } = inputData
  const results: GeneratedCombination[] = []

  ageCategories.forEach((category) => {
    const arraysToCombine = [
      profileId || [''],
      specialization || [''],
      level || [''],
      gender || [''],
      formation || [''],
    ]
    const combinations = cartesianProduct(arraysToCombine)

    combinations.forEach((combination) => {
      const [
        profileIdValue,
        specializationValue,
        levelValue,
        genderValue,
        formationValue,
      ] = combination

      const result: GeneratedCombination = {
        tournamentId,
        profileId: profileIdValue,
        specialization: specializationValue,
        level: levelValue,
        gender: genderValue,
        ...category,
        ...formationValue,
      }

      results.push(result)
    })
  })

  return results
}
