import { Typography } from 'antd'
import { EnrollmentTabs } from '@pages/enrollments/EnrollmentTabs'
import { routePaths } from '@/routes'

export const GroupsEnrollment = () => {
  return (
    <EnrollmentTabs active={routePaths.enrollment.groups}>
      <Typography.Text>{'GroupsEnrollment enrollment'}</Typography.Text>
    </EnrollmentTabs>
  )
}
