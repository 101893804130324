import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo, useState } from 'react'
import {
  SchoolViewDto,
  StudentDocumentViewDto,
  StudentDocumentViewDtoStatusEnum,
  StudentSchoolEnrollmentStatusDto,
  StudentSchoolEnrollmentStatusDtoStatusEnum,
  StudentViewDto,
} from '@/openapi-api/api'
import { Button, Drawer, Flex, Space, Tag, Typography } from 'antd'
import { StudentEnrollment } from '@pages/enrollments/StudentEnrollment'
import { useGetAllStudents, useGetSchools } from '@/utils/hooks/entities'
import { useTranslation } from 'react-i18next'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { FilterSelect } from '@pages/schedule/FilterSelect'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { FilterOutlined, FilterTwoTone } from '@ant-design/icons'
interface GroupedEntity {
  school: SchoolViewDto
  student: StudentViewDto
  status?: StudentSchoolEnrollmentStatusDtoStatusEnum
  docs: StudentDocumentViewDto[]
  schoolEnrollment?: StudentSchoolEnrollmentStatusDto
}
import useUrlState, { useClearSearchParams } from '@/utils/hooks/useUrlState'

const doQuery = ({
  schools,
  students,
  statuses,
}: {
  students: string[]
  schools: string[]
  statuses: string[]
}) => {
  const query = []

  if (schools?.length) {
    query.push(`schoolId~in~${schools.join(',')}`)
  }

  if (statuses?.length) {
    query.push(`status~in~${statuses.join(',')}`)
  }
  if (students?.length) {
    query.push(`studentId~in~${students.join(',')}`)
  }

  return query.join(';') || undefined
}

export const ParentEnrollment = () => {
  const { studentDocumentViewControllerApi } = useApi()
  const [open, setOpen] = useState(false)
  const [schools, setSchools] = useUrlState('schools')
  const [students, setStudents] = useUrlState('students')
  const [statuses, setStatuses] = useUrlState('statuses')
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()
  const schoolOptions = useGetSchools()
  const studentOptions = useGetAllStudents()
  const clear = useClearSearchParams()
  const { resource: entities, fetch: refetch } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        studentDocumentViewControllerApi.find(
          0,
          1000,
          undefined,
          doQuery({ schools, students, statuses }),
        ),
      [schools, statuses, studentDocumentViewControllerApi, students],
    ),
  })

  const statusesOptions = useMemo(
    () => [
      {
        value: StudentDocumentViewDtoStatusEnum.DOCUMENTS_REQUESTED,
        label: t`invite.requested`,
      },
      {
        value: StudentDocumentViewDtoStatusEnum.ACCEPTED,
        label: t`invite.accepted`,
      },
      {
        value: StudentDocumentViewDtoStatusEnum.REJECTED,
        label: t`invite.error`,
      },
    ],
    [t],
  )

  const hasFilters = useMemo(
    () => !!(schools.length || students.length || statuses.length),
    [schools.length, statuses.length, students.length],
  )

  const tagFilters = useMemo(
    () =>
      hasFilters ? (
        <Flex
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr auto',
            marginBottom: '21px',
            marginTop: isMobile ? '21px' : 0,
          }}
        >
          <Space style={{ flexWrap: 'wrap' }}>
            {!isMobile && <Typography>{t`filters`}</Typography>}
            {schools.map((schoolId) => (
              <Tag
                bordered={false}
                closable
                key={`school-${schoolId}`}
                onClose={() => setSchools(schoolId)}
              >
                {t`school.school`}
                {': '}
                {schoolOptions.find(({ id }) => id === +schoolId)?.name}
              </Tag>
            ))}
            {students.map((studentId) => (
              <Tag
                bordered={false}
                closable
                key={`student-${studentId}`}
                onClose={() => setStudents(studentId)}
              >
                {t`students`}
                {': '}
                {studentOptions.find(({ id }) => id === +studentId)?.name}
              </Tag>
            ))}
            {statuses.map((statusId) => (
              <Tag
                bordered={false}
                closable
                key={`status-${statusId}`}
                onClose={() => setStatuses(statusId)}
              >
                {t`invite.status`}
                {': '}
                {statusesOptions.find(({ value }) => value === statusId)?.label}
              </Tag>
            ))}
          </Space>
          {!isMobile && <Button onClick={clear}>{t`clearFilter`}</Button>}
        </Flex>
      ) : null,
    [
      clear,
      hasFilters,
      isMobile,
      schoolOptions,
      schools,
      setSchools,
      setStatuses,
      setStudents,
      statuses,
      statusesOptions,
      studentOptions,
      students,
      t,
    ],
  )

  const filters = useMemo(
    () => (
      <Flex
        style={{
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          gap: '8px',
        }}
      >
        <FilterSelect
          label={t`school.school`}
          options={optionsFromEntity(schoolOptions)}
          value={schools}
          onChange={setSchools}
        />
        <FilterSelect
          label={t`students`}
          options={optionsFromEntity(studentOptions)}
          value={students}
          onChange={setStudents}
        />
        <FilterSelect
          label={t`enrollment.status`}
          options={statusesOptions}
          value={statuses}
          onChange={setStatuses}
        />
      </Flex>
    ),
    [
      isMobile,
      schoolOptions,
      schools,
      setSchools,
      setStatuses,
      setStudents,
      statuses,
      statusesOptions,
      studentOptions,
      students,
      t,
    ],
  )

  const enrollments = useMemo(() => {
    const groupedMap: { [key: string]: GroupedEntity } = {}

    entities?.data.content?.forEach((entity) => {
      const studentId = entity.student?.id
      const schoolId = entity.school?.id

      if (studentId !== undefined && schoolId !== undefined) {
        const key = `${studentId}-${schoolId}`

        if (!groupedMap[key]) {
          groupedMap[key] = {
            school: entity.school!,
            student: entity.student!,
            docs: [],
            status: entity.schoolEnrollment?.status,
          }
        }

        groupedMap[key].docs.push(entity)
      }
    })

    return Object.values(groupedMap)
  }, [entities?.data.content])

  return (
    <div>
      <Flex style={{ marginBottom: '21px' }}>
        {isMobile && (
          <Flex justify="space-between">
            <Button
              icon={hasFilters ? <FilterTwoTone /> : <FilterOutlined />}
              ghost={hasFilters}
              type={hasFilters ? 'primary' : undefined}
              onClick={() => setOpen(true)}
            >{t`filters`}</Button>
            <Button onClick={clear}>{t`clearFilter`}</Button>
          </Flex>
        )}
        {!isMobile && filters}
      </Flex>
      {tagFilters}
      <Flex vertical gap={24}>
        {enrollments.map(({ docs, school, student, status }) => (
          <StudentEnrollment
            key={`${school.id}-${student.id}`}
            docs={docs}
            student={student}
            school={school}
            refresh={refetch}
            status={status}
          />
        ))}
      </Flex>
      <Drawer
        placement="right"
        width={'100%'}
        destroyOnClose
        open={open}
        closable={false}
      >
        {filters}
        {tagFilters}
        <Flex justify="space-between" gap="24px" style={{ marginTop: '16px' }}>
          <Button
            onClick={clear}
            style={{ width: '100%' }}
          >{t`clearFilter`}</Button>
          <Button
            style={{ width: '100%' }}
            onClick={() => setOpen(false)}
            type="primary"
          >{t`show`}</Button>
        </Flex>
      </Drawer>
    </div>
  )
}
