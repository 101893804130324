import { Button, Flex, Form, FormProps, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '@/contexts/AuthContext'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { getBase64ToUpload, Uploader } from '@components/Uploader'
import { DeleteOutlined } from '@ant-design/icons'
import { useCallback, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { Separator } from '@components/Separator'
import { NO_DIGITS } from '@/utils/constants'
import { routePaths } from '@/routes'
import { useNavigate } from 'react-router-dom'

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const Profile = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const { user, refreshUser, avatar: image } = useAuthContext()
  const { adminsAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (!image) {
          await adminsAdminControllerApi.createMediaFile(user?.id, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await adminsAdminControllerApi.updateFile(
            user.id,
            image.id as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && image) {
        await adminsAdminControllerApi.deleteMediaFile(
          user.id,
          image.id as number,
        )
      }
      await adminsAdminControllerApi.update(user.id, {
        firstNamePL: values.firstNamePL,
        firstNameEN: values.firstNamePL,
        firstNameUA: values.firstNamePL,
        firstNameRU: values.firstNamePL,
        lastNamePL: values.lastNamePL,
        lastNameUA: values.lastNamePL,
        lastNameEN: values.lastNamePL,
        lastNameRU: values.lastNamePL,
        phone: values.phone,
      })
      await refreshUser()
    } finally {
      setSubmitting(false)
    }
  }

  const avatar = Form.useWatch('avatar', form)

  const resetData = useCallback(() => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        content: (
          <Flex vertical>
            <Typography.Text
              style={{ marginBottom: '8px', fontWeight: 600 }}
            >{t`warning`}</Typography.Text>
            <Typography.Text>{t`profile.changesNotSaved`}</Typography.Text>
          </Flex>
        ),
        onOk: () => form.resetFields(),
        icon: <ExclamationCircleOutlined />,
        cancelText: t`cancel`,
      })
    }
  }, [form, t])

  const deleteProfile = useCallback(() => {
    Modal.confirm({
      title: t`profile.deleteActionConfirm`,
      onOk: async () => {
        await adminsAdminControllerApi.deleteById(+(user.id || 0))
        navigate(routePaths.login)
      },
    })
  }, [adminsAdminControllerApi, navigate, t, user?.id])

  if (!user) return null

  return (
    <Flex align="center" vertical>
      <Typography.Title
        level={4}
        style={{ margin: 0, marginBottom: '24px' }}
      >{t`myProfile`}</Typography.Title>
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
        }}
        disabled={submitting}
        form={form}
        initialValues={{
          avatar: image
            ? [
                {
                  uid: '-1',
                  status: 'done',
                  url: image.link,
                },
              ]
            : null,
          firstNamePL: user?.firstName,
          lastNamePL: user?.lastName,
          phone: user?.phone,
          email: user?.loginCredentials.email,
        }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <Form.Item
          label={t`profile.avatar`}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="avatar"
          style={{ height: '102px' }}
        >
          <Uploader maxCount={1} length={avatar?.length} />
        </Form.Item>
        <FormInput
          label={t`login.name`}
          name="firstNamePL"
          rules={[
            { required: true, message: t`required` },
            { min: 1, max: 50 },
            { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
          ]}
          required={false}
        />
        <FormInput
          label={t`login.lastName`}
          name="lastNamePL"
          rules={[
            { required: true, message: t`required` },
            { min: 1, max: 50 },
            { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
          ]}
          required={false}
        />
        <FormPhoneInput label={t`profile.phone`} />
        <FormInput label={t`profile.email`} disabled name="email" />
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={resetData}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
      <Separator />
      <Flex align="center" vertical>
        <Typography.Title
          level={4}
          style={{ margin: 0, marginBottom: '24px' }}
        >{t`profile.delete`}</Typography.Title>
        <Button
          icon={<DeleteOutlined />}
          onClick={deleteProfile}
        >{t`profile.deleteAction`}</Button>
      </Flex>
    </Flex>
  )
}

export default Profile
