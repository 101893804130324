import { Flex, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { TournamentDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { addFilterToUrl } from '@/utils/functions/filterUtils'

import useIsDevice from '@/utils/hooks/useIsMobile'
import dayjs from 'dayjs'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.city?.length) {
    query.push(`city.name~~${filters.city[0]}`)
  }
  return query.join(';') || undefined
}

const PAGE_SIZE = 10

const AdminTournamentsList = () => {
  const { t } = useTranslation()
  const { tournamentsAdminControllerApi } = useApi()
  const navigate = useNavigate()
  const { isBothMobile } = useIsDevice()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: tournaments } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        tournamentsAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          doSort(tableParams, { dates: 'dateStart' }),
          doQuery(tableParams),
        ),
      [tournamentsAdminControllerApi, tableParams],
    ),
  })

  const searchColumn = useSearchColumn<TournamentDto>()

  const columns: ColumnsType<TournamentDto> = useMemo(
    () => [
      {
        title: t`tournaments.name`,
        dataIndex: 'name',
        ...searchColumn('name'),
      },
      {
        title: t`city`,
        dataIndex: 'city',
        ...searchColumn('city'),
        render: (_, entity) => entity.city?.name,
      },
      {
        title: t`date`,
        sorter: true,
        dataIndex: 'dates',
        render: (_, entity) => {
          const from = entity.dateStart
            ? dayjs(entity.dateStart).format('DD.MM.YYYY')
            : ''
          const to = entity.dateEnd
            ? dayjs(entity.dateEnd).format('DD.MM.YYYY')
            : ''
          return `${from} - ${to}`
        },
      },
      {
        title: t`tournaments.registrationEnd`,
        dataIndex: 'registrationDateEnd',
        sorter: true,
        render: (val) => (val ? dayjs(val).format('DD.MM.YYYY') : '-'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={
                routePaths.admin.categories.all +
                addFilterToUrl({
                  filters: {
                    tournament: [entity.name],
                  },
                })
              }
            >{t`tournaments.categories`}</Link>
          </Space>
        ),
      },
    ],
    [searchColumn, t],
  )

  return (
    <Flex vertical>
      <Typography.Title
        level={5}
      >{t`tournaments.tournaments`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={tournaments?.data.content || []}
        onChange={handleTableChange}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default AdminTournamentsList
