import { FC } from 'react'
import { SessionAttendanceViewDto } from '@/openapi-api/api'
import { Button, Flex, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { formatTimeRange, generateCardStyles } from '@pages/schedule/helpers'
import { useTranslation } from 'react-i18next'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import { SessionProgress } from '@pages/attendance/SessionProgress'

interface Props {
  session: SessionAttendanceViewDto
}

export const AttendanceSessionCard: FC<Props> = ({ session }) => {
  const startTime = dayjs(session.startTime)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()

  return (
    <Flex
      style={{
        padding: '16px',
        backgroundColor: session.group?.color,
        borderRadius: '4px',
        gap: '8px',
        ...generateCardStyles(startTime, session.duration),
      }}
      vertical
    >
      <Flex
        justify="space-between"
        align="center"
        style={{ flexWrap: isMobile ? 'wrap' : 'nowrap', rowGap: '8px' }}
      >
        <Space>
          <Typography style={{ fontWeight: 600 }}>
            {formatTimeRange(startTime, session.duration as number)}
          </Typography>
          <Typography style={{ fontWeight: 600 }}>
            {t`group.group`} {session.group?.name} {session.group?.level}
          </Typography>
        </Space>

        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            type="primary"
            onClick={() => navigate(routePaths.attendance.specific(session.id))}
          >{t`attendance.new`}</Button>
          <Button
            type="link"
            onClick={() =>
              navigate(routePaths.groups.specific(session.group?.id).edit)
            }
          >{t`attendance.viewEditGroup`}</Button>
        </div>
      </Flex>

      <Space
        style={{
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography>{session.teacher?.name}</Typography>
        <Typography>{session.room?.place?.address}</Typography>
        <Typography>{session.room?.name}</Typography>
      </Space>
      <SessionProgress session={session} />
      <Flex>
        <Button type="link" style={{ padding: 0, height: 'auto' }}>
          {t`attendance.list`}
        </Button>
      </Flex>
    </Flex>
  )
}
