import classNames from 'classnames'
import { FC, PropsWithChildren, useState } from 'react'
import styles from './Styles.module.less'
import { Button, Flex } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

interface Props extends PropsWithChildren {
  title: string
  center?: boolean
}
export const ButtonAccordion: FC<Props> = ({ title, children, center }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.accordion}>
      <Flex
        justify={center ? 'center' : 'left'}
        align="center"
        style={{ height: '32px', marginBottom: '8px' }}
      >
        <Button
          type="link"
          onClick={toggleAccordion}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {title} {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </Button>
      </Flex>
      <div
        className={classNames(styles.accordionContent, {
          [styles.open]: isOpen,
        })}
      >
        {children}
      </div>
    </div>
  )
}
