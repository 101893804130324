import { useCallback, useMemo, useState } from 'react'
import useApi from '@/contexts/api'
import { useTranslation } from 'react-i18next'
import { TeacherDto } from '@/openapi-api/api'
import { Button, Card, Flex, Modal, Typography } from 'antd'
import Meta from 'antd/es/card/Meta'
import { extractAvatar } from '@/utils/functions/dataUtils'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'

export const useTeacherPopup = () => {
  const { teachersAdminControllerApi } = useApi()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [teacher, setTeacher] = useState<TeacherDto | undefined>()

  const openTeacherModal = useCallback(
    async (id?: number) => {
      const { data } = await teachersAdminControllerApi.getById(id as number)

      setTeacher(data)
    },
    [teachersAdminControllerApi],
  )

  const modal = useMemo(
    () => (
      <Modal
        title={t`group.teacher`}
        open={!!teacher}
        onOk={() => setTeacher(undefined)}
        onCancel={() => setTeacher(undefined)}
        footer={[]}
      >
        <Card cover={<img alt="example" src={extractAvatar(teacher)} />}>
          <Meta title={teacher?.name} style={{ marginBottom: '8px' }} />
          <Flex vertical gap={24}>
            <Typography.Text style={{ display: 'block' }}>
              {teacher?.bio}
            </Typography.Text>
            <Typography.Text>
              {`${t`specialization`}: ${teacher?.specializations}`}
            </Typography.Text>
            {teacher?.socialMediaLink && (
              <a
                href={teacher.socialMediaLink}
                target="_blank"
                rel="noreferrer"
              >
                {teacher.socialMediaLink}
              </a>
            )}
          </Flex>
        </Card>
        <Flex justify="center">
          <Button
            type="link"
            style={{ marginTop: '16px' }}
            onClick={() => {
              navigate(`${routePaths.user.schedule}?teachers=${teacher?.id}`)
              setTeacher(undefined)
            }}
          >{t`allTeacherLessions`}</Button>
        </Flex>
      </Modal>
    ),
    [navigate, t, teacher],
  )

  return { openTeacherModal, modal }
}
