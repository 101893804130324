import { FC, Fragment, useMemo, useState } from 'react'
import { SessionDto } from '@/openapi-api/api'
import { Button, Drawer, Flex, Space, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { formatTimeRange, groupByDayAndHour } from '@pages/schedule/helpers'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { chunk } from 'lodash'
import { SessionCard } from '@pages/schedule/SessionCard'
import { getColor, getName } from '@pages/groups/GroupsList'

interface Props {
  sessions: SessionDto[]
  date: Dayjs
  wrap?: boolean
  refresh: () => Promise<void>
}

export const MonthCard: FC<Props> = ({ sessions, date, wrap, refresh }) => {
  const { isBothMobile, isMobile } = useIsMobile()
  const [open, setOpen] = useState(false)

  const overflow = useMemo(
    () => sessions.length - (isBothMobile ? 6 : 5),
    [isBothMobile, sessions.length],
  )
  return (
    <>
      <Flex
        style={{
          height: '136px',
          borderTop: '2px solid rgba(0, 0, 0, 0.06)',

          overflow: 'hidden',
          position: 'relative',
          cursor: 'pointer',
        }}
        vertical
        onClick={() => setOpen(true)}
      >
        {overflow > 0 && (
          <Button
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              bottom: '2px',
              right: '2px',
              width: '32px',
            }}
          >{`+${overflow}`}</Button>
        )}
        <Flex
          style={{ height: '22px', marginTop: '2px', padding: '0 8px' }}
          justify="flex-end"
        >
          {date.date()}
        </Flex>
        <Flex
          vertical
          gap="2px"
          style={{ paddingLeft: '4px', whiteSpace: 'nowrap' }}
        >
          {sessions.map((session) => (
            <Space
              style={{
                padding: '1px 4px',
                backgroundColor: getColor(session),
                borderRadius: '4px',
                opacity: dayjs(session.startTime)
                  .add(session.duration as number, 'minutes')
                  .isBefore(dayjs())
                  ? 0.5
                  : 1,
              }}
              key={session.id}
            >
              <Typography style={{ fontSize: isBothMobile ? '10px' : '12px' }}>
                {formatTimeRange(
                  dayjs(session.startTime),
                  session.duration as number,
                  isBothMobile,
                )}
              </Typography>
              <Typography style={{ fontSize: isBothMobile ? '10px' : '12px' }}>
                {getName(session)}
              </Typography>
            </Space>
          ))}
        </Flex>
      </Flex>
      <Drawer
        title={date.format('DD MMM, YYYY')}
        placement="right"
        width={isMobile ? '100%' : '400px'}
        destroyOnClose
        onClose={() => setOpen(false)}
        open={open}
      >
        {groupByDayAndHour(sessions).map(({ hour, items }) => (
          <Fragment key={hour}>
            <Flex vertical gap="8px" style={{ marginBottom: '8px' }}>
              <Typography
                style={{ color: 'rgba(0, 0, 0, 0.45)' }}
              >{`${hour}:00`}</Typography>
            </Flex>
            <Flex gap={'8px'} style={{ marginBottom: '8px' }} vertical>
              {chunk(items, 3).map((items) => (
                <Flex
                  gap={'8px'}
                  style={{
                    display: 'grid',
                    gridTemplateColumns:
                      isBothMobile || wrap
                        ? '1fr'
                        : 'repeat(auto-fit, minmax(32%, 1fr))',
                  }}
                  key={`row-${items[0].id}`}
                >
                  {items.map((i) => (
                    <SessionCard
                      key={i.id}
                      session={i}
                      wrap
                      refresh={refresh}
                    />
                  ))}
                </Flex>
              ))}
            </Flex>
          </Fragment>
        ))}
      </Drawer>
    </>
  )
}
