/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateTournamentCategoryRequest
 */
export interface CreateTournamentCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'tournamentId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'namePL': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'nameRU'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'profileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamAgeNamePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamAgeNameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamAgeNameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamAgeNameRU'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMinAge'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMaxAge'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMinAgeShift'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMinAgeRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMaxAgeShift'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMaxAgeRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamAgeDifference'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'ageCalculationMode'?: CreateTournamentCategoryRequestAgeCalculationModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamSizeNamePL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamSizeNameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamSizeNameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamSizeNameRU'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMinSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentCategoryRequest
     */
    'teamMaxSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentCategoryRequest
     */
    'gender'?: CreateTournamentCategoryRequestGenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTournamentCategoryRequestAgeCalculationModeEnum {
    YEAR_OF_BIRTH = 'YEAR_OF_BIRTH',
    EXACT_BIRTH_DAY = 'EXACT_BIRTH_DAY'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateTournamentCategoryRequestGenderEnum {
    MALE = 'male',
    FEMALE = 'female',
    OTHER = 'other',
    UNKNOWN = 'unknown'
}


