import { FC, PropsWithChildren } from 'react'
import styles from '@pages/login/index.module.less'

export const Layout: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.form}>
    <div className={styles.logo}>
      <img
        alt="Logo"
        src="/logo-square.png"
        style={{ width: '100%', maxWidth: '170px', height: '176px' }}
        className={styles.desktopLogo}
      />
      <img
        alt="Logo"
        src="/logo.png"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        className={styles.mobileLogo}
      />
    </div>
    <div className={styles.contentWrapper}>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)
