import { useCallback, useEffect, useState } from 'react'

interface UseAsyncResourceParams<T, D extends T | undefined> {
  fetchResource: () => Promise<T> | void
  defaultValue?: D
  defaultIsLoading?: boolean
  autoFetching?: boolean
}

export function useAsyncResource<T, D extends T | undefined>({
  defaultValue,
  fetchResource,
  defaultIsLoading = false,
  autoFetching = true,
}: UseAsyncResourceParams<T, D>) {
  type Resource = D extends T ? T : T | undefined

  const [resource, setResource] = useState<Resource>(defaultValue as Resource)
  const [isLoading, setIsLoading] = useState(defaultIsLoading)
  const [error, setError] = useState<unknown>()
  const [loaded, setLoaded] = useState(false)

  const fetch = useCallback(async () => {
    setError(undefined)
    try {
      setIsLoading(true)

      const resp = await fetchResource()

      setResource(resp as Resource)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
      setLoaded(true)
    }
  }, [fetchResource])

  useEffect(() => {
    if (autoFetching) {
      fetch()
    }
  }, [autoFetching, fetch])

  return { resource, isLoading, setResource, fetch, loaded, error }
}
