import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { Layout } from './Layout'
import { Flex, Spin, Typography } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useEffectOnce from '@/utils/hooks/useEffectOnce'

export const Verification = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { registrationControllerApi } = useApi()
  const [result, setResult] = useState<'success' | 'fail'>()
  const { t } = useTranslation()

  useEffectOnce(() => {
    const email = searchParams.get('email')
    const code = searchParams.get('code')
    if (!code || !email) {
      navigate(routePaths.login)
      return
    }
    registrationControllerApi
      .confirmRegistration({ code, email })
      .then(() => {
        setResult('success')
      })
      .catch(() => {
        setResult('fail')
      })
      .finally(() => {
        setTimeout(() => {
          navigate(routePaths.login)
        }, 5000)
      })
  })

  return (
    <Layout>
      <Flex align="center" justify="center" style={{ height: '100%' }}>
        {!result && <Spin size="large" />}
        {result === 'success' && (
          <Flex align="center" vertical>
            <CheckCircleFilled
              style={{
                color: 'rgba(82, 196, 26, 1)',
                fontSize: '52px',
                marginBottom: '20px',
              }}
            />
            <Typography.Text
              style={{ textAlign: 'center', fontSize: '18px' }}
            >{t`login.activationSuccess`}</Typography.Text>
          </Flex>
        )}
        {result === 'fail' && (
          <Flex align="center" vertical>
            <CloseCircleFilled
              style={{
                color: 'rgba(255, 77, 79, 1)',
                fontSize: '52px',
                marginBottom: '20px',
              }}
            />
            <Typography.Text
              style={{ textAlign: 'center', fontSize: '18px' }}
            >{t`login.activationError`}</Typography.Text>
          </Flex>
        )}
      </Flex>
    </Layout>
  )
}
