import { FC } from 'react'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

export interface Props {
  title: string
  items: MenuProps['items']
}
export const MenuDropdown: FC<Props> = ({ title, items }) => (
  <Dropdown menu={{ items }}>
    <a onClick={(e) => e.preventDefault()}>
      <Space style={{ color: 'white' }}>
        {title}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
)
