import React, { useState } from 'react'
import { Form, Input, Button, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/es/form'
import { useTranslation } from 'react-i18next'

interface StringArrayFormFieldProps {
  name: string
  form: FormInstance
  required?: boolean
  label?: string
}

const StringArrayFormField: React.FC<StringArrayFormFieldProps> = ({
  name,
  form,
  required,
  label,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { t } = useTranslation()

  const handleAddString = () => {
    const currentStrings: string[] = form.getFieldValue(name) || []
    if (inputValue && !currentStrings.includes(inputValue)) {
      const updatedStrings = [...currentStrings, inputValue]
      form.setFieldsValue({ [name]: updatedStrings })
      setInputValue('')
    }
  }

  const handleRemoveString = (removedString: string) => {
    const currentStrings: string[] = form.getFieldValue(name) || []
    const updatedStrings = currentStrings.filter((str) => str !== removedString)
    form.setFieldsValue({ [name]: updatedStrings })
  }

  const currentStrings: string[] = form.getFieldValue(name) || []

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: t`required`,
          type: 'array',
        },
      ]}
    >
      <>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handleAddString}
            placeholder={t`tournaments.bulkAddLevel`}
          />
          <Button
            type="primary"
            onClick={handleAddString}
            icon={<PlusOutlined />}
          >
            {t`add`}
          </Button>
        </div>

        <div style={{ marginTop: 16 }}>
          {currentStrings.map((str) => (
            <Tag
              key={str}
              closable
              onClose={() => handleRemoveString(str)}
              style={{ marginBottom: 8 }}
            >
              {str}
            </Tag>
          ))}
        </div>
      </>
    </Form.Item>
  )
}

export default StringArrayFormField
