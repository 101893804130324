import { useState } from 'react'
import { TableParams } from '../types'
import { TableProps } from 'antd'
import { parseFiltersFormUrl } from '@/utils/functions/filterUtils'

export const doSort = (
  { sortField, sortOrder }: TableParams,
  mapper: Record<string, string> = {},
) =>
  sortField && sortOrder
    ? [
        `${
          mapper[sortField as string] ? mapper[sortField as string] : sortField
        },${sortOrder === 'ascend' ? 'asc' : 'desc'}`,
      ]
    : undefined

export const useTableFilters = (size = 10) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: size,
    },
    ...parseFiltersFormUrl(),
  })

  const handleTableChange: TableProps['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    })
  }

  return { tableParams, handleTableChange, setTableParams }
}
