import {
  Button,
  Checkbox,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Select,
  Space,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '@/contexts/AuthContext'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { getBase64ToUpload, Uploader } from '@components/Uploader'
import { useCallback, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { NO_DIGITS } from '@/utils/constants'
import { getValue } from '@/utils/functions/dataUtils'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { useGetCities } from '@/utils/hooks/entities'

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const OrganizerProfile = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const { user, refreshUser, avatar: image } = useAuthContext()
  const { organisersAdminControllerApi } = useApi()
  const cities = useGetCities()

  const [form] = Form.useForm()
  const naturalPerson = Form.useWatch('naturalPerson', form)
  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (!image) {
          await organisersAdminControllerApi.createMediaFile(user?.id, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await organisersAdminControllerApi.updateFile(
            user.id,
            image.id as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && image) {
        await organisersAdminControllerApi.deleteMediaFile(
          user.id,
          image.id as number,
        )
      }
      await organisersAdminControllerApi.update(user.id, {
        namePL: values.name,
        descriptionPL: values.description,
        phone: values.phone,
        address: values.address,
        countryId: user.country.id,
        loginCredentialsId: user.loginCredentials.id,
        cityId: getValue(values.cityId, true) as number,
        naturalPerson: values?.naturalPerson,
        nip: values?.nip,
        companyName: values?.companyName,
        regon: values?.regon,
      })
      await refreshUser()
    } finally {
      setSubmitting(false)
    }
  }

  const avatar = Form.useWatch('avatar', form)

  const resetData = useCallback(() => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        content: (
          <Flex vertical>
            <Typography.Text
              style={{ marginBottom: '8px', fontWeight: 600 }}
            >{t`warning`}</Typography.Text>
            <Typography.Text>{t`profile.changesNotSaved`}</Typography.Text>
          </Flex>
        ),
        onOk: () => form.resetFields(),
        icon: <ExclamationCircleOutlined />,
        cancelText: t`cancel`,
      })
    }
  }, [form, t])

  if (!user) return null

  return (
    <Flex align="center" vertical>
      <Typography.Title
        level={4}
        style={{ margin: 0, marginBottom: '24px' }}
      >{t`myProfile`}</Typography.Title>
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 600,
          width: '100%',
        }}
        disabled={submitting}
        form={form}
        initialValues={{
          avatar: image
            ? [
                {
                  uid: '-1',
                  status: 'done',
                  url: image.link,
                },
              ]
            : null,
          name: user?.name,
          phone: user?.phone,
          email: user?.loginCredentials.email,
          cityId: user?.city?.id ? String(user?.city?.id) : null,
          description: user?.description,
          address: user?.address,
          naturalPerson: user?.naturalPerson,
          nip: user?.nip,
          companyName: user?.companyName,
          regon: user?.regon,
        }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <Form.Item
          label={t`profile.avatar`}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          name="avatar"
          style={{ height: '102px' }}
        >
          <Uploader maxCount={1} length={avatar?.length} />
        </Form.Item>
        <FormInput
          label={t`login.name`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { min: 1, max: 50 },
            { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
          ]}
          required={false}
        />
        <Form.Item
          name="cityId"
          label={t`city`}
          rules={[
            {
              required: true,
              message: t`required`,
            },
          ]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(cities)}
          />
        </Form.Item>
        <FormPhoneInput label={t`profile.phone`} />
        <FormInput label={t`profile.email`} disabled name="email" />
        <Form.Item name="description" label={t`school.description`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item name="address" label={t`school.address`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <FormInput
          label={t`school.companyName`}
          name="companyName"
          rules={[
            { required: !naturalPerson, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <Form.Item
          label={t`school.nip`}
          name="nip"
          rules={[
            { required: !naturalPerson, message: t`required` },
            {
              min: 10,
              max: 10,
              message: t`10digits`,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="naturalPerson"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox>{t`school.naturalPerson`}</Checkbox>
        </Form.Item>
        <Form.Item
          label={t`school.regon`}
          name="regon"
          rules={[
            { required: !naturalPerson, message: t`required` },
            {
              min: 1,
              max: 9,
              message: t`max9numbers`,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={resetData}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
    </Flex>
  )
}

export default OrganizerProfile
