import React, { useContext } from 'react'

import { notification } from 'antd'
import { NotificationInstance } from 'antd/es/notification/interface'

export interface IAuthContext {
  info: NotificationInstance['info']
  warning: NotificationInstance['warning']
  error: NotificationInstance['error']
}

const LanguageContext = React.createContext<IAuthContext | undefined>(undefined)

const NotificationProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [api, contextHolder] = notification.useNotification()

  let value = {
    info: api.info,
    warning: api.warning,
    error: api.error,
  }

  return (
    <LanguageContext.Provider value={value}>
      {contextHolder}
      {children}
    </LanguageContext.Provider>
  )
}

export function useNotificationContext() {
  const context = useContext(LanguageContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useNotificationContext must be used within an NotificationProvider',
    )
  }

  return context
}

export default NotificationProvider
