import { Avatar, Button, Flex, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { FC, useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import {
  SendUserNotificationRequestTypeEnum,
  StudentGroupEnrollmentViewDto,
  StudentGroupEnrollmentViewDtoStatusEnum,
} from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { UserOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { formatPhoneNumber } from '@/utils/functions/dataUtils'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'
import { useSendNotification } from '@/utils/hooks/useSendNotification'

const PAGE_SIZE = 6

const doQuery = ({ filters }: TableParams, id?: string) => {
  const query = []
  if (filters?.['student.name']?.length) {
    query.push(`studentName~~${filters['student.name'][0]}`)
  }
  query.push(`groupId==${id}`)
  return query.join(';') || undefined
}

interface Props {
  schoolId: string
}

const GroupStudentsList: FC<Props> = ({ schoolId }) => {
  const { t } = useTranslation()
  const { studentGroupEnrollmentViewControllerApi } = useApi()

  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)
  const searchColumn = useSearchColumn<StudentGroupEnrollmentViewDto>()
  const { id } = useParams()
  const { modal, setData } = useSendNotification()

  const { resource: entities } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        studentGroupEnrollmentViewControllerApi.find(
          (tableParams.pagination?.current ?? 1) - 1,
          tableParams.pagination?.pageSize ?? PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams, id),
        ),
      [id, studentGroupEnrollmentViewControllerApi, tableParams],
    ),
  })

  const columns: ColumnsType<StudentGroupEnrollmentViewDto> = useMemo(
    () => [
      {
        title: t`teacher.fio`,
        dataIndex: 'student.name',
        sorter: true,
        ...searchColumn('name'),
        render: (_, entity) => (
          <Space size="small">
            <Avatar
              shape="circle"
              size="large"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.student?.thumbLink || <UserOutlined />}
            />
            <Button type="link">{entity.student?.name}</Button>
          </Space>
        ),
      },
      {
        title: t`student.birthDate`,
        dataIndex: 'student.birthDate',
        render: (_, entity) =>
          dayjs(entity.student?.birthDate).format('DD MMM, YYYY'),
        sorter: true,
      },
      {
        title: t`parent`,
        dataIndex: 'student.user',
        render: (_, entity) => (
          <Space size="small">
            <Avatar
              shape="circle"
              size="large"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.user?.thumbLink || <UserOutlined />}
            />
            <Flex vertical>
              <Typography>
                {entity.user?.firstName} {entity.user?.lastName}
              </Typography>
              <Typography>{formatPhoneNumber(entity.user?.phone)}</Typography>
            </Flex>
          </Space>
        ),
      },
      // {
      //   title: t`profile.phone`,
      //   dataIndex: 'student.phone',
      //   render: (_, entity) => formatPhoneNumber(entity.student),
      // },
      {
        title: t`invite.status`,
        dataIndex: 'status',
        sorter: true,
        render: (_, entity) =>
          renderStatusBadge(
            entity.status as StudentGroupEnrollmentViewDtoStatusEnum,
            t,
          ),
      },
    ],
    [searchColumn, t],
  )

  return (
    <Flex vertical>
      <Flex justify="space-between">
        <Typography.Title
          level={4}
          style={{ marginTop: 0 }}
        >{t`student.list`}</Typography.Title>
        <Button
          type="primary"
          onClick={() =>
            setData({
              schoolId: +schoolId!,
              entityId: +id!,
              type: SendUserNotificationRequestTypeEnum.STUDENT_GROUP,
            })
          }
        >{t`notifications.sendMessageToAll`}</Button>
      </Flex>
      <Typography.Title level={5}>{t`students`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() ?? ''}
        dataSource={entities?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: entities?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
      {modal}
    </Flex>
  )
}

export default GroupStudentsList
