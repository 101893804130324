/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateTournamentRequest
 */
export interface CreateTournamentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'namePL': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'nameEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'nameUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'nameRU'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'descriptionPL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'descriptionEN'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'descriptionUA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'descriptionRU'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentRequest
     */
    'countryId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTournamentRequest
     */
    'cityId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'dateStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'dateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'registrationDateEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'status': CreateTournamentRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentRequest
     */
    'phone'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTournamentRequestStatusEnum {
    UNPUBLISHED = 'UNPUBLISHED',
    PUBLISHED = 'PUBLISHED',
    ARCHIVED = 'ARCHIVED',
    ANNOUNCED = 'ANNOUNCED',
    CANCELED = 'CANCELED'
}


