import { Button, Flex, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { SessionDto } from '@/openapi-api/api'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import useIsDevice from '@/utils/hooks/useIsMobile'
import { Dates } from '@pages/schedule/types'
import { days, GroupedByDay, groupEventsByPeriodAndWeekDay } from './helpers'
import { SessionCardWeekView } from '@pages/schedule/SessionCardWeekView'
import { DayTopRow } from '@pages/schedule/DayTopRow'

interface Props {
  sessions: SessionDto[]
  dates: Dates
  setDates: (dates: Dates) => void
  refresh: () => Promise<void>
}

const UserScheduleWeekView: FC<Props> = ({
  sessions,
  dates,
  setDates,
  refresh,
}) => {
  const { t } = useTranslation()

  const { isBothMobile } = useIsDevice()

  const dataToRender = useMemo(
    () => groupEventsByPeriodAndWeekDay(sessions),
    [sessions],
  )

  return (
    <Flex vertical>
      <Flex justify="center" style={{ marginBottom: '16px' }}>
        <Space align="center" style={{ justifyContent: 'center' }} size="large">
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              setDates([
                dates[0].subtract(7, 'days'),
                dates[1].subtract(7, 'days'),
              ])
            }
          />
          <Typography.Title level={4} style={{ margin: 0 }}>
            {dates[0].format('DD.MM')}
            {' - '}
            {dates[1].format('DD.MM')}
          </Typography.Title>
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() =>
              setDates([dates[0].add(7, 'days'), dates[1].add(7, 'days')])
            }
          />
        </Space>
      </Flex>
      <Flex
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7 ,minmax(0, 1fr))',
          gap: isBothMobile ? '4px' : '12px',
          paddingLeft: '16px',
        }}
      >
        {days.map((day, i) => {
          const date = dates[0].add(i, 'days')
          return <DayTopRow key={`header-${day}`} day={date} short />
        })}
      </Flex>
      <Flex vertical>
        {Object.keys(dataToRender.morning).some(
          (k) => dataToRender.morning[k as keyof GroupedByDay].length,
        ) && (
          <Flex>
            <Flex
              style={{
                width: '12px',
                backgroundColor: '#EFDBFF',
                alignItems: 'center',
                marginRight: '4px',
              }}
            >
              <Typography
                style={{
                  transform: 'rotate(-180deg)',
                  writingMode: 'vertical-rl',
                  marginLeft: '-5px',
                }}
              >
                {t`schedule.morning`}
              </Typography>
            </Flex>
            <Flex
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7 ,minmax(0, 1fr))',
                gap: isBothMobile ? '4px' : '12px',
              }}
            >
              {days.map((day) => {
                const items = dataToRender.morning[day]
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                    key={`morning-${day}`}
                  >
                    {items.map((item) => (
                      <SessionCardWeekView
                        refresh={refresh}
                        session={item}
                        key={item.id}
                      />
                    ))}
                  </div>
                )
              })}
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex vertical>
        {Object.keys(dataToRender.afternoon).some(
          (k) => dataToRender.afternoon[k as keyof GroupedByDay].length,
        ) && (
          <Flex style={{ marginTop: '4px' }}>
            <Flex
              style={{
                width: '12px',
                backgroundColor: '#FFE7BA',
                alignItems: 'center',
                marginRight: '4px',
              }}
            >
              <Typography
                style={{
                  transform: 'rotate(-180deg)',
                  writingMode: 'vertical-rl',
                  marginLeft: '-5px',
                }}
              >
                {t`schedule.afternoon`}
              </Typography>
            </Flex>
            <Flex
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7 ,minmax(0, 1fr))',
                gap: isBothMobile ? '4px' : '12px',
              }}
            >
              {days.map((day) => {
                const items = dataToRender.afternoon[day]
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                    key={`afternoon-${day}`}
                  >
                    {items.map((item) => (
                      <SessionCardWeekView
                        refresh={refresh}
                        session={item}
                        key={item.id}
                      />
                    ))}
                  </div>
                )
              })}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex vertical>
        {Object.keys(dataToRender.evening).some(
          (k) => dataToRender.evening[k as keyof GroupedByDay].length,
        ) && (
          <Flex style={{ marginTop: '4px' }}>
            <Flex
              style={{
                width: '12px',
                backgroundColor: '#BAE0FF',
                alignItems: 'center',
                marginRight: '4px',
              }}
            >
              <Typography
                style={{
                  transform: 'rotate(-180deg)',
                  writingMode: 'vertical-rl',
                  marginLeft: '-5px',
                }}
              >
                {t`schedule.evening`}
              </Typography>
            </Flex>
            <Flex
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7 ,minmax(0, 1fr))',
                gap: isBothMobile ? '4px' : '12px',
              }}
            >
              {days.map((day) => {
                const items = dataToRender.evening[day]
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                    key={`evening-${day}`}
                  >
                    {items.map((item) => (
                      <SessionCardWeekView
                        refresh={refresh}
                        session={item}
                        key={item.id}
                      />
                    ))}
                  </div>
                )
              })}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default UserScheduleWeekView
