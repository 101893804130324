import { ProfileDto } from '@/openapi-api/api'
import { TFunction } from 'i18next'
import { GeneratedCombination } from '@pages/organiser/generateCombinations'
import { Space, TableColumnsType } from 'antd'

export const teamSizeName: Record<
  string,
  {
    teamSizeName: string
    teamMinSize: number
    teamMaxSize: number
  }
> = {
  SOLO: {
    teamSizeName: 'tournaments.bulkTeamSizeNameSolo',
    teamMinSize: 1,
    teamMaxSize: 1,
  },
  DUET: {
    teamSizeName: 'tournaments.bulkTeamSizeNameDuet',
    teamMinSize: 2,
    teamMaxSize: 2,
  },
  TRIO: {
    teamSizeName: 'tournaments.bulkTeamSizeNameTrio',
    teamMinSize: 3,
    teamMaxSize: 3,
  },
  SMALL_FORMATION: {
    teamSizeName: 'tournaments.bulkTeamSizeNameSmallFormation',
    teamMinSize: 4,
    teamMaxSize: 8,
  },
  MIDDLE_FORMATION: {
    teamSizeName: 'tournaments.bulkTeamSizeNameMiddleFormation',
    teamMinSize: 9,
    teamMaxSize: 11,
  },
  BIG_FORMATION: {
    teamSizeName: 'tournaments.bulkTeamSizeNameBigFormation',
    teamMinSize: 12,
    teamMaxSize: 30,
  },
}

export const generateCategoriesColumns = (
  t: TFunction,
  profiles: ProfileDto[],
  deleteCategory: (i: number) => void,
): TableColumnsType<GeneratedCombination> => [
  {
    title: t`profile.profile`,
    dataIndex: 'profileId',
    key: 'profileId',
    render: (val: string) => profiles.find((p) => p.id === +val)?.name,
  },
  {
    title: t`group.specialization`,
    dataIndex: 'specialization',
    key: 'specialization',
  },
  {
    title: t`tournaments.bulkTeamSize`,
    dataIndex: 'teamSizeName',
    key: 'teamSizeName',
    render: (val: string) => t(val),
  },
  {
    title: t`group.level`,
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: t`tournaments.bulkAgeCategoryName`,
    dataIndex: 'teamAgeName',
    key: 'teamAgeName',
  },
  {
    title: t`teacher.gender`,
    dataIndex: 'gender',
    key: 'gender',
    render: (val) => {
      switch (val) {
        case 'male':
          return t`teacher.genderMan`
        case 'female':
          return t`teacher.genderWoman`
        default:
          return t`teacher.genderOther`
      }
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (i, entity, index) => (
      <Space size="middle">
        <a onClick={() => deleteCategory(index)}>{t`delete`}</a>
      </Space>
    ),
  },
]
