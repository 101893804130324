import { FormInput } from '@components/Form/FormInput'
import { Alert, Button, Flex, Form, Space, Typography } from 'antd'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginViewProps } from '@pages/login/types'
import commonStyles from '@/common.module.less'
import { useAuthContext } from '@/contexts/AuthContext'
import { EMAIL_REGEXP } from '@/utils/constants'
import { isAxiosError } from 'axios'

export const LoginView: FC<LoginViewProps> = ({ changeLayout }) => {
  const { login } = useAuthContext()
  const [error, setError] = useState<string | undefined>()
  const { t } = useTranslation()

  const handleOk = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      try {
        setError('')
        await login(username, password)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e.response?.data.message
          if (
            message.includes('Incorrect username or password') ||
            message.includes('Password is not valid for user') ||
            message.includes('No user exist with username')
          ) {
            setError(t`login.wrongEmailOrPassword`)
          } else if (message.includes('is in improper status')) {
            setError(t`login.unconfirmedEmail`)
          }
        }
      }
    },
    [login, t],
  )

  return (
    <Form
      layout="vertical"
      onFinish={handleOk}
      size="large"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Typography.Title
        className={commonStyles.fromTablet}
        style={{ marginBottom: '48px' }}
      >{t`welcome`}</Typography.Title>
      <Typography.Text
        style={{ padding: '8px 0', marginBottom: '16px', fontSize: '16px' }}
      >{t`login.loginTitle`}</Typography.Text>

      <FormInput
        label={t`email/login`}
        name="username"
        rules={[
          { required: true, message: t`required` },
          {
            pattern: new RegExp(EMAIL_REGEXP),
            message: t`wrongEmail`,
          },
        ]}
      />
      <FormInput
        label={t`password`}
        name="password"
        rules={[{ required: true, message: t`required` }]}
        type="password"
      />
      {!!error && (
        <Alert message={error} type="error" style={{ marginBottom: '16px' }} />
      )}
      <Flex justify="flex-end" style={{ marginBottom: '16px' }}>
        <Button
          type="link"
          onClick={() => changeLayout('forgot')}
        >{t`login.forgotPasswordText`}</Button>
      </Flex>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        style={{ width: '100%', marginBottom: '16px' }}
      >
        {t`login.logIn`}
      </Button>
      <Space style={{ gap: '4px' }}>
        <Typography.Text>{t`login.or`}</Typography.Text>
        <Button
          type="link"
          size="small"
          onClick={() => changeLayout('registration')}
        >{t`login.signIn`}</Button>
      </Space>
    </Form>
  )
}
