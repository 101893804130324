import dayjs, { Dayjs } from 'dayjs'
import { SessionDto } from '@/openapi-api/api'
import { CSSProperties } from 'react'

interface GroupedByHour {
  hour: number
  items: SessionDto[]
}

export function groupByDayAndHour(dates: SessionDto[]): GroupedByHour[] {
  const filteredDates = dates

  const groupedByHour: Record<number, SessionDto[]> = {}

  filteredDates.forEach((dateTime) => {
    const hour = dayjs(dateTime.startTime).hour() // Получаем час

    if (!groupedByHour[hour]) {
      groupedByHour[hour] = []
    }

    groupedByHour[hour].push(dateTime)
  })

  const result: GroupedByHour[] = []
  for (let hour = 0; hour < 24; hour++) {
    result.push({
      hour,
      items: groupedByHour[hour] || [],
    })
  }

  const firstNonEmptyIndex = result.findIndex((group) => group.items.length > 0)
  const lastNonEmptyIndex = result
    .slice()
    .reverse()
    .findIndex((group) => group.items.length > 0)

  const trimmedResult = result.slice(
    firstNonEmptyIndex,
    result.length - lastNonEmptyIndex,
  )
  if (trimmedResult.some((r) => r.items.length)) return trimmedResult
  return []
}

export function formatTimeRange(
  startTime: Dayjs,
  durationInMinutes: number,
  onlyStart = false,
): string {
  const endTime = startTime.add(durationInMinutes, 'minute')

  const formatTime = (time: Dayjs): string => {
    const hours = time.format('H')
    return `${hours}:${time.format('mm')}`
  }

  const startFormatted = formatTime(startTime)
  const endFormatted = formatTime(endTime)

  return onlyStart ? startFormatted : `${startFormatted}-${endFormatted}`
}

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'

type Period = 'morning' | 'afternoon' | 'evening'
type WeekDay =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY'

export type GroupedByDay = {
  [day in WeekDay]: SessionDto[]
}

interface GroupedByPeriod {
  morning: GroupedByDay
  afternoon: GroupedByDay
  evening: GroupedByDay
}

export const days: WeekDay[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

function getPeriod(hour: number): Period {
  if (hour < 13) {
    return 'morning'
  } else if (hour < 18) {
    return 'afternoon'
  } else {
    return 'evening'
  }
}

function getWeekDay(date: dayjs.Dayjs): WeekDay {
  const dayOfWeek = date.day()
  return days[(dayOfWeek + 6) % 7] // Приведение дня недели к порядку от Понедельника
}

function initializeGroupedByDay(): GroupedByDay {
  return days.reduce((acc, day) => {
    acc[day] = []
    return acc
  }, {} as GroupedByDay)
}

// Функция для инициализации объекта GroupedByPeriod
function initializeGroupedByPeriod(): GroupedByPeriod {
  return {
    morning: initializeGroupedByDay(),
    afternoon: initializeGroupedByDay(),
    evening: initializeGroupedByDay(),
  }
}

export function groupEventsByPeriodAndWeekDay(
  events: SessionDto[],
): GroupedByPeriod {
  const groupedByPeriod = initializeGroupedByPeriod()
  events.forEach((event) => {
    const date = dayjs(event.startTime)
    const weekDay = getWeekDay(date)

    const period = getPeriod(date.hour())

    groupedByPeriod[period][weekDay].push(event)
  })

  return groupedByPeriod
}

export function generateCalendar(month: number, year: number) {
  const firstDayOfMonth = dayjs().year(year).month(month).startOf('month')

  const lastDayOfMonth = dayjs().year(year).month(month).endOf('month')

  const startCalendar =
    firstDayOfMonth.weekday() === 0
      ? firstDayOfMonth
      : firstDayOfMonth.weekday(0)

  const endCalendar =
    lastDayOfMonth.weekday() === 6 ? lastDayOfMonth : lastDayOfMonth.weekday(6)

  const daysInCalendar = []
  let currentDay = startCalendar

  while (currentDay.isSameOrBefore(endCalendar)) {
    daysInCalendar.push(currentDay)
    currentDay = currentDay.add(1, 'day')
  }

  return daysInCalendar
}
interface GroupedSessions {
  date: number
  sessions: SessionDto[]
}

export function groupAndSortSessions(
  sessions: SessionDto[],
): GroupedSessions[] {
  const groupedSessions = sessions.reduce(
    (acc, session) => {
      if (session.startTime) {
        const date = dayjs(session.startTime).startOf('day').valueOf()
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(session)
      }
      return acc
    },
    {} as Record<number, SessionDto[]>,
  )

  return Object.entries(groupedSessions)
    .map(([date, sessions]) => ({
      date: Number(date),
      sessions: sessions.sort(
        (a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix(),
      ),
    }))
    .sort((a, b) => a.date - b.date)
}

export const generateCardStyles = (
  startTime?: Dayjs,
  duration?: number,
): CSSProperties =>
  startTime?.add(duration as number, 'minutes').isBefore(dayjs())
    ? { opacity: 0.5, pointerEvents: 'none' }
    : { opacity: 1 }
