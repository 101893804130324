import { Avatar, Flex, Space, Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import { StudentDto, TournamentDto } from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'

import dayjs from 'dayjs'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { GlobalOutlined } from '@ant-design/icons'
import ImportStudents from '@pages/admin/students/ImportStudentsButton'

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.phone?.length) {
    query.push(`phone~~${filters.phone[0]}`)
  }
  if (filters?.email?.length) {
    query.push(`email~~${filters.email[0]}`)
  }

  return query.join(';') || undefined
}

const PAGE_SIZE = 10

const AdminTournamentsStudents = () => {
  const { t } = useTranslation()
  const { studentsAdminControllerApi } = useApi()
  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)

  const { resource: students, fetch } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        studentsAdminControllerApi.find(
          (tableParams.pagination?.current || 1) - 1,
          tableParams.pagination?.pageSize || PAGE_SIZE,
          doSort(tableParams, { dates: 'dateStart' }),
          doQuery(tableParams),
        ),
      [studentsAdminControllerApi, tableParams],
    ),
  })

  const searchColumn = useSearchColumn<TournamentDto>()

  const columns: ColumnsType<StudentDto> = useMemo(
    () => [
      {
        title: t`teacher.fio`,
        dataIndex: 'name',
        ...searchColumn('name'),
        filteredValue: tableParams.filters?.name || null,
        render: (_, entity) => (
          <Space size="middle">
            <Avatar
              shape="circle"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.thumbLink || <GlobalOutlined />}
            />
            <Flex vertical>
              <Typography>{entity.name}</Typography>
              <Typography>
                {entity.birthDate
                  ? dayjs(entity.birthDate).format('DD MMM, YYYY')
                  : ''}
              </Typography>
            </Flex>
          </Space>
        ),
      },
      {
        title: t`profile.phone`,
        dataIndex: 'phone',
        ...searchColumn('phone'),
        filteredValue: tableParams.filters?.phone || null,
      },
      {
        title: t`profile.email`,
        dataIndex: 'email',
        ...searchColumn('email'),
        filteredValue: tableParams.filters?.email || null,
      },

      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => <Space size="middle"></Space>,
      },
    ],
    [
      searchColumn,
      t,
      tableParams.filters?.email,
      tableParams.filters?.name,
      tableParams.filters?.phone,
    ],
  )

  return (
    <Flex vertical>
      <Typography.Title level={5}>{t`students`}</Typography.Title>
      <ImportStudents refresh={fetch} />
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() || ''}
        dataSource={students?.data.content || []}
        onChange={handleTableChange}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default AdminTournamentsStudents
