import { Button, Flex, Form, FormProps, Input, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import { CreateStudentGroupRequest } from '@/openapi-api/api'
import {
  useGetProfiles,
  useGetSchools,
  useGetSpecializations,
  useGetTeachers,
} from '@/utils/hooks/entities'
import {
  optionsFromEntity,
  optionsFromEntityNoId,
} from '@/utils/functions/optionsFromEntity'
import { FormColorPicker } from '@components/Form/FormColorPicker'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import { getValue } from '@/utils/functions/dataUtils'
import { FormAvatar } from '@components/Form/FormAvatar'
import GroupStudentsList from '@pages/groups/edit/GroupStudentsList'
import { Separator } from '@components/Separator'

const GroupEdit = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { studentGroupsAdminControllerApi } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs, refreshTour } = useGlobalContext()
  const { appLang } = useLanguageContext()
  const { id } = useParams()
  const profileId = Form.useWatch('profileId', form)
  const schoolId = Form.useWatch('schoolId', form)
  const profiles = useGetProfiles()
  const schools = useGetSchools()
  const specializations = useGetSpecializations(getValue(profileId) as string)

  const teachers = useGetTeachers(getValue(schoolId) as string)
  const { getLocaleField } = useLocaleHelpers()

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      studentGroupsAdminControllerApi
        .getByIdExtended(+(id ?? 0))
        .then(({ data }) => {
          setFormData({
            name: getLocaleField('namePL', data),
            description: getLocaleField('descriptionPL', data),
            profileId: data.profile
              ? {
                  label: getLocaleField('namePL', data.profile),
                  value: data.profile?.id,
                }
              : null,
            teacherId: data.teacher
              ? {
                  label: getLocaleField('namePL', data.teacher),
                  value: data.teacher?.id,
                }
              : null,
            schoolId: data.school
              ? {
                  label: getLocaleField('namePL', data.school),
                  value: data.school?.id,
                }
              : null,
            level: data.level,
            specialization: data.specialization?.split(', '),
            enrollmentRequired: data.enrollmentRequired,
            capacity: data.capacity,
            minAge: data.minAge,
            maxAge: data.maxAge,
            color: data.color,
            avatar: data.mediaFiles?.[0]
              ? [
                  {
                    uid: data.mediaFiles[0].id,
                    status: 'done',
                    url: data.mediaFiles[0].link,
                  },
                ]
              : null,
          })
          setReady(true)
        })
    }
  }, [getLocaleField, id, studentGroupsAdminControllerApi])

  const title = useMemo(() => formData.name || t`group.new`, [formData.name, t])

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateStudentGroupRequest = {
        [`name${appLang}`]: values.name,
        [`description${appLang}`]: values.description,
        profileId: values.profileId?.value || values.profileId,
        teacherId: values.teacherId?.value || values.teacherId,
        schoolId: Number(values.schoolId?.value || values.schoolId),
        level: values.level,
        specialization: values.specialization?.join(', '),
        enrollmentRequired: !!values.enrollmentRequired,
        capacity: values.capacity || null,
        minAge: values.minAge || null,
        maxAge: values.maxAge || null,
        color: values.color,
      }
      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await studentGroupsAdminControllerApi.create({
          ...dataToSend,
        })
        entityId = data.id as number
      } else {
        await studentGroupsAdminControllerApi.update(id as any, dataToSend)
      }
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (id === 'new' || !formData.avatar?.length) {
          await studentGroupsAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await studentGroupsAdminControllerApi.updateFile(
            entityId,
            formData.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && formData.avatar?.length) {
        await studentGroupsAdminControllerApi.deleteMediaFile(
          entityId,
          formData.avatar[0].uid as number,
        )
      }
      if (id === 'new') {
        refreshTour()
        navigate(routePaths.groups.all)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const formReset = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Space
        size="large"
        align="center"
        style={{ marginBottom: '24px', justifyContent: 'space-between' }}
      >
        <Button
          icon={<ArrowLeftOutlined />}
          iconPosition="start"
          onClick={() => navigate(routePaths.groups.all)}
        >
          {t`back`}
        </Button>
        <Button
          type="primary"
          disabled={id === 'new'}
          onClick={() => navigate(routePaths.groups.specific(id).schedule)}
        >{t`addToSchedule`}</Button>
      </Space>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          maxWidth: 480,
          width: '100%',
          margin: '0 auto',
        }}
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <FormInput
          label={t`group.name`}
          name="name"
          rules={[
            { required: true, message: t`required` },
            { max: 150, message: t`max150` },
          ]}
        />
        <Form.Item name="description" label={t`group.description`}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item label={t`group.ageFrom`} name="minAge">
          <Input type="number" />
        </Form.Item>
        <Form.Item label={t`group.ageTo`} name="maxAge">
          <Input type="number" />
        </Form.Item>
        <FormInput label={t`group.level`} name="level" />

        <Form.Item
          name="profileId"
          label={t`profile.profile`}
          rules={[
            {
              required: true,
              message: t`school.error.noProfile`,
            },
          ]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(profiles)}
          />
        </Form.Item>
        <Form.Item name="specialization" label={t`group.specialization`}>
          <Select
            mode="tags"
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntityNoId(specializations)}
          />
        </Form.Item>
        <Form.Item
          name="schoolId"
          label={t`school.school`}
          rules={[
            {
              required: true,
              message: t`required`,
            },
          ]}
        >
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(schools)}
          />
        </Form.Item>
        <Form.Item name="teacherId" label={t`group.teacher`}>
          <Select
            placeholder={t`school.pleaseSelect`}
            options={optionsFromEntity(teachers)}
          />
        </Form.Item>
        <Form.Item label={t`group.maxStudents`} name="capacity">
          <Input type="number" />
        </Form.Item>
        <FormColorPicker label={t`group.pickColor`} form={form} />
        <FormAvatar label={t`image`} form={form} />
        <Space size="large" style={{ justifyContent: 'center' }}>
          <Button type="text" onClick={formReset}>
            {t`reset`}
          </Button>
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Space>
      </Form>
      <Separator />
      <GroupStudentsList schoolId={formData.schoolId?.value} />
    </Flex>
  )
}

export default GroupEdit
