import { FC } from 'react'
import { Button, Flex, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { formatTimeRange } from '@pages/schedule/helpers'
import { useTranslation } from 'react-i18next'
import { UnionSessionDto } from '@pages/schedule/types'
import { getColor, getLevel, getName } from '@pages/groups/GroupsList'
import { useTeacherPopup } from '@/utils/hooks/useTeacherPopup'

interface Props {
  session: UnionSessionDto
  scheduleUser?: (session: UnionSessionDto) => Promise<void>
}

export const UserSessionCard: FC<Props> = ({ session, scheduleUser }) => {
  const startTime = dayjs(session.startTime)
  const { t } = useTranslation()

  const { openTeacherModal, modal } = useTeacherPopup()

  return (
    <Flex
      style={{
        opacity: startTime
          .add(session.duration as number, 'minutes')
          .isBefore(dayjs())
          ? 0.5
          : 1,
        pointerEvents: startTime.isBefore(dayjs()) ? 'none' : 'auto',
        padding: '16px',
        backgroundColor: getColor(session),
        borderRadius: '4px',
        gap: '8px',
      }}
      vertical
    >
      <Space align="center">
        <Typography style={{ fontWeight: 600 }}>
          {formatTimeRange(startTime, session.duration as number)}
        </Typography>
        <Typography style={{ fontWeight: 600 }}>
          {getName(session)} {getLevel(session)}
        </Typography>
      </Space>

      <Flex justify="space-between">
        <Button type="link" style={{ padding: 0, height: 'auto' }}>
          {session.room?.place?.address}
        </Button>
        <Button
          type="link"
          style={{ padding: 0, height: 'auto' }}
          onClick={() => openTeacherModal(session.teacher?.id)}
        >
          {session.teacher?.name}
        </Button>
      </Flex>
      {!session.enrollment ? (
        <Flex justify="center">
          <Button
            type="primary"
            onClick={() => scheduleUser?.(session)}
          >{t`schedule.enrollStudents`}</Button>
        </Flex>
      ) : (
        <Flex justify="space-between" align="center">
          {session.enrollment.student?.name}
          <Button
            onClick={() => scheduleUser?.(session)}
          >{t`schedule.removeStudent`}</Button>
        </Flex>
      )}
      {modal}
    </Flex>
  )
}
