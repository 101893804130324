import dayjs from 'dayjs'

export const startDateInFuture = (_: any, value: dayjs.Dayjs[]) => {
  if (!value || !value[0]) {
    return Promise.resolve()
  }
  const startDate = value[0]
  const today = dayjs().startOf('day')

  if (startDate.isBefore(today)) {
    return Promise.reject()
  }

  return Promise.resolve()
}
