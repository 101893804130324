export const Here = () => (
  <div
    style={{
      width: '100%',
      backgroundColor: '#1677FF',
      height: '2px',
      position: 'relative',
      marginBottom: '6px',
    }}
  >
    <div
      style={{
        backgroundColor: '#1677FF',
        width: '12px',
        height: '12px',
        position: 'absolute',
        left: '-6px',
        top: '-5px',
        borderRadius: '6px',
      }}
    />
  </div>
)
