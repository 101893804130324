import { FC, useMemo } from 'react'
import {
  SendUserNotificationRequestTypeEnum,
  SessionDto,
} from '@/openapi-api/api'
import { Button, Dropdown, Flex, MenuProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { formatTimeRange } from '@pages/schedule/helpers'
import { useTranslation } from 'react-i18next'
import { MoreOutlined } from '@ant-design/icons'
import {
  getColor,
  getLevel,
  getName,
  renderAge,
} from '@pages/groups/GroupsList'
import useIsMobile from '@/utils/hooks/useIsMobile'
import { routePaths } from '@/routes'
import { Link, useNavigate } from 'react-router-dom'
import { useSendNotification } from '@/utils/hooks/useSendNotification'
import { useEventCancel } from '@/utils/hooks/useEventCancel'

interface Props {
  session: SessionDto
  refresh: () => Promise<void>
}

export const SessionCardWeekView: FC<Props> = ({ session, refresh }) => {
  const startTime = dayjs(session.startTime)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile, isBothMobile } = useIsMobile()
  const { modal, setData } = useSendNotification()
  const cancel = useEventCancel(refresh)

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'attendance',
        label: (
          <Link
            to={routePaths.attendance.specific(session.id)}
          >{t`schedule.presence`}</Link>
        ),
      },
      {
        key: 'edit',
        label: <Link to={routePaths.scheduleEdit(session.id)}>{t`Edit`}</Link>,
      },
      {
        key: 'send',
        label: (
          <Link
            to={''}
            onClick={() =>
              setData({
                schoolId: session.room?.place?.schoolId!,
                entityId: session.id!,
                type: SendUserNotificationRequestTypeEnum.SESSION,
              })
            }
          >{t`notifications.sendMessage`}</Link>
        ),
      },
      {
        key: 'cancelEvent',
        label: (
          <Link
            to=""
            onClick={() => cancel(session.id)}
          >{t`schedule.cancelEvent`}</Link>
        ),
      },
    ],
    [cancel, session.id, session.room?.place?.schoolId, setData, t],
  )

  return (
    <Flex
      style={{
        opacity: startTime
          .add(session.duration as number, 'minutes')
          .isBefore(dayjs())
          ? 0.5
          : 1,
        padding: isBothMobile ? '2px 4px' : '16px',
        backgroundColor: getColor(session),
        borderRadius: '4px',
        gap: '8px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      vertical
    >
      <Flex justify="space-between" align="center">
        <Typography style={{ fontWeight: 600 }}>
          {formatTimeRange(startTime, session.duration as number, isBothMobile)}
        </Typography>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </Flex>
      <Typography style={{ fontWeight: 600 }}>{getName(session)}</Typography>
      {!isMobile && (
        <Flex justify="space-between" gap={8}>
          {renderAge(session, true) && (
            <Typography>
              {renderAge(session, true)} {t`years`}
            </Typography>
          )}
          {getLevel(session) && <Typography>{getLevel(session)}</Typography>}
        </Flex>
      )}
      <Flex vertical gap="4px" justify="flex-start" align="start">
        <Button
          type="link"
          style={{ padding: 0, height: 'auto' }}
          onClick={() =>
            navigate(routePaths.teachers.specific(session.teacher?.id).view)
          }
        >
          {session.teacher?.name}
        </Button>
        <Typography>{session.room?.place?.address}</Typography>
        <Typography>{session.room?.name}</Typography>
      </Flex>
      {modal}
    </Flex>
  )
}
