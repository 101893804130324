import { Flex, Radio, Typography } from 'antd'
import styles from '@pages/login/index.module.less'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import GroupSchedule from '@pages/groups/schedule/GroupSchedule'
import SingleSchedule from '@pages/groups/schedule/SingleSchedule'

const NewSchedule = () => {
  const { t } = useTranslation()
  const [mode, setMode] = useState('group')

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '24px' }} justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          {t(mode === 'group' ? 'event.addGroup' : 'event.addSingle')}
        </Typography.Title>
        <Radio.Group
          buttonStyle="solid"
          onChange={(e) => setMode(e.target.value)}
          value={mode}
        >
          <Radio.Button
            value="single"
            className={styles.radioItem}
          >{t`event.single`}</Radio.Button>
          <Radio.Button
            value="group"
            className={styles.radioItem}
          >{t`group.group`}</Radio.Button>
        </Radio.Group>
      </Flex>
      {mode === 'group' && <GroupSchedule minimal />}
      {mode === 'single' && <SingleSchedule />}
    </Flex>
  )
}

export default NewSchedule
