import { useCallback } from 'react'
import { Flex, Modal, Radio, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  UpdateSessionRequestStatusEnum,
  UpdateSessionRequestUpdateTypeEnum,
} from '@/openapi-api/api'
import useApi from '@/contexts/api'

export const useEventCancel = (refresh: () => void) => {
  const { t } = useTranslation()
  const { sessionsAdminControllerApi } = useApi()

  const cancelEvent = useCallback(
    (id?: number) => {
      let selectedRadio = UpdateSessionRequestUpdateTypeEnum.SINGLE
      const { destroy } = Modal.confirm({
        title: t`schedule.cancelPopupTitle`,
        icon: null,
        content: (
          <Flex vertical>
            <Typography.Text>{t`schedule.cancelPopupDescription`}</Typography.Text>
            <Radio.Group
              onChange={(e) => {
                selectedRadio = e.target.value
              }}
              name="type"
              defaultValue={UpdateSessionRequestUpdateTypeEnum.SINGLE}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Radio value={UpdateSessionRequestUpdateTypeEnum.SINGLE}>
                {t`schedule.toOneSession`}
              </Radio>
              <Radio value={UpdateSessionRequestUpdateTypeEnum.ALL}>
                {t`schedule.toAllSession`}
              </Radio>
              <Radio value={UpdateSessionRequestUpdateTypeEnum.ONGOING}>
                {t`schedule.toOngoingSession`}
              </Radio>
            </Radio.Group>
          </Flex>
        ),
        onOk: async () => {
          destroy()
          await sessionsAdminControllerApi.update(id as number, {
            updateType: selectedRadio,
            status: UpdateSessionRequestStatusEnum.CANCELED,
          })
          refresh()
        },
      })
    },
    [refresh, sessionsAdminControllerApi, t],
  )

  return cancelEvent
}
