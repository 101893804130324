import { Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props {
  name?: string
  label: string
  required?: boolean
}

export const FormPhoneInput: FC<Props> = ({
  name = 'phone',
  label,
  required = true,
}) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[...(required ? [{ required: true, message: t`required` }] : [])]}
    >
      <PhoneInput placeholder="" country="pl" />
    </Form.Item>
  )
}
