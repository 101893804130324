import { Button, Flex, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FC, Fragment, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { groupByDayAndHour } from '@pages/schedule/helpers'
import useIsDevice from '@/utils/hooks/useIsMobile'
import { Dates } from '@pages/schedule/types'
import { DayTopRow } from '@pages/schedule/DayTopRow'
import { Here } from '@pages/schedule/Here'
import { SessionAttendanceViewDto, SessionDto } from '@/openapi-api/api'
import { AttendanceSessionCard } from './AttendanceSessionCard'

interface Props {
  sessions: SessionAttendanceViewDto[]
  dates: Dates
  setDates: (dates: Dates) => void
  isUserView?: boolean
}

interface GroupedByHourAttendance {
  hour: number
  items: SessionAttendanceViewDto[]
}

const AttendanceDayView: FC<Props> = ({ sessions, dates, setDates }) => {
  const { t } = useTranslation()
  const isToday = useMemo(() => dates[0].isToday(), [dates])
  const { isMobile } = useIsDevice()
  const dataToRender = useMemo(
    () =>
      groupByDayAndHour(
        sessions as never as SessionDto[],
      ) as never as GroupedByHourAttendance[],
    [sessions],
  )

  const detectCurrentTime = useCallback(
    (hour: number) => {
      if (!isToday) return false
      const startHour = dayjs().hour(hour).startOf('hour')
      const endHour = startHour.endOf('hour')
      const currentTime = dayjs()
      return currentTime.isAfter(startHour) && currentTime.isBefore(endHour)
    },
    [isToday],
  )

  return (
    <Flex vertical>
      <Flex justify="center" style={{ marginBottom: '16px' }}>
        <Space
          align="center"
          style={{ justifyContent: !isMobile ? 'center' : 'space-between' }}
          size={isMobile ? 'small' : 'large'}
        >
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              setDates([
                dates[0].subtract(1, 'day'),
                dates[1].subtract(1, 'day'),
              ])
            }
          />
          <Typography.Title level={4} style={{ margin: 0 }}>
            {dates[0].format('DD MMMM YYYY')}
          </Typography.Title>
          <Button
            onClick={() =>
              setDates([dayjs().startOf('day'), dayjs().endOf('day')])
            }
          >{t`schedule.today`}</Button>
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() =>
              setDates([dates[0].add(1, 'day'), dates[1].add(1, 'day')])
            }
          />
        </Space>
      </Flex>
      <DayTopRow day={dates[0]} />
      {dataToRender.map(({ hour, items }) => (
        <Fragment key={hour}>
          <Flex vertical gap="8px" style={{ marginBottom: '8px' }}>
            <Typography
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
            >{`${hour}:00`}</Typography>
          </Flex>
          <Flex
            gap={'8px'}
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              marginBottom: '8px',
            }}
          >
            {items.map((i) => (
              <AttendanceSessionCard key={i.id} session={i} />
            ))}
          </Flex>
          {detectCurrentTime(hour) && <Here />}
        </Fragment>
      ))}
    </Flex>
  )
}

export default AttendanceDayView
