import { Dayjs } from 'dayjs'
import { SessionDto, StudentSessionEnrollmentViewDto } from '@/openapi-api/api'

export enum SCHEDULE_TYPES {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type Dates = [Dayjs, Dayjs]

export interface UnionSessionDto extends SessionDto {
  enrollment?: StudentSessionEnrollmentViewDto
}
