import { Button, Drawer, Flex, Space, Tag, Typography } from 'antd'
import { Dates } from '@pages/schedule/types'
import { useCallback, useMemo, useState } from 'react'
import { parseFiltersFormUrl } from '@/utils/functions/filterUtils'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { FilterTwoTone, FilterOutlined } from '@ant-design/icons'
import {
  useGetAllGroups,
  useGetAllLocations,
  useGetAllTeachers,
  useGetSchools,
} from '@/utils/hooks/entities'
import { optionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { FilterSelect } from '@pages/schedule/FilterSelect'
import useIsMobile from '@/utils/hooks/useIsMobile'
import AttendanceSchedule from '@pages/attendance/AttendanceSchedule'

const AttendanceWrapper = () => {
  const { isMobile } = useIsMobile()
  const [schools, setSchools] = useState<string[]>(
    parseFiltersFormUrl().schools || [],
  )
  const [open, setOpen] = useState(false)
  const [locations, setLocations] = useState<string[]>([])
  const [teacher, setTeacher] = useState<string[]>(
    parseFiltersFormUrl().teachers || [],
  )
  const [group, setGroup] = useState<string[]>([])
  const schoolOptions = useGetSchools()
  const locationOptions = useGetAllLocations()
  const teacherOptions = useGetAllTeachers()
  const groupOptions = useGetAllGroups()

  const hasFilters = useMemo(
    () =>
      !!(schools.length || locations.length || teacher.length || group.length),
    [group.length, locations.length, schools.length, teacher.length],
  )

  const [dates, setDates] = useState<Dates>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ])
  const { t } = useTranslation()

  const clearFilters = useCallback(() => {
    setLocations([])
    setSchools([])
    setGroup([])
    setTeacher([])
  }, [])

  const tagFilters = useMemo(
    () =>
      hasFilters ? (
        <Flex
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: isMobile ? '1fr' : '1fr auto',
            marginBottom: '21px',
            marginTop: isMobile ? '21px' : 0,
          }}
        >
          <Space style={{ flexWrap: 'wrap' }}>
            {!isMobile && <Typography>{t`filters`}</Typography>}
            {schools.map((schoolId) => (
              <Tag
                bordered={false}
                closable
                key={`school-${schoolId}`}
                onClose={() =>
                  setSchools((old) => old.filter((id) => id !== schoolId))
                }
              >
                {t`school.school`}
                {': '}
                {schoolOptions.find(({ id }) => id === +schoolId)?.name}
              </Tag>
            ))}
            {locations.map((locationId) => (
              <Tag
                bordered={false}
                closable
                key={`location-${locationId}`}
                onClose={() =>
                  setLocations((old) => old.filter((id) => id !== locationId))
                }
              >
                {t`location.location`}
                {': '}
                {locationOptions.find(({ id }) => id === +locationId)?.name}
              </Tag>
            ))}
            {teacher.map((teacherId) => (
              <Tag
                bordered={false}
                closable
                key={`teacher-${teacherId}`}
                onClose={() =>
                  setTeacher((old) => old.filter((id) => id !== teacherId))
                }
              >
                {t`group.teacher`}
                {': '}
                {teacherOptions.find(({ id }) => id === +teacherId)?.name}
              </Tag>
            ))}
            {group.map((groupId) => (
              <Tag
                bordered={false}
                closable
                key={`group-${groupId}`}
                onClose={() =>
                  setGroup((old) => old.filter((id) => id !== groupId))
                }
              >
                {t`group.group`}
                {': '}
                {groupOptions.find(({ id }) => id === +groupId)?.name}
              </Tag>
            ))}
          </Space>
          {!isMobile && (
            <Button onClick={clearFilters}>{t`clearFilter`}</Button>
          )}
        </Flex>
      ) : null,
    [
      clearFilters,
      group,
      groupOptions,
      hasFilters,
      isMobile,
      locationOptions,
      locations,
      schoolOptions,
      schools,
      t,
      teacher,
      teacherOptions,
    ],
  )

  const filters = useMemo(
    () => (
      <Flex
        style={{
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          gap: '8px',
        }}
      >
        <FilterSelect
          label={t`school.school`}
          options={optionsFromEntity(schoolOptions)}
          value={schools}
          onChange={setSchools}
        />
        <FilterSelect
          label={t`location.location`}
          options={optionsFromEntity(locationOptions)}
          value={locations}
          onChange={setLocations}
        />
        <FilterSelect
          label={t`teachers`}
          options={optionsFromEntity(teacherOptions)}
          value={teacher}
          onChange={setTeacher}
        />
        <FilterSelect
          label={t`groups`}
          options={optionsFromEntity(groupOptions)}
          value={group}
          onChange={setGroup}
        />
      </Flex>
    ),
    [
      group,
      groupOptions,
      isMobile,
      locationOptions,
      locations,
      schoolOptions,
      schools,
      t,
      teacher,
      teacherOptions,
    ],
  )

  return (
    <Flex vertical>
      <Flex style={{ marginBottom: '21px' }}>
        {isMobile && (
          <Flex justify="space-between">
            <Button
              icon={hasFilters ? <FilterTwoTone /> : <FilterOutlined />}
              ghost={hasFilters}
              type={hasFilters ? 'primary' : undefined}
              onClick={() => setOpen(true)}
            >{t`filters`}</Button>
            <Button onClick={clearFilters}>{t`clearFilter`}</Button>
          </Flex>
        )}
        {!isMobile && filters}
      </Flex>
      {tagFilters}
      <AttendanceSchedule
        dates={dates}
        locations={locations}
        schools={schools}
        teachers={teacher}
        groups={group}
        setDates={setDates}
      />
      <Drawer
        placement="right"
        width={'100%'}
        destroyOnClose
        open={open}
        closable={false}
      >
        {filters}
        {tagFilters}
        <Flex justify="space-between" gap="24px" style={{ marginTop: '16px' }}>
          <Button
            onClick={clearFilters}
            style={{ width: '100%' }}
          >{t`clearFilter`}</Button>
          <Button
            style={{ width: '100%' }}
            onClick={() => setOpen(false)}
            type="primary"
          >{t`show`}</Button>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default AttendanceWrapper
