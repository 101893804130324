import { ColumnsType } from 'antd/es/table'
import { useMemo, useState } from 'react'
import { Select } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import { useTranslation } from 'react-i18next'
export const useTableDynamicColumns = <T,>(columns: ColumnsType<T>) => {
  const { t } = useTranslation()
  const [values, setValues] = useState<string[]>(
    columns.map((t) => t.title as string),
  )

  const control = useMemo(
    () => (
      <Select
        value={values}
        className={styles.selectColumns}
        mode="multiple"
        style={{ width: '201px' }}
        options={columns.map((c) => ({ value: c.title }))}
        placeholder={t`manageColumns`}
        showSearch={false}
        suffixIcon={<MoreOutlined />}
        maxTagCount={0}
        onChange={setValues}
        tagRender={() => (
          <span style={{ marginLeft: '8px' }}>{t`manageColumns`}</span>
        )}
      />
    ),
    [columns, t, values],
  )

  const filteredColumns = useMemo(
    () => columns.filter(({ title }) => values.includes(title as string)),
    [columns, values],
  )

  return { control, filteredColumns }
}
