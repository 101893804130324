import { Upload, Image, UploadFile, UploadProps, GetProp, message } from 'antd'
import { FC, useState } from 'react'
import { RcFile } from 'antd/es/upload/interface'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const getBase64ToUpload = (
  file: UploadFile,
  toUpload = false,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL((file.originFileObj as RcFile) || file)
    reader.onload = () =>
      resolve(
        toUpload
          ? (reader.result as string)?.substring(
              (reader.result as string).indexOf(';base64,') + ';base64,'.length,
            )
          : (reader.result as string),
      )
    reader.onerror = (error) => reject(error)
  })

export const Uploader: FC<UploadProps & { length?: number }> = ({
  length,
  ...rest
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const { t } = useTranslation()

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64ToUpload(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{'Upload'}</div>
    </button>
  )

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif'
    if (!isJpgOrPng) {
      message.error(t`imageSupportedFormats`)
      return Upload.LIST_IGNORE
    }

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error(t`imageMaxWeight`)
      return Upload.LIST_IGNORE
    }

    return false
  }

  return (
    <>
      <Upload
        onPreview={handlePreview}
        beforeUpload={beforeUpload}
        listType="picture-card"
        {...rest}
      >
        {(rest.maxCount || 0) > (length || 0) && uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  )
}
