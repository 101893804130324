import React from 'react'
import LanguageProvider from './LanguageProvider'
import { ApiProvider } from '@/contexts/api'
import NotificationProvider from '@/contexts/NotificationProvider'
import AuthProvider from '@/contexts/AuthContext'
import GlobalContext from '@/contexts/GlobalContext'

interface ComposeProps {
  components: React.JSXElementConstructor<React.PropsWithChildren<any>>[]
  children: React.ReactNode
}

const Compose: React.FC<ComposeProps> = (props) => {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, ComponentItem) => {
        return <ComponentItem>{acc}</ComponentItem>
      }, children)}
    </>
  )
}

const SingletonProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <Compose
      components={[
        LanguageProvider,
        ApiProvider,
        NotificationProvider,
        AuthProvider,
        GlobalContext,
      ]}
    >
      {children}
    </Compose>
  )
}

export default SingletonProviders
