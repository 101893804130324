export const addFilterToUrl = (obj: Record<any, any>) =>
  `?startFilters=${JSON.stringify(obj)}`

export const parseFiltersFormUrl = (key?: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  const startFilters = urlParams.get('startFilters')
  if (!startFilters) return {}
  try {
    const data = JSON.parse(startFilters)
    if (key) {
      return Array.isArray(data[key])
        ? data[key].map((i: any) => String(i))
        : data[key]
    }
    return data
  } catch {
    return {}
  }
}
