import { useNavigate, useParams } from 'react-router-dom'
import useApi from '@/contexts/api'
import { useCallback, useMemo } from 'react'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import {
  Avatar,
  Button,
  Descriptions,
  Flex,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import {
  CreateSchoolTournamentRequestStatusEnum,
  SchoolDto,
  StudentDto,
} from '@/openapi-api/api'
import { GlobalOutlined } from '@ant-design/icons'
import { renderStatusBadge } from '@/utils/functions/renderStatusBadge'
import dayjs from 'dayjs'

export const TournamentInvite = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    tournamentsAdminControllerApi,
    schoolTournamentsAdminControllerApi,
    schoolsAdminControllerApi,
  } = useApi()

  const { resource: tournament } = useAsyncResource({
    fetchResource: useCallback(
      () => tournamentsAdminControllerApi.getById(+(id as string)),
      [id, tournamentsAdminControllerApi],
    ),
  })

  const { resource: schools } = useAsyncResource({
    fetchResource: useCallback(
      () => schoolsAdminControllerApi.find(0, 1000),
      [schoolsAdminControllerApi],
    ),
  })

  const { resource: enrollments, fetch: refreshEnrollments } = useAsyncResource(
    {
      fetchResource: useCallback(
        () =>
          schoolTournamentsAdminControllerApi.find(
            0,
            1000,
            undefined,
            `tournament.id~in~${id}`,
          ),
        [id, schoolTournamentsAdminControllerApi],
      ),
    },
  )

  const join = useCallback(
    async (school: SchoolDto) => {
      try {
        const { data } = await schoolTournamentsAdminControllerApi.create({
          tournamentId: +(id as string),
          schoolId: school.id as number,
          status: CreateSchoolTournamentRequestStatusEnum.ACCEPTED,
        })

        refreshEnrollments()
      } catch {}
    },
    [id, refreshEnrollments, schoolTournamentsAdminControllerApi],
  )

  const columns: TableColumnsType<StudentDto> = useMemo(
    () => [
      {
        title: t`school.name`,
        dataIndex: 'name',
        render: (_, entity) => (
          <Space size="middle">
            <Avatar
              shape="circle"
              style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}
              src={entity.mediaFiles?.[0]?.thumbLink || <GlobalOutlined />}
            />
            <Typography>{entity.name}</Typography>
          </Space>
        ),
      },
      {
        title: t`invite.status`,
        dataIndex: 'status',
        render: (_, entity) => {
          const enrollStatus = enrollments?.data.content?.find(
            (e) => e.school?.id === entity.id,
          )?.status
          if (!enrollStatus)
            return (
              <Button onClick={() => join(entity)}>{t`invite.join`}</Button>
            )

          return renderStatusBadge((enrollStatus as any).toLowerCase(), t)
        },
      },
    ],
    [enrollments?.data.content, join, t],
  )
  return (
    <Flex vertical align="center" id="invite">
      <Flex vertical style={{ maxWidth: '500px' }}>
        {tournament?.data.mediaFiles?.[0] && (
          <div style={{ margin: '-16px', width: 'calc(100% + 32px)' }}>
            <img
              style={{ aspectRatio: '16/9', objectFit: 'cover', width: '100%' }}
              src={tournament.data.mediaFiles[0].link}
            />
          </div>
        )}
        <Typography.Title
          level={4}
          style={{
            textAlign: 'center',
            marginTop: '30px',
            marginBottom: '15px',
          }}
        >
          {t('invite.welcome', { name: tournament?.data.name })}
        </Typography.Title>
        <Descriptions title="" bordered>
          {tournament?.data.name && (
            <Descriptions.Item label={t`group.name`} span={3}>
              {tournament.data.name}
            </Descriptions.Item>
          )}
          {tournament?.data.dateStart && (
            <Descriptions.Item label={t`event.start`} span={3}>
              {dayjs(tournament.data.dateStart).format('DD-MM-YYYY')}
            </Descriptions.Item>
          )}
          {tournament?.data.dateEnd && (
            <Descriptions.Item label={t`event.end`} span={3}>
              {dayjs(tournament.data.dateEnd).format('DD-MM-YYYY')}
            </Descriptions.Item>
          )}
          {tournament?.data.registrationDateEnd && (
            <Descriptions.Item label={t`tournaments.registrationEnd`} span={3}>
              {dayjs(tournament.data.registrationDateEnd).format('DD-MM-YYYY')}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={t`address`} span={3}>
            {[
              tournament?.data.country?.name,
              tournament?.data.city?.name,
              tournament?.data.address,
            ]
              .filter(Boolean)
              .join(', ')}
          </Descriptions.Item>
          {tournament?.data.phone && (
            <Descriptions.Item label={t`profile.phone`} span={3}>
              <a href={`tel:${tournament.data.phone}`}>
                {tournament.data.phone}
              </a>
            </Descriptions.Item>
          )}
          {tournament?.data.email && (
            <Descriptions.Item label={t`profile.email`} span={3}>
              <a href={`mailto:${tournament.data.email}`}>
                {tournament.data.email}
              </a>
            </Descriptions.Item>
          )}
        </Descriptions>
        <Table
          columns={columns}
          dataSource={schools?.data.content || []}
          style={{ marginTop: '16px' }}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </Flex>
    </Flex>
  )
}
