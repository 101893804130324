import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Select,
  Switch,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@components/Form/FormInput'
import useApi from '@/contexts/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { routePaths } from '@/routes'
import { FormPhoneInput } from '@components/Form/PhoneInput'
import { getBase64ToUpload } from '@components/Uploader'
import { Loading } from '@components/Loading'
import {
  CreateStudentRequest,
  CreateTeacherRequestGenderEnum,
  UpdateStudentRequest,
} from '@/openapi-api/api'
import { FormAvatar } from '@components/Form/FormAvatar'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { useLocaleHelpers } from '@/utils/hooks/useLocaleHelpers'
import dayjs from 'dayjs'
import { EMAIL_REGEXP, NO_DIGITS } from '@/utils/constants'
import { Separator } from '@components/Separator'
import { DeleteOutlined } from '@ant-design/icons'
import { useFormReset } from '@/utils/hooks/useFormReset'
import { disableDatesInFuture } from '@/utils/functions/dateUtils'

const EditStudent = () => {
  const [submitting, setSubmitting] = useState(false)
  const [ready, setReady] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { appLang } = useLanguageContext()
  const [searchParams] = useSearchParams()

  const { studentsAdminControllerApi } = useApi()
  const [form] = Form.useForm()
  const { setBreadcrumbs } = useGlobalContext()
  const { id } = useParams()
  const { getLocaleField } = useLocaleHelpers()
  const isCreate = useMemo(() => id === 'new', [id])

  useEffect(() => {
    if (id === 'new') {
      setReady(true)
    } else {
      studentsAdminControllerApi
        .getByIdExtended(+(id || 0))
        .then(({ data }) => {
          setFormData({
            name: getLocaleField('namePL', data),
            comments: getLocaleField('commentsPL', data),
            email: data.email,
            birthDate: data.birthDate ? dayjs(data.birthDate) : '',
            gender: data.gender,
            phone: data.phone,
            avatar: data.mediaFiles?.[0]
              ? [
                  {
                    uid: data.mediaFiles[0].id,
                    status: 'done',
                    url: data.mediaFiles[0].link,
                  },
                ]
              : null,
          })
          setReady(true)
        })
    }
  }, [getLocaleField, id, studentsAdminControllerApi])

  const title = useMemo(
    () => formData.name || t`student.new`,
    [formData.name, t],
  )

  useEffect(() => {
    setBreadcrumbs([
      {
        name: title,
        href: '',
      },
    ])
  }, [setBreadcrumbs, title])

  const deleteProfile = useCallback(() => {
    Modal.confirm({
      title: t`profile.deleteActionConfirm`,
      onOk: async () => {
        await studentsAdminControllerApi.deleteById(+(id || 0))
        navigate(routePaths.user.students.all)
      },
    })
  }, [id, navigate, studentsAdminControllerApi, t])

  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    setSubmitting(true)
    try {
      const dataToSend: CreateStudentRequest = {
        [`name${appLang}`]: values.name,
        [`comments${appLang}`]: values.comments,
        email: values.email,
        gender: values.gender,
        phone: values.phone,
        birthDate: values.birthDate.format('YYYY-MM-DD'),
      }

      let entityId = +(id || 0)
      if (id === 'new') {
        const { data } = await studentsAdminControllerApi.create({
          ...dataToSend,
        })
        entityId = data.id as number
      } else {
        await studentsAdminControllerApi.update(
          id as any,
          dataToSend as UpdateStudentRequest,
        )
      }
      if (values.avatar?.length && !values.avatar[0].url) {
        const file = await getBase64ToUpload(values.avatar[0], true)

        if (id === 'new' || !formData.avatar?.length) {
          await studentsAdminControllerApi.createMediaFile(entityId, {
            fileContent: file as any,
            fileName: values.avatar[0].name,
          })
        } else {
          await studentsAdminControllerApi.updateFile(
            entityId,
            formData.avatar?.[0]?.uid as number,
            {
              fileContent: file as any,
              fileName: values.avatar[0].name,
            },
          )
        }
      }
      if (!values.avatar?.length && formData.avatar?.length) {
        await studentsAdminControllerApi.deleteMediaFile(
          entityId,
          formData.avatar[0].uid as number,
        )
      }
      if (id === 'new') {
        const fromSchool = searchParams.get('fromSchool')
        if (fromSchool) {
          navigate(routePaths.schoolInvite(fromSchool))
        } else {
          navigate(routePaths.user.students.all)
        }
      }
    } finally {
      setSubmitting(false)
    }
  }

  const resetData = useFormReset(form)

  if (!ready) return <Loading />

  return (
    <Flex vertical>
      <Flex justify="center" style={{ marginBottom: '24px' }}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {id === 'new'
            ? t`student.newTitle`
            : `${t`profile.profile`} ${title}`}
        </Typography.Title>
      </Flex>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        }}
        size="large"
        disabled={submitting}
        form={form}
        initialValues={{ ...formData }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
      >
        <FormAvatar label={t`student.photo`} form={form} fullWidth={false} />
        <FormInput
          label={t`teacher.fio`}
          name="name"
          placeholder={t`student.fioPlaceholder`}
          rules={[
            { required: true, message: t`required` },
            { max: 50, message: t`max50` },
            { pattern: new RegExp(NO_DIGITS), message: t`enterCorrectValue` },
          ]}
        />
        <Form.Item
          label={t`student.birthDate`}
          name="birthDate"
          rules={[{ required: true, message: t`required` }]}
        >
          <DatePicker
            needConfirm={false}
            style={{ width: '100%' }}
            placeholder="YYYY-MM-DD"
            disabledDate={disableDatesInFuture}
          />
        </Form.Item>
        <Form.Item
          name="gender"
          label={t`teacher.gender`}
          rules={[{ required: true, message: t`required` }]}
        >
          <Select
            options={[
              {
                value: CreateTeacherRequestGenderEnum.FEMALE,
                label: t`teacher.genderWoman`,
              },
              {
                value: CreateTeacherRequestGenderEnum.MALE,
                label: t`teacher.genderMan`,
              },
              {
                value: CreateTeacherRequestGenderEnum.OTHER,
                label: t`teacher.genderOther`,
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="comments" label={t`student.comments`}>
          <Input.TextArea
            rows={2}
            placeholder={t`student.commentsDescription`}
          />
        </Form.Item>
        <FormPhoneInput label={t`login.phone`} required={false} />
        <FormInput
          name="email"
          label={t`profile.email`}
          rules={[
            {
              pattern: new RegExp(EMAIL_REGEXP),
              message: t`school.wrongEmail`,
            },
          ]}
        />
        <Form.Item
          name="createLoginCredentials"
          label={t`student.canLogin`}
          valuePropName="checked"
          className="wrap-label"
          colon={false}
          style={{ minHeight: '22px' }}
        >
          <Switch size="default" />
        </Form.Item>
        {isCreate && (
          <Form.Item
            name="disabled"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t`login.privacyPolicyShouldAccept`),
                      ),
              },
            ]}
          >
            <Checkbox>{t`student.agreeWitForm`}</Checkbox>
          </Form.Item>
        )}
        <Flex gap={24} justify="center">
          {!isCreate && (
            <Button type="text" onClick={resetData}>
              {t`reset`}
            </Button>
          )}
          <Button type="primary" htmlType="submit">
            {t`save`}
          </Button>
        </Flex>
      </Form>
      {!isCreate && (
        <>
          <Separator />
          <Flex vertical align="center" gap={16}>
            <Typography.Text
              strong
              style={{ fontSize: '16px' }}
            >{t`profile.delete`}</Typography.Text>
            <Button
              icon={<DeleteOutlined />}
              onClick={deleteProfile}
            >{t`profile.deleteAction`}</Button>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default EditStudent
