import { Button, Flex, Space, Table, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { routePaths } from '@/routes'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { useCallback, useMemo } from 'react'
import { ColumnsType } from 'antd/es/table'
import {
  ProfileDto,
  SchoolDto,
  SessionDto,
  SessionExtendedDto,
  StudentGroupDto,
  TeacherDto,
} from '@/openapi-api/api'
import { useSearchColumn } from '@/utils/hooks/useSearchColumn'
import { doSort, useTableFilters } from '@/utils/hooks/useTableFilters'
import { TableParams } from '@/utils/types'
import { filterOptionsFromEntity } from '@/utils/functions/optionsFromEntity'
import { useGetAllTeachers, useGetProfiles } from '@/utils/hooks/entities'

const PAGE_SIZE = 6

export const getLevel = (session: SessionDto) =>
  session.level || session.studentGroup?.level

export const getName = (session: SessionDto) =>
  session.name || session.studentGroup?.name

export const getColor = (session: SessionDto | SessionExtendedDto) =>
  session.color || session.studentGroup?.color

export const renderAge = (session: SessionDto, emptyIfNoData = false) => {
  let maxAge = 0
  let minAge = 0
  if (session.studentGroup) {
    maxAge = session.studentGroup.maxAge || 0
    minAge = session.studentGroup.minAge || 0
  } else {
    maxAge = session.maxAge || 0
    minAge = session.minAge || 0
  }

  if (!maxAge && !minAge) return emptyIfNoData ? '' : '-'
  if (maxAge && !minAge) return `<${maxAge}`
  if (!maxAge && minAge) return `${minAge}+`
  return `${minAge}-${maxAge}`
}

const doQuery = ({ filters }: TableParams) => {
  const query = []
  if (filters?.name?.length) {
    query.push(`name~~${filters.name[0]}`)
  }
  if (filters?.level?.length) {
    query.push(`level~~${filters.level[0]}`)
  }
  if (filters?.specialization?.length) {
    query.push(`specialization~~${filters.specialization[0]}`)
  }
  if (filters?.profile?.length) {
    query.push(`profile.id~in~${filters.profile.toString()}`)
  }
  if (filters?.teacher?.length) {
    query.push(`teacher.id~in~${filters.teacher.toString()}`)
  }
  return query.join(';') || undefined
}

const GroupsList = () => {
  const { t } = useTranslation()
  const { studentGroupsAdminControllerApi } = useApi()
  const navigate = useNavigate()

  const { tableParams, handleTableChange } = useTableFilters(PAGE_SIZE)
  const searchColumn = useSearchColumn<SchoolDto>()
  const profiles = useGetProfiles()
  const teachers = useGetAllTeachers()

  const { resource: entities } = useAsyncResource({
    fetchResource: useCallback(
      () =>
        studentGroupsAdminControllerApi.find(
          (tableParams.pagination?.current ?? 1) - 1,
          tableParams.pagination?.pageSize ?? PAGE_SIZE,
          doSort(tableParams),
          doQuery(tableParams),
        ),
      [studentGroupsAdminControllerApi, tableParams],
    ),
  })

  const columns: ColumnsType<StudentGroupDto> = useMemo(
    () => [
      {
        title: t`nazwa`,
        dataIndex: 'name',
        sorter: true,
        ...searchColumn('name'),
        render: (name, entity) => (
          <Link to={routePaths.groups.specific(entity.id).edit}>{name}</Link>
        ),
      },
      {
        title: t`group.age`,
        dataIndex: 'wiek',
        render: (_, entity) => renderAge(entity),
      },
      {
        title: t`group.studentsCount`,
        dataIndex: 'acceptedParticipants',
      },
      {
        title: t`group.level`,
        dataIndex: 'level',
        sorter: true,
        ...searchColumn('level'),
      },
      {
        title: t`profile.profile`,
        dataIndex: 'profile',
        filters: filterOptionsFromEntity(profiles),
        render: (profile: ProfileDto) => <Tag>{profile.name}</Tag>,
      },
      {
        title: t`group.teacher`,
        dataIndex: 'teacher',
        filters: filterOptionsFromEntity(teachers),
        render: (teacher: TeacherDto) => teacher?.name,
      },
      {
        title: t`teacher.specialization`,
        dataIndex: 'specialization',
        sorter: true,
        ...searchColumn('specialization'),
        render: (specialization: string) => (
          <Space size="middle">
            {specialization?.split(', ')?.map((p) => <Tag key={p}>{p}</Tag>)}
          </Space>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, entity) => (
          <Space size="middle">
            <Link
              to={routePaths.groups.specific(entity.id).schedule}
            >{t`addToSchedule`}</Link>
          </Space>
        ),
      },
    ],
    [profiles, searchColumn, t, teachers],
  )

  return (
    <Flex vertical>
      <div style={{ marginBottom: '24px' }}>
        <Button
          onClick={() => navigate(routePaths.groups.specific('new').edit)}
        >{t`group.add`}</Button>
      </div>
      <Typography.Title level={5}>{t`groups`}</Typography.Title>
      <Table
        columns={columns}
        rowKey={(record) => record.id?.toString() ?? ''}
        dataSource={entities?.data.content || []}
        onChange={handleTableChange}
        pagination={{
          ...tableParams.pagination,
          total: entities?.data.totalElements,
          position: ['bottomLeft'],
        }}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

export default GroupsList
