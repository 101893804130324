import { Flex } from 'antd'
import useApi from '@/contexts/api'
import { useAsyncResource } from '@/utils/hooks/useAsyncResource'
import { FC, useCallback, useRef } from 'react'
import { Dates } from '@pages/schedule/types'
import { DATE_FORMAT } from '@pages/schedule/helpers'
import axios from 'axios'
import { SessionDto } from '@/openapi-api/api'
import AttendanceDayView from './AttendanceDayView'

interface Props {
  dates: Dates
  locations: string[]
  schools: string[]
  teachers: string[]
  groups: string[]
  setDates: (dates: Dates) => void
  scheduleUser?: (session: SessionDto) => Promise<void>
  isUserView?: boolean
}

const doQuery = ({
  dates,
  locations,
  schools,
  teachers,
  groups,
}: {
  dates: Dates
  locations: string[]
  schools: string[]
  teachers: string[]
  groups: string[]
}) => {
  const query = []

  query.push(`startTime~DATE_AFTER~${dates[0].format(DATE_FORMAT)}`)
  query.push(`startTime~DATE_BEFORE~${dates[1].format(DATE_FORMAT)}`)
  if (locations?.length) {
    query.push(`placeId~in~${locations.join(',')}`)
  }
  if (schools?.length) {
    query.push(`schoolId~in~${schools.join(',')}`)
  }
  if (teachers?.length) {
    query.push(`teacherId~in~${teachers.join(',')}`)
  }
  if (groups?.length) {
    query.push(`groupId~in~${groups.join(',')}`)
  }

  return query.join(';') || undefined
}

const AttendanceSchedule: FC<Props> = ({
  dates,
  setDates,
  schools,
  locations,
  teachers,
  groups,
  isUserView,
}) => {
  const { sessionAttendanceViewControllerApi } = useApi()
  const token = useRef(axios.CancelToken.source())
  const { resource: sessions } = useAsyncResource({
    fetchResource: useCallback(() => {
      token.current.cancel()
      token.current = axios.CancelToken.source()
      return sessionAttendanceViewControllerApi.find(
        0,
        1000,
        ['startTime,asc'],
        doQuery({
          dates,
          schools,
          locations,
          teachers,
          groups,
        }),
        { cancelToken: token.current.token },
      )
    }, [
      dates,
      locations,
      schools,
      sessionAttendanceViewControllerApi,
      teachers,
      groups,
    ]),
  })

  return (
    <Flex vertical>
      <AttendanceDayView
        sessions={sessions?.data.content || []}
        dates={dates}
        setDates={setDates}
        isUserView={isUserView}
      />
    </Flex>
  )
}

export default AttendanceSchedule
