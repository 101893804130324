/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DocumentTypeDto } from './document-type-dto';
// May contain unused imports in some cases
// @ts-ignore
import { MediaFileDto } from './media-file-dto';
// May contain unused imports in some cases
// @ts-ignore
import { SchoolViewDto } from './school-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StudentSchoolEnrollmentStatusDto } from './student-school-enrollment-status-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StudentViewDto } from './student-view-dto';

/**
 * 
 * @export
 * @interface StudentDocumentViewDto
 */
export interface StudentDocumentViewDto {
    /**
     * 
     * @type {number}
     * @memberof StudentDocumentViewDto
     */
    'id'?: number;
    /**
     * 
     * @type {SchoolViewDto}
     * @memberof StudentDocumentViewDto
     */
    'school'?: SchoolViewDto;
    /**
     * 
     * @type {StudentViewDto}
     * @memberof StudentDocumentViewDto
     */
    'student'?: StudentViewDto;
    /**
     * 
     * @type {DocumentTypeDto}
     * @memberof StudentDocumentViewDto
     */
    'documentType'?: DocumentTypeDto;
    /**
     * 
     * @type {MediaFileDto}
     * @memberof StudentDocumentViewDto
     */
    'mediaFile'?: MediaFileDto;
    /**
     * 
     * @type {string}
     * @memberof StudentDocumentViewDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentDocumentViewDto
     */
    'status'?: StudentDocumentViewDtoStatusEnum;
    /**
     * 
     * @type {StudentSchoolEnrollmentStatusDto}
     * @memberof StudentDocumentViewDto
     */
    'schoolEnrollment'?: StudentSchoolEnrollmentStatusDto;
}

/**
    * @export
    * @enum {string}
    */
export enum StudentDocumentViewDtoStatusEnum {
    DOCUMENTS_REQUESTED = 'documents_requested',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected'
}


