import { Flex, Tabs } from 'antd'
import { FC, PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { routePaths } from '@/routes'
import { useNavigate } from 'react-router-dom'

interface Props extends PropsWithChildren {
  active: string
}

export const NotificationTabs: FC<Props> = ({ active, children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onChange = useCallback((key: string) => navigate(key), [navigate])

  return (
    <Flex vertical>
      <Tabs
        onChange={onChange}
        type="card"
        activeKey={active}
        items={[
          {
            label: t`notifications.sent`,
            key: routePaths.notifications.sent,
          },
          {
            label: t`notifications.received`,
            key: routePaths.notifications.received,
          },
        ]}
        tabBarStyle={{ marginBottom: 0 }}
      />
      <Flex
        vertical
        style={{
          backgroundColor: 'white',
          height: '100%',
          padding: '16px',
          marginLeft: '1px',
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}
